import { ApolloQueryResult } from '@apollo/client';
import omit from 'lodash.omit';
import { useEffect, useState } from 'react';

import {
  CMSQuestions,
  useGetCountries,
  useGetPaperworkContent,
  useGetPaperworkQuestionsContent,
  useGetStates,
} from '../contentstack';
import { GetPaperworkContent_all_paperwork_items } from '../contentstack/__generated__/GetPaperworkContent';
import {
  ManagedProductPaperwork,
  PaperworkInvestment,
  PaperworkWealthInformation,
  RelatedContactParty,
  TrustInformation,
  useGetPaperwork,
  useGetRelatedContacts,
  useSyncWithCustodian,
} from '../symphony';
import { GetPaperwork, GetPaperworkVariables } from '../symphony/__generated__/GetPaperwork';
import { GetRelatedContacts } from '../symphony/__generated__/GetRelatedContacts';

import {
  BusinessEntityType,
  FinancialAccountType,
  IdentifierType,
  PaperworkType,
  PartyType,
  RelationshipName,
  TrustIdentificationType,
  TrustProfileType,
} from '~/__generated__';
import { DropdownItem } from '~/components';
import {
  isStringAttribute,
  PartyAttribute,
  useGetClientExternalIdentifiers,
  useGetPartyAttributes,
} from '~/hooks/client/symphony';
import { AsyncResult, ContentOptions, isCorporateAccountType, isTrustAccountType, useCoreConfig } from '~/utils';

export interface ZipCodeMapItem {
  ranges: {
    high: string;
    low: string;
  }[];
  state: string;
}

interface UseGetPaperworkData {
  content?: GetPaperworkContent_all_paperwork_items | null;
  countriesList?: DropdownItem<string | number>[];
  entityRelationships: Record<string, string[]>;
  minorAgesByState?: {
    custodial_minor_age?: number;
    minor_age: number;
    state: string | undefined;
  }[];
  paperworkSymphonyData?: GetPaperwork;
  partyAttributes?: PartyAttribute[];
  questionsContent?: CMSQuestions[];
  refetchPaperworkSymphonyData?: (
    variables?: Partial<GetPaperworkVariables> | undefined,
  ) => Promise<ApolloQueryResult<GetPaperwork>>;
  relatedContactsData?: ManagedProductPaperwork[];
  relatedEntitiesData?: ManagedProductPaperwork[];
  repCodes: DropdownItem<string>[];
  stateZipCodeMap?: ZipCodeMapItem[];
  statesList?: DropdownItem<string | number>[];
}

interface UsePaperWorkContentVariables {
  contentOptions: ContentOptions;
  managedProductId: string;
  partyId: string;
  partyIdFA?: string;
  performSyncWithCustodian?: boolean;
  relationshipNames?: RelationshipName[];
}

export const useGetPaperworkData = ({
  contentOptions,
  managedProductId,
  partyId,
  partyIdFA = '',
  relationshipNames = [
    RelationshipName.HOUSEHOLD_MEMBER,
    RelationshipName.AUTHORIZED_INDIVIDUAL,
    RelationshipName.BENEFICIAL_OWNER,
    RelationshipName.CONTROL_PERSON,
    RelationshipName.TRUSTEE,
  ],
  performSyncWithCustodian = false,
}: UsePaperWorkContentVariables): AsyncResult<UseGetPaperworkData> => {
  const [state, setState] = useState<AsyncResult<UseGetPaperworkData>>({ loading: true });
  const [syncError, setSyncError] = useState<Error | undefined>();
  const [isSynced, setIsSynced] = useState(false);
  const [syncWithCustodian] = useSyncWithCustodian();

  const {
    featureFlags: { additionalRepCodeIdentifierNames, repCodeIdentifierName },
    components: {
      sfPaperwork: { fetchRelatedContactsData },
    },
  } = useCoreConfig();

  useEffect(() => {
    if (performSyncWithCustodian && !isSynced) {
      const syncWithCustodianFunc = async () => {
        try {
          const result = await syncWithCustodian({
            variables: {
              managedProductId,
              partyId,
            },
          });

          setIsSynced(!!result.data?.syncPaperworkFromCustodian?.success);
          setSyncError(result.errors?.[0].originalError ?? undefined);
        } catch (err) {
          console.error(err);
          if (err instanceof Error) {
            setSyncError(err);
          }
        }
      };

      syncWithCustodianFunc();
    }
  }, [isSynced, managedProductId, partyId, performSyncWithCustodian, syncWithCustodian]);

  const { data: statesData, loading: statesDataLoading, error: statesDataError } = useGetStates({
    variables: contentOptions,
  });

  const { data: countriesData, loading: countriesDataLoading, error: countriesDataError } = useGetCountries({
    variables: contentOptions,
  });

  const {
    data: paperworkContentData,
    loading: paperworkContentLoading,
    error: paperworkContentError,
  } = useGetPaperworkContent({
    variables: contentOptions,
  });

  const {
    data: paperworkQuestionsContentData,
    loading: paperworkQuestionsContentLoading,
    error: paperworkQuestionsContentError,
  } = useGetPaperworkQuestionsContent({
    variables: contentOptions,
  });

  const {
    data: paperworkSymphonyData,
    loading: paperworkSymphonyLoading,
    error: paperworkSymphonyError,
    refetch: refetchPaperworkSymphonyData,
  } = useGetPaperwork({
    variables: {
      partyId,
      managedProductId,
    },
    fetchPolicy: 'no-cache',
    skip: performSyncWithCustodian && !isSynced,
  });

  const {
    data: relatedContactsData,
    loading: relatedContactsLoading,
    error: relatedContactsError,
  } = useGetRelatedContacts({
    variables: {
      partyId,
      relationshipNames,
    },
    skip: !fetchRelatedContactsData,
  });

  const {
    data: partyAttributesData,
    loading: partyAttributesLoading,
    error: partyAttributesError,
  } = useGetPartyAttributes({
    variables: {
      partyId: partyIdFA,
    },
    skip: !partyIdFA,
  });

  const { data: repCodeData, loading: repCodeLoading, error: repCodeError } = useGetClientExternalIdentifiers({
    variables: { partyId: partyIdFA },
    skip: !partyIdFA || !repCodeIdentifierName,
  });

  const loadingStates =
    paperworkContentLoading ||
    paperworkQuestionsContentLoading ||
    paperworkSymphonyLoading ||
    statesDataLoading ||
    countriesDataLoading ||
    partyAttributesLoading ||
    relatedContactsLoading ||
    repCodeLoading;
  const error =
    paperworkContentError ||
    paperworkQuestionsContentError ||
    paperworkSymphonyError ||
    statesDataError ||
    countriesDataError ||
    partyAttributesError ||
    relatedContactsError ||
    repCodeError ||
    syncError;
  useEffect(() => {
    const content = paperworkContentData?.all_paperwork?.items?.[0];
    const questionsContent = (paperworkQuestionsContentData?.all_paperwork_questions?.items?.filter(c => c !== null) ??
      []) as CMSQuestions[];
    const statesContent = statesData?.all_states?.items?.[0];
    const statesList = statesContent?.stateslist?.map(s => ({ label: s?.label, value: s?.value })) as DropdownItem[];
    const minorAgesByState =
      statesContent?.stateslist?.map(s => ({
        state: s?.value ?? undefined,
        minor_age: s?.minor_age ?? 18,
        custodial_minor_age: s?.custodial_minor_age ?? s?.minor_age ?? 18,
      })) ?? [];
    const stateZipCodeMap =
      statesContent?.stateslist?.map(el => ({
        state: el?.value ?? '',
        ranges: el?.valid_zip_code_ranges?.map(range => ({ low: range?.low ?? '', high: range?.high ?? '' })) ?? [],
      })) ?? [];
    const countriesContent = countriesData?.all_countries?.items?.[0];
    const countriesList = countriesContent?.countrieslist?.map(c => ({
      label: c?.label,
      value: c?.value,
      otherValues: c?.other_values,
    })) as DropdownItem[];

    let repCodeDropdownItems: DropdownItem<string>[] = [];
    if (repCodeData && partyAttributesData) {
      const externalIdentifiersRepCodesDropdownItems =
        repCodeData.client?.party?.externalSystemIdentifiers
          ?.filter(
            item =>
              item.name === repCodeIdentifierName ||
              additionalRepCodeIdentifierNames?.externalIdentifiers?.includes(item.name),
          )
          .map(item => ({ label: item.value, value: item.value })) ?? [];

      const partyAttributesRepCodes = partyAttributesData.client?.party?.attributes
        ?.filter(isStringAttribute)
        .filter(attribute => additionalRepCodeIdentifierNames?.partyAttributes?.includes(attribute.name))
        .flatMap(attribute => attribute.value.split(','))
        .sort();
      const partyAttributesRepCodeItems = partyAttributesRepCodes?.map(item => ({ label: item, value: item })) ?? [];

      repCodeDropdownItems = externalIdentifiersRepCodesDropdownItems.concat(partyAttributesRepCodeItems);
    }
    let relatedContactsPaperworkData: ManagedProductPaperwork[] | undefined;
    let relatedEntitiesData: ManagedProductPaperwork[] | undefined;
    let entityRelationships: Record<string, string[]> = {};
    if (fetchRelatedContactsData && relatedContactsData && paperworkSymphonyData?.managedProduct?.accountType) {
      const { contacts, entities, entityRelationshipsItems } = getRelatedContactsPaperworkData(
        paperworkSymphonyData.managedProduct.accountType,
        relatedContactsData,
      );
      relatedContactsPaperworkData = contacts;
      relatedEntitiesData = entities;
      entityRelationships = entityRelationshipsItems;
    }

    setState({
      data: {
        content,
        countriesList,
        entityRelationships,
        minorAgesByState,
        paperworkSymphonyData,
        partyAttributes: partyAttributesData?.client?.party?.attributes ?? [],
        relatedContactsData: relatedContactsPaperworkData,
        relatedEntitiesData,
        questionsContent,
        refetchPaperworkSymphonyData,
        repCodes: repCodeDropdownItems,
        statesList,
        stateZipCodeMap,
      },
      loading: loadingStates,
      error,
    });
  }, [
    countriesData,
    error,
    fetchRelatedContactsData,
    loadingStates,
    paperworkContentData,
    paperworkQuestionsContentData,
    paperworkSymphonyData,
    relatedContactsData,
    statesData,
    additionalRepCodeIdentifierNames,
    partyAttributesData,
    refetchPaperworkSymphonyData,
    repCodeData,
    repCodeIdentifierName,
  ]);

  return state;
};

const getRelatedContactsPaperworkData = (
  accountType: FinancialAccountType,
  provisioningData: GetRelatedContacts,
): {
  contacts: ManagedProductPaperwork[];
  entities: ManagedProductPaperwork[];
  entityRelationshipsItems: Record<string, string[]>;
} => {
  const contacts: ManagedProductPaperwork[] = [];
  const entities: ManagedProductPaperwork[] = [];
  const entityRelationshipsItems: Record<string, string[]> = {};
  const entityType = isTrustAccountType(accountType)
    ? BusinessEntityType.TRUST
    : isCorporateAccountType(accountType)
    ? BusinessEntityType.COMPANY
    : BusinessEntityType.HOUSEHOLD;
  const parties = provisioningData.client?.party?.relationships
    ?.filter(item => item.party.partyBusinessEntity?.entityType === entityType)
    .map(item => item.party);

  if (entityType !== BusinessEntityType.HOUSEHOLD) {
    parties?.forEach(party => {
      let trustInformation: TrustInformation | null = null;
      const { investment, wealthInformation } = getInvestmentInformation(party);
      if (entityType === BusinessEntityType.TRUST) {
        trustInformation = getTrustInformation(party);
      }
      entities.push(intialisePaperworkDataFromRelatedContact(party, trustInformation, investment, wealthInformation));
    });
  }
  parties?.forEach(party => {
    const partyIds: string[] = [];
    party.relationships?.forEach(relationship => {
      let trustInformation: TrustInformation | null = null;
      const { investment, wealthInformation } = getInvestmentInformation(relationship.party);
      if (entityType === BusinessEntityType.TRUST) {
        trustInformation = getTrustInformation(relationship.party);
      }
      if (entityType !== BusinessEntityType.HOUSEHOLD) {
        partyIds.push(relationship.party.id ?? '');
      }
      contacts.push(
        intialisePaperworkDataFromRelatedContact(relationship.party, trustInformation, investment, wealthInformation),
      );
    });
    entityRelationshipsItems[party.id ?? ''] = partyIds;
  });

  return { contacts, entities, entityRelationshipsItems };
};

const getInvestmentInformation = (party: RelatedContactParty) => ({
  investment:
    party.partyPerson?.investmentInformation?.investment ??
    party.partyBusinessEntity?.investmentInformation?.investment ??
    null,
  wealthInformation:
    party.partyPerson?.investmentInformation?.wealthInformation ??
    party.partyBusinessEntity?.investmentInformation?.wealthInformation ??
    null,
});

const intialisePaperworkDataFromRelatedContact = (
  party: RelatedContactParty,
  trustInformation: TrustInformation | null,
  investment: PaperworkInvestment | null,
  wealthInformation: PaperworkWealthInformation | null,
): ManagedProductPaperwork => {
  const partyPerson = omit(party.partyPerson, 'investmentInformation');
  const partyBusinessEntity = omit(party.partyBusinessEntity, 'investmentInformation');
  return {
    __typename: 'Paperwork',
    id: null,
    paperworkFreeFormId: null,
    paperworkType: PaperworkType.SECONDARY, // Dummy this is not used in paperwork while saving
    isMailingAddressSameAsHomeAddress: true,
    isHomeAddressDerivedFromPrimary: false,
    isMailingAddressDerivedFromPrimary: false,
    isUpdatingBeneficiariesAllowed: null,
    party: { ...party, partyPerson, partyBusinessEntity },
    trustInformation,
    wealthInformation,
    relationships: null,
    regulatoryInformation: null,
    investment,
    tradeConfirmationFrequency: null,
    isProxyVotingEnabled: null,
    additionalAttributes: null,
  };
};

const getTrustInformation = (party: RelatedContactParty): TrustInformation => {
  const identifier =
    party.identifiers?.find(id => IdentifierType.SSN === id.type) ??
    party.identifiers?.find(id => IdentifierType.TIN === id.type);
  return {
    __typename: 'TrustInformation',
    isTrusteeGrantor: null,
    canTrusteesModifyTrust: null,
    eachTrusteeAction: null,
    numberOfTrustees: null,
    otherSettlorTrustorOrGrantor: null,
    otherTrusteeAction: null,
    revokerFirstName: null,
    revokerLastName: null,
    revokerMiddleName: null,
    settlorTrustorOrGrantor: null,
    trustAgreementDate: party.partyBusinessEntity?.attributes?.trustDate ?? '',
    trustGoverningState: party.partyBusinessEntity?.state ?? '',
    trustIdentificationNumber: identifier?.identifierValue ?? '',
    trustIdentificationType: identifier
      ? identifier.type === IdentifierType.SSN
        ? TrustIdentificationType.SSN
        : TrustIdentificationType.TIN
      : null,
    trustName: party.partyBusinessEntity?.name ?? '',
    trustProfileType: party.partyType === PartyType.BUSINESS_ENTITY ? TrustProfileType.TRUST : TrustProfileType.TRUSTEE,
    trustType: null,
    whoCanModifyTrust: null,
  };
};
