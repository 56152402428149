import { createSvgIcon } from '@mui/material/utils';
import { isAfter, isFuture, isPast, isToday, isValid, parseISO, toDate } from 'date-fns';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { FileUploader } from '../../../../components/ui/FileUploader';
import {
  calculateAge,
  formatDate,
  isCustodialAccountType,
  isJTWROSAccountType,
  isoDateFormat,
  useCoreConfig,
} from '../../../../utils';
import { AssetsHeldAway } from '../../AssetsHeldAway';
import { Beneficiaries } from '../../Beneficiaries';
import { Contacts } from '../../Contacts';
import { PaperworkContext } from '../../context';
import { InvestmentRestrictions } from '../../InvestmentRestrictions';
import {
  getPaperworkBooleanFieldValue,
  isMasked,
  mandatoryCheckboxValidation,
  SELECT_DROPDOWN_ADD_NEW_VALUE,
} from '../../utils/helper';

import {
  ALPHA_NUMERIC_ONLY,
  ALPHA_ONLY,
  CustomQuestionComponentProps,
  DataMappingsByDataPointKey,
  FormData,
  Props,
  QuestionOrder,
  QuestionOrderSteps,
  QuestionRule,
  QuestionValue,
  Rule,
} from './types';
import {
  atLeastOneIsFilled,
  CmsKeys,
  ComponentTypes,
  formatDefaultValueForQuestionInput,
  getComponentFields,
  getDataMappingsByDataPointKey,
  getDefaultDropdownValue,
  getDefaultValue,
  getDropdownItems,
  getErrorMessage,
  getQuestionContent,
  getQuestionnaireValues,
  getQuestionsValidationErrorMessages,
  getRegulatoryTickerSearchContent,
  getValueFromSelectedContact,
  InputTypes,
  isAddressWithoutPostBox,
  isValidEmail,
  isValidIdentificationNumber,
  isValidLength,
  isValidNumber,
  isValidZipCode,
  matchConcatenatedStringValues,
  matchesRegex,
  ValidationNames,
} from './utils';

import { AddressType, FinancialAccountType, IdentifierType, RelationshipName } from '~/__generated__';
import { CurrencyTextField, RteContent } from '~/components';
import { AccountProfileContext } from '~/components/AccountProfileUpdateWrapper/context';
import { TaxLossHarvestingModal } from '~/components/modals/TaxLossHarvesting';
import {
  Alert,
  Box,
  Button,
  CheckboxGroup,
  DatePicker,
  Dropdown,
  FormHelperTextProps,
  InputAdornment,
  RadioGroup,
  Skeleton,
  TextField,
  Typography,
  useModalState,
  useTheme,
} from '~/components/ui';
import { DropdownChangeEvent } from '~/components/ui/Dropdown/types';
import { Regulatory } from '~/containers/Paperwork/Regulatory';
import { isStringAttribute } from '~/hooks/client/symphony';

const LeafIcon = createSvgIcon(
  <React.Fragment>
    <path d="M6.05001 8.05C3.32001 10.78 3.32001 15.2 6.03001 17.93C7.50001 14.53 10.12 11.69 13.39 10C10.62 12.34 8.68001 15.61 8.00001 19.32C10.6 20.55 13.8 20.1 15.95 17.95C19.43 14.47 20 4 20 4C20 4 9.53001 4.57 6.05001 8.05Z" />
  </React.Fragment>,
  'Leaf',
);

export const Questions: FC<Props> = ({
  dataQa = 'questions',
  accountType,
  allPaperworkData,
  assetsHeldAwayConfig,
  beneficiaryConfig,
  content,
  contentOptions,
  contingentBeneficiaryIds,
  ctas,
  deleteKeysFromResult,
  dropdownsContent,
  employmentStatus,
  harvestLosses,
  hiddenComponentValues,
  isSaving,
  isSecondary,
  isSubmitted,
  isAccountProfileEdit,
  listOfEmails,
  managedProductId,
  minorAgesByState,
  onSubmit,
  onUnsuccessfulCallback,
  order,
  partyAttributes,
  partyId,
  primaryAddresses,
  primaryClientHasBeneficiaries,
  repCodes,
  results,
  questionnaireInvestWealthData,
  section,
  sectionIndex,
  selectContactDropdownItems,
  selectedContact,
  setContextValues,
  setBeneficiaryIds,
  setContingentBeneficiaryIds,
  showTlhButtonIcon = false,
  symphonyData,
  updateAccordionStates,
  updateEmploymentDetails,
  updateTlh,
}) => {
  const {
    sfPaperwork: { styles: style },
  } = useTheme();
  const {
    calculateNetWorth,
    drivingLicenseIdentifier,
    onlyDriversIdentificationTypeForNonUsCitizen,
    onlyPassportIdentificationTypeForResidentAliens,
    passportIdentifier,
    registeredStatesAttributeName,
    registeredStatesSeparator,
    hiddenOptions,
    statesWithUGMA,
  } = useCoreConfig().components.sfPaperwork;
  const contextVariables = useContext(PaperworkContext);
  const {
    isNonUsCitizen,
    isResidentAlien,
    isValidNetWorth,
    repCodes: repCodesValue,
    stateZipCodeMap,
    currentContact,
    tenantInCommonPercentagePrimary,
  } = contextVariables;

  const [questions, setQuestions] = useState<QuestionOrder['orderSteps']>([]);
  const [fetchingQuestions, setFetchingQuestions] = useState(true);
  const [rules, setRules] = useState<Rule[]>([]);
  const [dataMappingsByDataPointKey, setDataMappingsByDataPointKey] = useState<
    Record<string, DataMappingsByDataPointKey>
  >();
  const [numberOfPrimaryBeneficiaries, setNumberOfPrimaryBeneficiaries] = useState(0);
  const [numberOfContingentBeneficiaries, setNumberOfContingentBeneficiaries] = useState(0);
  const [numberOfContacts, setNumberOfContacts] = useState(0);
  const [dependentDataPoints, setDependentDataPoints] = useState<Record<string, boolean>>({});
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>();
  const [unmaskedValues, setUnmaskedValues] = useState<Record<string, any>>({});
  const [submittedFormData, setSubmittedFormData] = useState<Record<string, any>>({});
  const formHooks = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const {
    watch,
    handleSubmit,
    control,
    errors: fieldsErrors,
    trigger,
    reset: resetForm,
    formState: { isSubmitted: formIsSubmitted, isSubmitSuccessful: formSubmitSuccessful },
    getValues,
    setValue,
    setError,
  } = formHooks;
  const [tlhValue, setTlhValue] = useState(harvestLosses ?? false);
  const { open, openModal, onClose } = useModalState();

  const { disablePageEditing } = useContext(AccountProfileContext);

  const questionsContent = content.questions;
  const singleOptionInputContents = questionsContent.single_option_input_questions;
  const textInputContents = questionsContent.text_input_questions;
  const customComponents = [
    ComponentTypes.AssetsHeldAway,
    ComponentTypes.Beneficiary,
    ComponentTypes.Contacts,
    ComponentTypes.Tlh,
    ComponentTypes.Regulatory,
    ComponentTypes.FileUploader,
  ];

  const birthDateField = 'data_point:birth_date:date';
  const emailField = 'data_point:email:email';
  const confirmEmailField = 'data_point:confirm_email:email';
  const employmentStatusField = 'data_point:employment_status:single_option';
  const homeAddressStateField = 'data_point:state:single_option';
  const mailAddressStateField = 'data_point:mailing_state:single_option';
  const differentMailingAddressField = 'data_point:different_mailing_address:boolean';
  const sameAsPrimaryAddressField = 'data_point:same_home_mailing_address:boolean';
  const maritalStatusDataPoint = 'data_point:marital_status:single_option';
  const identificationTypeField = 'data-point:identification_type:single_option';
  const repCodeField = 'data_point:rep_code:single_option';
  const selectContactField = 'data_point:select_contact:single_option';
  const designateTrustedContactField = 'data_point:designate_trusted_contact:single_option';
  const designatePrimaryBeneficiaryField = 'data_point:designate_primary_beneficiary:single_option';

  // Watch repCode value to set in updateDigitalWealth request in Onboarding Wrapper
  const watchRepCodeValue = watch(repCodeField);

  // Watch employmentStatus for JOINT accounts and pass the formValue data to other sections
  const employmentFieldValue = watch(employmentStatusField);
  const watchedEmailFieldValue = watch(emailField);
  const watchedConfirmEmailFieldValue = watch(confirmEmailField);

  // Watch home address state for verifying that applicant is not a minor
  const homeAddressStateFieldValue = watch(homeAddressStateField);
  const mailingAddressStateFieldValue = watch(mailAddressStateField);
  const differentMailingAddressFieldValue = watch(differentMailingAddressField);

  // Watch birthDateField for triggering validation of applicant is not a minor
  const birthDateFieldValue = watch(birthDateField);
  const watchMaritalStatus = watch(maritalStatusDataPoint);
  // This is required to perform minor age validation for secondary applicant when they choose the same addresses as primary
  const sameAsPrimaryAddressFieldValue = watch(sameAsPrimaryAddressField);
  const primaryHomeAddressState = primaryAddresses.find(a => a.type === AddressType.HOME)?.countryPrimarySubdivision;
  const sameAsPrimaryAddress =
    isSecondary &&
    (typeof sameAsPrimaryAddressFieldValue === 'object'
      ? sameAsPrimaryAddressFieldValue[sameAsPrimaryAddressField]
      : sameAsPrimaryAddressFieldValue) === true;

  const isUGMAOrUTMAAccountType =
    !!accountType && [FinancialAccountType.UGMA, FinancialAccountType.UTMA].includes(accountType);

  useEffect(() => {
    if (updateEmploymentDetails && employmentFieldValue) {
      updateEmploymentDetails(symphonyData?.id ?? null, employmentFieldValue ?? null);
    }
  }, [employmentFieldValue, updateEmploymentDetails, symphonyData?.id]);

  const setContextOnInput = (value: any, key?: string) => {
    if (key && setContextValues) {
      setContextValues(prevState => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  useEffect(() => {
    /**
     * Paperwork currently only supports one repCode selection.
     * If we need to support multiple repCode selection from Paperwork,
     * additional work would need to be done
     * */

    if (watchRepCodeValue && setContextValues) {
      setContextValues(prevContext => ({
        ...prevContext,
        repCodes: [{ isPrimary: true, percentage: '100', repCode: watchRepCodeValue }],
      }));
    }
  }, [setContextValues, watchRepCodeValue]);

  useEffect(() => {
    if (isResidentAlien && onlyPassportIdentificationTypeForResidentAliens && passportIdentifier) {
      // update dependency of identification type when user selects Resident Alien
      updateSingleDependentDataPointKeysVisibility(identificationTypeField, passportIdentifier);
    }
  }, [isResidentAlien]);

  useEffect(() => {
    if (onlyDriversIdentificationTypeForNonUsCitizen) {
      const identifierType = symphonyData?.party?.identifiers?.find(item =>
        [IdentifierType.DRIVING_LICENSE, IdentifierType.PASSPORT].includes(item.type),
      )?.type;
      if (identifierType !== getValues(identificationTypeField)) {
        if (isNonUsCitizen && drivingLicenseIdentifier) {
          setValue(identificationTypeField, drivingLicenseIdentifier);
          // update dependency of identification type to only select driving license if non-US citizen
          updateSingleDependentDataPointKeysVisibility(identificationTypeField, drivingLicenseIdentifier);
        }
        if (!isNonUsCitizen && passportIdentifier) {
          setValue(identificationTypeField, passportIdentifier);
          // This is to update the visibility of the passport fields when selecting US citizen.
          updateSingleDependentDataPointKeysVisibility(identificationTypeField, passportIdentifier);
        }
      }
    }
  }, [isNonUsCitizen, onlyDriversIdentificationTypeForNonUsCitizen, symphonyData, getValues]);

  useEffect(() => {
    if (calculateNetWorth && !isSubmitted) {
      trigger();
    }
  }, [calculateNetWorth, isValidNetWorth, isSubmitted, trigger]);
  useEffect(() => {
    if (setContextValues && watchMaritalStatus) {
      setContextValues(prevState => ({
        ...prevState,
        maritalStatus: watchMaritalStatus,
      }));
    }
  }, [setContextValues, watchMaritalStatus]);

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit(handleSuccessfulSubmit, handleUnsuccessfulSubmit)();
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (isSaving) {
      handleSuccessfulSubmit(getValues());
    }
  }, [isSaving]);

  useEffect(() => {
    if (formSubmitSuccessful && !isSubmitted && !fieldsErrors) {
      // Reset form when api call is completed and form submit is successful
      resetForm(submittedFormData);
    }
  }, [formSubmitSuccessful, isSubmitted]);

  useEffect(() => {
    if (watchedEmailFieldValue !== undefined && watchedConfirmEmailFieldValue !== undefined) {
      trigger([emailField, confirmEmailField]);
    }
  }, [watchedEmailFieldValue, watchedConfirmEmailFieldValue, trigger]);

  useEffect(() => {
    if (birthDateFieldValue !== undefined) {
      trigger(birthDateField);
    }
  }, [homeAddressStateFieldValue, sameAsPrimaryAddressFieldValue]);

  useEffect(() => {
    if (isCustodialAccountType(accountType) && setContextValues) {
      const homeAddressState =
        homeAddressStateFieldValue ??
        symphonyData?.party?.addresses?.find(a => a.type === AddressType.HOME)?.countryPrimarySubdivision;
      if (minorAgesByState.length) {
        setContextValues(prevContext => ({
          ...prevContext,
          ageOfTermination: minorAgesByState.find(s => s.state === homeAddressState)?.custodial_minor_age ?? undefined,
        }));
      }
      if (isUGMAOrUTMAAccountType && statesWithUGMA.length) {
        setContextValues(prevContext => ({
          ...prevContext,
          accountType: statesWithUGMA.includes(homeAddressState)
            ? FinancialAccountType.UGMA
            : FinancialAccountType.UTMA,
        }));
      }
    }
  }, [homeAddressStateFieldValue, minorAgesByState, setContextValues, statesWithUGMA, symphonyData?.party?.addresses]);

  useEffect(() => {
    if (setContextValues) {
      setContextValues(prevState => ({
        ...prevState,
        differentMailingAddressValue: differentMailingAddressFieldValue?.[differentMailingAddressField],
        userMailStateCode: mailingAddressStateFieldValue,
        userHomeStateCode: homeAddressStateFieldValue,
        ...(isSecondary ? { sameAsPrimaryAddress } : { primaryUserHomeStateCode: homeAddressStateFieldValue }),
      }));
    }
  }, [
    isSecondary,
    homeAddressStateFieldValue,
    mailingAddressStateFieldValue,
    differentMailingAddressFieldValue,
    sameAsPrimaryAddress,
  ]);

  const handleSuccessfulSubmit = (formData: any) => {
    const formDataObj = formData as Record<string, any>;
    const unmaskedDataKeys = Object.keys(unmaskedValues);
    unmaskedDataKeys.forEach(k => {
      if (unmaskedValues[k] && formDataObj[k] && isMasked(formDataObj[k])) {
        formDataObj[k] = unmaskedValues[k];
      }
    });
    onSubmit(section, formDataObj, dependentDataPoints);
    setSubmittedFormData(formData);
  };

  const handleUnsuccessfulSubmit = () => {
    if (updateAccordionStates) {
      updateAccordionStates(sectionIndex);
    }
    onUnsuccessfulCallback();
  };

  const handleTLHOnFinish = () => {
    updateTlh?.(!tlhValue);
    setTlhValue(!tlhValue);
  };

  const isEmailMatching = (email: string, fieldName: string) => {
    if (fieldName === emailField && watchedConfirmEmailFieldValue) {
      return email === watchedConfirmEmailFieldValue;
    } else if (fieldName === confirmEmailField && watchedEmailFieldValue) {
      return email === watchedEmailFieldValue;
    }
    return;
  };

  const getCustomContent = (questionKey: string, type: InputTypes, componentType: ComponentTypes) => {
    const singleInputQuestions =
      singleOptionInputContents
        ?.filter(c => c?.key?.startsWith(questionKey))
        .map(c =>
          getQuestionContent({
            questionKey: c?.key ?? '',
            type: InputTypes.SingleOption,
            componentType,
            customComponents,
            singleOptionInputContents,
            textInputContents,
          }),
        ) ?? [];
    const textQuestions =
      textInputContents
        ?.filter(c => c?.key?.startsWith(questionKey))
        .map(c =>
          getQuestionContent({
            questionKey: c?.key ?? '',
            type,
            componentType,
            customComponents,
            singleOptionInputContents,
            textInputContents,
          }),
        ) ?? [];

    return singleInputQuestions.concat(textQuestions);
  };

  const filterRelationshipSymphonyData = (questionKey: string) => {
    switch (questionKey) {
      case 'question:beneficiary_primary':
        return symphonyData?.relationships?.filter(datum => datum?.name === 'BENEFICIARY_PRIMARY');
      case 'question:beneficiary':
        return symphonyData?.relationships?.filter(datum => datum?.name === 'BENEFICIARY');
      case 'question:trusted_contact':
        return symphonyData?.relationships?.filter(datum => datum?.name === 'TRUSTED_CONTACT');
      default:
        return;
    }
  };

  const getConditionalQuestionsAndRules = (conditionalRules: QuestionRule[]): [QuestionOrder['orderSteps'], Rule[]] => {
    const conditionalQuestions: QuestionOrder['orderSteps'] = [];
    const conditionalQuestionsRules: Rule[] = [];
    conditionalRules.forEach(rule => {
      const ruleDependentDataPointKeys: string[] = [];
      let nextConditionalQuestionKey = rule.next;
      while (nextConditionalQuestionKey !== null) {
        const nextConditionalQuestion = order.orderSteps.find(s => s.questionKey === nextConditionalQuestionKey);
        if (nextConditionalQuestion) {
          ruleDependentDataPointKeys.push(nextConditionalQuestion.dataPointKey);
          conditionalQuestions.push(nextConditionalQuestion);
          const depthOneConditionalRules = nextConditionalQuestion.rules.filter(r => r.conditions !== undefined);
          if (depthOneConditionalRules) {
            const [depthOneconditionalQuestions, depthOneconditionalQuestionsRules] = getConditionalQuestionsAndRules(
              depthOneConditionalRules,
            );
            if (depthOneconditionalQuestions) {
              conditionalQuestions.push(...depthOneconditionalQuestions);
              conditionalQuestionsRules.push(...depthOneconditionalQuestionsRules);
            }
          }
        } else {
          throw new Error(`Missing paperwork question for key: ${nextConditionalQuestionKey}`);
        }
        nextConditionalQuestionKey = nextConditionalQuestion.rules.find(r => r.conditions === undefined)?.next ?? null;
      }
      const conditions = rule.conditions?.map(c => {
        return { dataPointKey: c.dataPointKey, targetValue: c.value, type: c.type };
      });
      if (conditions) {
        conditionalQuestionsRules.push({ conditions, dependentDataPointKeys: ruleDependentDataPointKeys });
      }
    });
    return [conditionalQuestions, conditionalQuestionsRules];
  };

  useEffect(() => {
    if (questions.length === 0) {
      const firstQuestion = order.orderSteps.find(s => s.questionKey === order.start);
      if (firstQuestion) {
        setQuestions(arr => [...arr, firstQuestion]);
      } else {
        setFetchingQuestions(false);
        throw new Error(`Missing paperwork question for key: ${order.start}`);
      }
    } else {
      const currentLastQuestion = questions[questions.length - 1];

      const conditionalRules = currentLastQuestion.rules.filter(r => r.conditions !== undefined);
      const [conditionalQuestions, conditionalQuestionsRules] = getConditionalQuestionsAndRules(conditionalRules);
      const hiddenDataPoints = conditionalQuestions.reduce((acc: Record<string, boolean>, q) => {
        acc[q.dataPointKey] = true;
        return acc;
      }, {});
      setDependentDataPoints({ ...dependentDataPoints, ...hiddenDataPoints });
      setRules(arr => [...arr, ...conditionalQuestionsRules]);

      const nextQuestionKey = currentLastQuestion.rules.find(r => r.conditions === undefined)?.next;
      const nextQuestion = order.orderSteps.find(s => s.questionKey === nextQuestionKey);
      if (nextQuestion) {
        setQuestions(arr => [...arr, ...conditionalQuestions, nextQuestion]);
      } else if (conditionalQuestions.length > 0) {
        setQuestions(arr => [...arr, ...conditionalQuestions]);
      } else {
        setFetchingQuestions(false);
        if (nextQuestionKey !== null && nextQuestionKey !== undefined) {
          throw new Error(`Missing paperwork question for key: ${nextQuestionKey}`);
        }
      }
    }
  }, [questions]);

  useEffect(() => {
    if (!fetchingQuestions && !dataMappingsByDataPointKey) {
      setDataMappingsByDataPointKey(getDataMappingsByDataPointKey(questions));
    }
  }, [fetchingQuestions, dataMappingsByDataPointKey, questions]);

  useEffect(() => {
    if (dataMappingsByDataPointKey && Object.keys(defaultValues ?? {}).length === 0) {
      const defaultValuesByDataPointKey: Record<string, any> = {};
      const contact =
        symphonyData?.id === null && !isSecondary
          ? selectContactDropdownItems?.find(item => item.value === symphonyData.party?.id)
          : undefined;
      Object.keys(dataMappingsByDataPointKey).forEach(dpk => {
        const data = dataMappingsByDataPointKey[dpk];

        // If value is not present in symphony then fetch it from questionnaire
        const symphonyMappingPath = data.symphonyMapping;
        const symphonyMappingValue =
          data.questionnaireDataPointKeyMapping && data.shouldFetchFromSymphonyData && symphonyMappingPath
            ? symphonyMappingPath.split('.').reduce((obj: any, key: string) => obj[key], symphonyData)
            : undefined;
        const isSymphonyDataPresent = symphonyMappingValue ? parseFloat(symphonyMappingValue ?? 0) > 0 : false;
        defaultValuesByDataPointKey[dpk] =
          symphonyData?.additionalAttributes?.selectedContactPartyId && currentContact === SELECT_DROPDOWN_ADD_NEW_VALUE
            ? dpk === selectContactField
              ? currentContact
              : ''
            : results && dpk in results
            ? results[dpk] && typeof results[dpk] === 'object'
              ? dpk in results[dpk]
                ? results[dpk][dpk]
                : results[dpk] instanceof Date
                ? isoDateFormat(results[dpk])
                : results[dpk]
              : results[dpk]
            : !isSymphonyDataPresent && data.questionnaireDataPointKeyMapping && questionnaireInvestWealthData?.length
            ? getQuestionnaireValues(questionnaireInvestWealthData, data.questionnaireDataPointKeyMapping)
            : !contact && symphonyData
            ? getDefaultValue(symphonyData, data)
            : '';

        const contextKey = data.contextKey;
        if (contextKey && setContextValues) {
          setContextValues(prevState => ({ ...prevState, [contextKey]: defaultValuesByDataPointKey[dpk] }));
        }
      });
      if (symphonyData) {
        const beneficiaryArray = symphonyData.relationships?.filter(
          datum => datum?.name === RelationshipName.BENEFICIARY_PRIMARY || datum?.name === RelationshipName.BENEFICIARY,
        );
        const hasTrustedContact = symphonyData.relationships?.find(
          datum => datum?.name === RelationshipName.TRUSTED_CONTACT,
        );
        // Question asked on Frontend is use Different mailing address, thus the boolean values has to be negated.
        const value = symphonyData.isMailingAddressSameAsHomeAddress ?? true;
        defaultValuesByDataPointKey[differentMailingAddressField] = !value;
        if (hasTrustedContact) {
          defaultValuesByDataPointKey[designateTrustedContactField] =
            'data_point:designate_trusted_contact:single_option_yes';
        }
        if (
          (beneficiaryArray && beneficiaryArray.length > 0) ||
          questions
            .find(q => q.questionKey === 'question:designate_primary_beneficiary')
            ?.defaultValue?.toString()
            ?.includes(`${designatePrimaryBeneficiaryField}_yes`)
        ) {
          defaultValuesByDataPointKey[designatePrimaryBeneficiaryField] = `${designatePrimaryBeneficiaryField}_yes`;
          updateSingleDependentDataPointKeysVisibility(
            designatePrimaryBeneficiaryField,
            `${designatePrimaryBeneficiaryField}_yes`,
          );
        } else {
          defaultValuesByDataPointKey[designatePrimaryBeneficiaryField] = `${designatePrimaryBeneficiaryField}_no`;
        }

        // on reload we don't get watch functionality, we are setting context for reload or redirect scenario
        if (setContextValues && !watchMaritalStatus) {
          setContextValues(prevState => ({
            ...prevState,
            maritalStatus: symphonyData.party?.partyPerson?.maritalStatus ?? '',
          }));
        }

        if (setContextValues) {
          setContextValues(prevState => ({
            ...prevState,
            isNonUsCitizen: symphonyData.party?.partyPerson?.citizenship !== '999',
          }));
        }
      }

      if (repCodesValue) {
        defaultValuesByDataPointKey['data_point:rep_code:single_option'] =
          repCodesValue.find(item => item?.isPrimary)?.repCode ?? '';
      }
      if (
        questions.find(item => item.dataPointKey === selectContactField) &&
        currentContact === '' &&
        !symphonyData?.additionalAttributes?.selectedContactPartyId
      ) {
        if (contact) {
          setContextValues?.(prevState => ({ ...prevState, currentContact: contact.value }));
          defaultValuesByDataPointKey[selectContactField] = contact.value;
        } else {
          // TODO: Setting currentContact to SELECT_DROPDOWN_ADD_NEW_VALUE will re-run the useEffect below one extra time
          // It doesn't cause any issues, but is inefficient. We need to re-visit this and make this better
          setContextValues?.(prevState => ({ ...prevState, currentContact: SELECT_DROPDOWN_ADD_NEW_VALUE }));
          defaultValuesByDataPointKey[selectContactField] = SELECT_DROPDOWN_ADD_NEW_VALUE;
        }
      }
      setDefaultValues(defaultValuesByDataPointKey);
      updateMultipleDependentDataPointKeysVisibility(defaultValuesByDataPointKey);

      if (isNonUsCitizen && drivingLicenseIdentifier && onlyDriversIdentificationTypeForNonUsCitizen) {
        // update dependency of identification type to only select driving license if non-US citizen
        defaultValuesByDataPointKey['data-point:identification_type:single_option'] = drivingLicenseIdentifier;
        updateSingleDependentDataPointKeysVisibility(identificationTypeField, drivingLicenseIdentifier);
      }
    }
  }, [
    questions,
    dataMappingsByDataPointKey,
    defaultValues,
    fetchingQuestions,
    onlyDriversIdentificationTypeForNonUsCitizen,
    symphonyData,
    repCodesValue,
  ]);

  useEffect(() => {
    // On Changing dropdown value, this will ensure data is pre filled using data from the selected contact
    if (selectedContact?.party?.id && dataMappingsByDataPointKey) {
      if (symphonyData?.id && selectedContact.party.id === symphonyData.additionalAttributes?.selectedContactPartyId) {
        setDefaultValues({});
        return;
      }
      const values: Record<string, any> = {};
      Object.keys(dataMappingsByDataPointKey).forEach(dpk => {
        const data = dataMappingsByDataPointKey[dpk];
        /**
         * For customComponents like regulatory, beneficiary and trusted contact
         * we are skipping prefill as they are not expected to be present.
         * We are also not setting values for the radio questions which start them.
         */
        if (
          ![designatePrimaryBeneficiaryField, designateTrustedContactField].includes(dpk) &&
          data.componentType &&
          ![ComponentTypes.Beneficiary, ComponentTypes.Contacts, ComponentTypes.Regulatory].includes(data.componentType)
        ) {
          let value: any = null;
          if (dpk === selectContactField) {
            value = currentContact;
          } else {
            value = getValueFromSelectedContact(
              selectedContact,
              data,
              dpk,
              dropdownsContent,
              singleOptionInputContents,
            );

            if (value !== null && value !== undefined) {
              setValue(dpk, value);
              setContextOnInput(value, data.contextKey);
              values[dpk] = value;
            }
          }
        }
      });
      updateMultipleDependentDataPointKeysVisibility(values);
    } else if (currentContact === SELECT_DROPDOWN_ADD_NEW_VALUE && dataMappingsByDataPointKey) {
      const shouldReset = !Object.keys(dataMappingsByDataPointKey).find(dpk => {
        const componentType = dataMappingsByDataPointKey[dpk].componentType;
        return (
          [designatePrimaryBeneficiaryField, designateTrustedContactField].includes(dpk) ||
          (componentType &&
            [ComponentTypes.Beneficiary, ComponentTypes.Contacts, ComponentTypes.Regulatory].includes(componentType))
        );
      });
      if (shouldReset) {
        setDefaultValues({});
      }
    }
  }, [
    currentContact,
    selectedContact,
    dataMappingsByDataPointKey,
    setValue,
    resetForm,
    selectContactField,
    symphonyData,
  ]);

  /**
   * This useffect helps to force set values, for datapoints which are hidden component type
   * which act as page modifiers. Page modifiers are components which allow us to set
   * conditional rendering for certain fields.
   */
  useEffect(() => {
    if (!fetchingQuestions && order.hasHiddenComponentType && hiddenComponentValues) {
      Object.keys(hiddenComponentValues).forEach(item => {
        setValue(item, hiddenComponentValues[item]);
        updateSingleDependentDataPointKeysVisibility(item, hiddenComponentValues[item]);
      });
    }
  }, [fetchingQuestions, hiddenComponentValues, order.hasHiddenComponentType, setValue]);

  const matchTargetValueWithCurrentValue = (targetValue: QuestionValue, currentValue: QuestionValue): boolean => {
    return (
      targetValue === currentValue ||
      (typeof currentValue === 'string' &&
        typeof targetValue === 'string' &&
        matchConcatenatedStringValues(targetValue, currentValue))
    );
  };

  const isValueGreaterThan = (targetValue: QuestionValue, currentValue: QuestionValue): boolean => {
    const parseTargetValue = parseInt(targetValue as any, 10);
    const currentTargetValue = parseInt(currentValue as any, 10);
    return !isNaN(parseTargetValue) && !isNaN(currentTargetValue) && currentTargetValue > parseTargetValue;
  };

  const isValueBetween = (targetValue: QuestionValue, currentValue: QuestionValue): boolean => {
    const currentTargetValue = parseInt(currentValue as any, 10);
    return (
      Array.isArray(targetValue) &&
      !isNaN(currentTargetValue) &&
      +currentTargetValue > +targetValue[0] &&
      +currentTargetValue < +targetValue[1]
    );
  };

  const validationMessages = useMemo(
    () => [...(content.validationMessages ?? []), ...getQuestionsValidationErrorMessages(content.questions)],
    [content],
  );

  const getDependentDataPointsVisibility = (
    defaultDataPointValues: Record<string, any>,
    filteredRules: Rule[],
    dataPointKey: string,
    currentValue: any,
    values?: Record<string, any>,
  ) => {
    const finalRules = filteredRules;
    const dpKeys: Record<string, boolean> = {};
    if (finalRules.length) {
      const questionDetails = questions.find(question => question.dataPointKey === dataPointKey);
      if (questionDetails?.componentType === ComponentTypes.Checkbox) {
        currentValue = typeof currentValue === 'object' ? currentValue[dataPointKey] : currentValue;
      }
      if (questionDetails?.defaultValue) {
        currentValue =
          getComponentFields({
            contextVariables,
            currentDefaultValue: currentValue,
            customComponents,
            defaultValues: defaultDataPointValues,
            dropdownsContent,
            fieldsErrors,
            formIsSubmitted,
            hiddenOptions,
            listOfEmails,
            questionInRow: questionDetails,
            repCodes,
            selectContactDropdownItems,
            singleOptionInputContents,
            textInputContents,
            validationMessages,
          }).defaultValue ?? currentValue;
      }
    }
    finalRules.forEach(rule => {
      const condition = rule.conditions.find(c => c.dataPointKey === dataPointKey);
      if (rule.conditions) {
        if (condition) {
          if (
            condition.type === 'GREATER' &&
            isValueGreaterThan(condition.targetValue, calculateAge(formatDate(currentValue, 'yyyy-MM-dd')))
          ) {
            rule.dependentDataPointKeys.forEach(k => (dpKeys[k] = false));
          } else if (
            condition.type === 'BETWEEN' &&
            isValueBetween(condition.targetValue, calculateAge(formatDate(currentValue, 'yyyy-MM-dd')))
          ) {
            rule.dependentDataPointKeys.forEach(k => (dpKeys[k] = false));
          } else if (
            condition.type === 'NOT_EQUAL' &&
            !matchTargetValueWithCurrentValue(condition.targetValue, currentValue)
          ) {
            rule.dependentDataPointKeys.forEach(k => (dpKeys[k] = false));
          }
          // Only two conditions can be handled for a dpk as of now.
          else if (rule.conditions.length === 2) {
            const [condition1, condition2] =
              rule.conditions[0].dataPointKey === dataPointKey
                ? [rule.conditions[0], rule.conditions[1]]
                : [rule.conditions[1], rule.conditions[0]];
            // If any of the condition is false then field will not be visible.
            if (
              matchTargetValueWithCurrentValue(condition1.targetValue, currentValue) &&
              matchTargetValueWithCurrentValue(
                condition2.targetValue,
                getPaperworkBooleanFieldValue(values ?? getValues(), condition2.dataPointKey),
              )
            ) {
              rule.dependentDataPointKeys.forEach(k => (dpKeys[k] = false));
            } else {
              rule.dependentDataPointKeys.forEach(k => (dpKeys[k] = true));
            }
          } else if (
            condition.type === 'EQUAL' &&
            matchTargetValueWithCurrentValue(condition.targetValue, currentValue)
          ) {
            rule.dependentDataPointKeys.forEach(k => (dpKeys[k] = false));
          } else {
            rule.dependentDataPointKeys.forEach(k => (dpKeys[k] = true));
          }
        }
      }
    });
    return dpKeys;
  };

  // Currently, this function assumes that only one condition affects the outcome
  // Couldn't find a use cases for Paperwork where multiple answers would affect the outcome
  // If such a use case exists, this function needs to be modified to take that into account
  const updateSingleDependentDataPointKeysVisibility = useCallback(
    (currDataPointKey: string, currAnswer: string | boolean, clearPrefilledValue = true) => {
      const filteredRules = rules.filter(
        r => r.conditions.find(c => c.dataPointKey === currDataPointKey) !== undefined,
      );
      const dpKeys = getDependentDataPointsVisibility(defaultValues ?? {}, filteredRules, currDataPointKey, currAnswer);

      // Update only when there are changes in dependent data point visibility to prevent unnecessary re-rendering.
      if (Object.keys(dpKeys).length) {
        setDependentDataPoints(prevState => {
          return { ...prevState, ...dpKeys };
        });
      }
      filteredRules.forEach(key => {
        if (dpKeys[key.dependentDataPointKeys[0]]) {
          if (clearPrefilledValue) {
            setValue(key.dependentDataPointKeys[0], '');
          }
          updateSingleDependentDataPointKeysVisibility(key.dependentDataPointKeys[0], false, clearPrefilledValue);
        }
      });
    },
    [defaultValues, dependentDataPoints, rules],
  );

  const updateMultipleDependentDataPointKeysVisibility = (values: Record<string, any>) => {
    const dpKeys: Record<string, boolean> = {};
    Object.keys(values).forEach(dpk => {
      const filteredRules = rules.filter(r => r.conditions.find(c => c.dataPointKey === dpk) !== undefined);
      const dpVisibilities = getDependentDataPointsVisibility(values, filteredRules, dpk, values[dpk], values);
      Object.keys(dpVisibilities).forEach(k => {
        dpKeys[k] = dpVisibilities[k];
      });
    });
    setDependentDataPoints({ ...dependentDataPoints, ...dpKeys });
  };

  const onCheckboxAnswerChange = (dataPointKey: string, answer: boolean, clearPrefilledValue?: boolean) => {
    updateSingleDependentDataPointKeysVisibility(dataPointKey, answer, clearPrefilledValue);
  };

  const onDateAnswerChange = (dataPointKey: string, answer: string, clearPrefilledValue?: boolean) => {
    updateSingleDependentDataPointKeysVisibility(dataPointKey, answer, clearPrefilledValue);
  };

  const onDropdownAnswerChange = (dataPointKey: string, e: DropdownChangeEvent, clearPrefilledValue?: boolean) => {
    updateSingleDependentDataPointKeysVisibility(dataPointKey, e.target.value.toString(), clearPrefilledValue);
  };

  const onRadioAnswerChange = (dataPointKey: string, answer: string, clearPrefilledValue?: boolean) => {
    updateSingleDependentDataPointKeysVisibility(dataPointKey, answer, clearPrefilledValue);
  };
  const disableFieldsForSelectedContact =
    ![SELECT_DROPDOWN_ADD_NEW_VALUE, ''].includes(currentContact) ||
    (selectedContact?.party?.id && symphonyData?.additionalAttributes?.selectedContactPartyId
      ? selectedContact.party.id === symphonyData.additionalAttributes.selectedContactPartyId
      : false);
  const questionsRendered = new Set();
  const filteredQuestions = questions.filter(
    q => !q.accountTypes || (accountType && q.accountTypes.includes(accountType)),
  );

  const renderQuestionComponent = (
    questionsInRow: QuestionOrderSteps[],
    question: QuestionOrderSteps,
    hidden: boolean,
  ) => {
    if (!defaultValues) {
      return;
    }
    return questionsInRow.map((questionInRow, questionInRowIndex) => {
      questionsRendered.add(questionInRow.questionKey);
      const {
        fieldName,
        componentType,
        inputType,
        inputErrors,
        questionContents,
        dependentHiddenOptions,
        dropdownItems,
        defaultValue,
        errorMessage,
        formattingRules,
        validationRules,
      } = getComponentFields({
        contextVariables,
        customComponents,
        defaultValues,
        dropdownsContent,
        fieldsErrors,
        formIsSubmitted,
        hiddenOptions,
        listOfEmails,
        questionInRow,
        repCodes,
        selectContactDropdownItems,
        singleOptionInputContents,
        textInputContents,
        validationMessages,
      });
      const questionContent = questionContents[0];

      /**
       * This is to handle cases where FE receives unmasked data, which if is unchanged on user submission.
       * Should then be sent as paperwork data.
       */
      if (formattingRules.masked && !isMasked(defaultValue) && !Object.keys(unmaskedValues).includes(fieldName)) {
        setUnmaskedValues({ ...unmaskedValues, [fieldName]: defaultValue });
      }

      const CustomQuestionComponent = questionInRow.customQuestionComponent;
      const isFieldDisabled =
        disablePageEditing ||
        !!validationRules.forceDisabled ||
        (defaultValue && !!validationRules.disabled) ||
        (disableFieldsForSelectedContact && order.selectContactDisabledFields?.includes(questionInRow.questionKey));
      let customQuestionComponentData: CustomQuestionComponentProps = {
        paperworkData: allPaperworkData,
        prefillValues: filterRelationshipSymphonyData(questionInRow.questionKey),
        content: {
          countriesList: dropdownsContent.countriesList,
          stateList: dropdownsContent.statesList,
          questions: getCustomContent(questionInRow.questionKey, inputType, componentType),
          validationMessages,
        },
        contextValues: contextVariables,
        managedProductId,
        partyId,
        accountType,
        isAccountProfileEdit,
        isSubmitted,
        onUnsuccessfulCallback,
        formHooks,
        hidden: dependentDataPoints[question.dataPointKey],
        setContextValues,
        validationRules,
        disablePageEditing,
      };
      // TODO: Custom Beneficiary component should not be dependent on these props.
      if (componentType === ComponentTypes.Beneficiary) {
        customQuestionComponentData = {
          ...customQuestionComponentData,
          beneficiaryProps: {
            setBeneficiaryIds,
            setContingentBeneficiaryIds,
            setNumberOfContingentBeneficiaries,
            setNumberOfPrimaryBeneficiaries,
            contingentBeneficiaryIds,
            deleteKeysFromResult,
            ctas,
            isAccountProfileEdit,
            numberOfContingentBeneficiaries,
            numberOfPrimaryBeneficiaries,
          },
        };
      }

      if (componentType === ComponentTypes.Dropdown) {
        customQuestionComponentData = {
          ...customQuestionComponentData,
          isSecondary,
          partyAttributes,
        };

        if (isSecondary) {
          customQuestionComponentData = {
            ...customQuestionComponentData,
            coApplicantProps: {
              primaryClientHasBeneficiaries,
              homeStateCode: symphonyData?.party?.addresses?.find(address => address.type === AddressType.HOME)
                ?.countryPrimarySubdivision,
            },
          };
        }
      }

      if (questionContent.error) {
        return (
          <Alert
            contentOptions={contentOptions}
            data-qa={`cms-error-${questionInRow.questionKey}`}
            key={fieldName}
            severity="error"
          >
            Content not found for question: "{questionInRow.questionKey}" in CMS.
          </Alert>
        );
      }
      return (
        <Box
          data-qa={`question-${questionInRow.questionKey}`}
          key={fieldName}
          sx={{
            display: 'flex',
            flex: '1 0',
            flexWrap: 'wrap',
            alignContent: 'flex-end',
            mt:
              componentType === ComponentTypes.Dropdown && questionInRow.cmsKey === CmsKeys.States
                ? '-2.5px'
                : undefined,
            mr: questionsInRow.length > 1 && questionInRowIndex === 0 ? 1 : isAccountProfileEdit ? 2 : 0,
          }}
        >
          <Box sx={{ flex: 1, alignItems: 'start' }}>
            {CustomQuestionComponent ? (
              <CustomQuestionComponent {...customQuestionComponentData} />
            ) : componentType === ComponentTypes.Input ? (
              <Controller
                control={control}
                defaultValue={
                  typeof defaultValue === 'string'
                    ? formatDefaultValueForQuestionInput(defaultValue, formattingRules, validationRules)
                    : defaultValue
                }
                key={`question-${fieldName}`}
                name={fieldName}
                render={({ onChange, value, name, ref }) => (
                  <TextField
                    InputProps={{
                      endAdornment: questionContent.suffix ? (
                        <InputAdornment position="end">{questionContent.suffix}</InputAdornment>
                      ) : undefined,
                      startAdornment: questionContent.prefix ? (
                        <InputAdornment position="start">{questionContent.prefix}</InputAdornment>
                      ) : undefined,
                    }}
                    // Auto complete off: https://stackoverflow.com/questions/57482928/turn-off-autocomplete-for-all-inputs-in-react-app
                    autoComplete={fieldName === 'data_point:confirm_email:email' ? 'never' : undefined}
                    data-qa={`question-textField-${questionInRow.questionKey}`}
                    disabled={isFieldDisabled}
                    error={!!inputErrors}
                    fullWidth
                    inputRef={ref}
                    label={questionContent.question}
                    name={name}
                    onBlur={e => {
                      e.target.value = e.target.value.trim();
                      onChange(e);
                    }}
                    onChange={e => {
                      const { value: inputValue } = e.target;
                      if (
                        ([
                          InputTypes.Number,
                          InputTypes.Float,
                          InputTypes.Ssn,
                          InputTypes.Tin,
                          InputTypes.Percentage,
                        ].includes(inputType) ||
                          validationRules.numericOnly) &&
                        isNaN((inputValue as unknown) as number)
                      ) {
                        e.preventDefault(); // Prevent Non Numeric entries
                        return;
                      }
                      if (validationRules.alphaOnly && inputValue && !ALPHA_ONLY.test(inputValue)) {
                        e.preventDefault(); // Allow only alphabets and spaces
                        return;
                      }
                      if (validationRules.alphaNumericOnly && inputValue && !ALPHA_NUMERIC_ONLY.test(inputValue)) {
                        e.preventDefault(); // Allow only alphanumeric characters
                        return;
                      }
                      if (validationRules.maxLength && inputValue.length > +validationRules.maxLength) {
                        e.preventDefault();
                        return;
                      }
                      if ([InputTypes.Number, InputTypes.Ssn, InputTypes.Tin].includes(inputType)) {
                        if (inputValue.includes('.')) {
                          return;
                        }
                      }
                      if (validationRules.maxDecimalPlaces && inputValue) {
                        const decimalValue = inputValue.split('.')[1];
                        if (decimalValue && decimalValue.length > validationRules.maxDecimalPlaces) {
                          return;
                        }
                      }
                      if (inputType === InputTypes.Float) {
                        const decimalValue = inputValue.split('.')[1];
                        if (decimalValue && decimalValue.length > 2) {
                          return;
                        }
                      }
                      if (inputType === InputTypes.Percentage && parseFloat(inputValue) > 100) {
                        return;
                      }
                      if (validationRules.invalidCharactersRegex && inputValue) {
                        e.target.value = inputValue.replaceAll(validationRules.invalidCharactersRegex, '');
                      }
                      if (formattingRules.capitalize && e.target.value) {
                        e.target.value = e.target.value.toUpperCase();
                      }
                      questionInRow.onChange?.(e.target.value);
                      onChange(e);
                    }}
                    onFocus={e => {
                      if ([InputTypes.Ssn, InputTypes.Tin].includes(inputType) && e.target.value.includes('*')) {
                        e.preventDefault();
                        e.target.select();
                        return;
                      }
                    }}
                    onPaste={e => {
                      if (fieldName === 'data_point:confirm_email:email') {
                        e.preventDefault();
                        return;
                      }
                    }} // handle number value onChange
                    type={
                      inputType === InputTypes.Number ||
                      inputType === InputTypes.Float ||
                      inputType === InputTypes.Percentage
                        ? InputTypes.Text
                        : inputType
                    }
                    value={value}
                  />
                )}
                rules={
                  hidden
                    ? {}
                    : {
                        ...validationRules,
                        validate: {
                          alphaOnly: value => (validationRules.alphaOnly ? matchesRegex(ALPHA_ONLY, value) : true),
                          alphaNumericOnly: value =>
                            validationRules.alphaNumericOnly ? matchesRegex(ALPHA_NUMERIC_ONLY, value) : true,
                          isEmail: value => (value && inputType === InputTypes.Email ? isValidEmail(value) : true),
                          isNumber: value => (value && inputType === InputTypes.Number ? isValidNumber(value) : true),
                          isSsn: value =>
                            value && inputType === InputTypes.Ssn ? isValidIdentificationNumber(value) : true,
                          isTin: value =>
                            value && inputType === InputTypes.Tin ? isValidIdentificationNumber(value) : true,
                          isLength: value =>
                            value && validationRules.length ? isValidLength(value, validationRules.length) : true,
                          minLength: value =>
                            value && validationRules.minLength ? value.length >= validationRules.minLength : true,
                          isEmailConfirmed: value =>
                            value && inputType === InputTypes.Email ? isEmailMatching(value, fieldName) : true,
                          isValidNetWorth: () =>
                            calculateNetWorth && validationRules.validNetWorth && !isSubmitted ? isValidNetWorth : true,
                          atLeastOne: value =>
                            validationRules.atLeastOne && validationRules.atLeastOne.length
                              ? atLeastOneIsFilled({
                                  value,
                                  linkedFields: validationRules.atLeastOne,
                                  isSubmitted,
                                  fieldsErrors,
                                  fieldName,
                                  getValues,
                                  trigger,
                                  setError,
                                })
                              : true,
                          isValidTenantsInCommonPercentage: value =>
                            validationRules.isValidTenantsInCommonPercentage ? value !== '' && !isNaN(value) : true,
                          tenantsInCommonPercentageSum: value =>
                            validationRules.tenantsInCommonPercentageSum
                              ? tenantInCommonPercentagePrimary !== undefined &&
                                parseFloat(value) + tenantInCommonPercentagePrimary === 100
                              : true,
                          isValidZipCode: value =>
                            validationRules.validZipCode
                              ? isValidZipCode({
                                  linkedState: getValues(validationRules.validZipCode),
                                  zipcode: value,
                                  stateZipCodeMap: stateZipCodeMap ?? [],
                                })
                              : true,
                          postBoxNotAllowed: value =>
                            validationRules.postBoxNotAllowed ? isAddressWithoutPostBox(value) : true,
                          regex: value => (validationRules.regex ? matchesRegex(validationRules.regex, value) : true),
                        },
                      }
                }
              />
            ) : componentType === ComponentTypes.Date ? (
              <Controller
                control={control}
                defaultValue={defaultValue ? parseISO(defaultValue) : null}
                key={`question-${fieldName}`}
                name={fieldName}
                render={({ onChange, value, ref }) => (
                  <DatePicker
                    dataQa={`question-datePicker-${questionInRow.questionKey}`}
                    defaultValue={defaultValue ? parseISO(defaultValue) : undefined}
                    disableFuture={validationRules.futureDisabled}
                    disableHighlightToday={validationRules.todayDisabled}
                    disablePast={validationRules.pastDisabled}
                    disableTextInput={formattingRules.disableTextInput}
                    disabled={isFieldDisabled}
                    error={!!inputErrors}
                    fullWidth
                    inputRef={ref}
                    isControlled
                    label={questionContent.question}
                    minDate={validationRules.minDate}
                    onChange={e => {
                      onDateAnswerChange(fieldName, e, questionInRow.clearPrefilledValue);
                      onChange(e);
                    }}
                    shouldDisableDate={date => {
                      return !!(validationRules.todayDisabled && isToday(date as Date));
                    }}
                    value={value}
                  />
                )}
                rules={
                  hidden
                    ? {}
                    : {
                        ...validationRules,
                        validate: {
                          isDate: value => (value ? isValid(toDate(value)) : true),
                          isFutureDateDisabled: value =>
                            validationRules.futureDisabled ? isPast(value) || isToday(value) : true,
                          isPastDateDisabled: value =>
                            validationRules.pastDisabled ? isFuture(value) || isToday(value) : true,
                          isTodayDisabled: value => !(validationRules.todayDisabled && isToday(value as Date)),
                          isMinor: value =>
                            validationRules.minorAge && value
                              ? calculateAge(formatDate(value, 'yyyy-MM-dd')) >=
                                (minorAgesByState.find(
                                  a =>
                                    a.state ===
                                    (isSecondary && sameAsPrimaryAddress
                                      ? primaryHomeAddressState
                                      : homeAddressStateFieldValue),
                                )?.minor_age ?? 18)
                              : true,
                          maxAge: value =>
                            validationRules.maxAge && value
                              ? calculateAge(formatDate(value, 'yyyy-MM-dd')) < validationRules.maxAge
                              : true,
                          minDate: value =>
                            value ? isAfter(value, validationRules.minDate ?? new Date('1899-12-31')) : true,
                          allowCustodialMinorsOnly: value =>
                            isCustodialAccountType(accountType) && validationRules.allowCustodialMinorsOnly && value
                              ? calculateAge(formatDate(value, 'yyyy-MM-dd')) <
                                (minorAgesByState.find(
                                  a =>
                                    a.state ===
                                    (isSecondary && sameAsPrimaryAddress
                                      ? primaryHomeAddressState
                                      : homeAddressStateFieldValue),
                                )?.custodial_minor_age ?? 21)
                              : true,
                          isDeathDateValid: value =>
                            validationRules.minDateFromDataPointKey &&
                            value &&
                            getValues(validationRules.minDateFromDataPointKey)
                              ? isAfter(value, getValues(validationRules.minDateFromDataPointKey))
                              : true,
                        },
                      }
                }
              />
            ) : componentType === ComponentTypes.Dropdown ? (
              <Controller
                control={control}
                defaultValue={getDefaultDropdownValue(defaultValue, dropdownItems, questionInRow.defaultFirstOption)}
                key={`question-${fieldName}`}
                name={fieldName}
                render={({ onChange, value, ref }) => (
                  <Dropdown
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: style.maxDropdownHeight as string | number | undefined,
                        },
                      },
                    }}
                    dataQa={`question-dropdown-${questionInRow.questionKey}`}
                    disabled={isFieldDisabled}
                    displayEmpty
                    error={!!inputErrors}
                    inputRef={ref}
                    items={dropdownItems}
                    label={questionContent.question}
                    onChange={e => {
                      const targetValue = e.target.value;
                      if (dependentHiddenOptions && setContextValues) {
                        setContextValues(prevState => ({
                          ...prevState,
                          [dependentHiddenOptions.contextVariable]:
                            dependentHiddenOptions.comparisonType === 'NOT_EQUAL'
                              ? dependentHiddenOptions.value !== targetValue
                              : dependentHiddenOptions.value === targetValue,
                        }));
                      }
                      setContextOnInput(targetValue, questionInRow.contextKey);
                      onDropdownAnswerChange(fieldName, e, questionInRow.clearPrefilledValue);
                      questionInRow.onChange?.(targetValue);
                      onChange(targetValue);
                    }}
                    value={value}
                    width="100%"
                  />
                )}
                rules={
                  hidden
                    ? {}
                    : {
                        ...validationRules,
                        validate: {
                          restrictedStatesForJointAccount: value =>
                            !!validationRules.restrictedStatesForJointAccount &&
                            questionInRow.cmsKey === CmsKeys.States &&
                            value
                              ? isJTWROSAccountType(accountType)
                                ? !validationRules.restrictedStatesForJointAccount.includes(value)
                                : true
                              : true,
                          restrictedStates: value =>
                            validationRules.restrictedStates &&
                            value &&
                            registeredStatesAttributeName &&
                            questionInRow.cmsKey === CmsKeys.States &&
                            partyAttributes
                              ? !!partyAttributes
                                  .filter(isStringAttribute)
                                  .find(a => a.name === registeredStatesAttributeName)
                                  ?.value.split(registeredStatesSeparator ?? ',')
                                  .includes(value)
                              : true,
                          valueNotLessThan: value => {
                            if (validationRules.valueNotLessThan && value) {
                              const valueToCompare = getValues(validationRules.valueNotLessThan);
                              if (isValidNumber(value) && isValidNumber(valueToCompare)) {
                                return parseInt(value, 10) >= parseInt(valueToCompare, 10);
                              }
                            }
                            return true;
                          },
                        },
                      }
                }
              />
            ) : componentType === ComponentTypes.Checkbox ? (
              <Controller
                control={control}
                defaultValue={{ [fieldName]: defaultValue }}
                key={`question-${fieldName}`}
                name={fieldName}
                render={({ onChange, value }) => (
                  <CheckboxGroup
                    dataQa={`question-checkbox-${questionInRow.questionKey}`}
                    disabled={isFieldDisabled}
                    error={!!inputErrors}
                    items={[
                      {
                        name: fieldName,
                        label: <RteContent data={questionContent.question} />,
                      },
                    ]}
                    onChange={e => {
                      questionInRow.onChange?.(e);
                      onCheckboxAnswerChange(fieldName, e[fieldName], questionInRow.clearPrefilledValue);
                      onChange(e);
                    }}
                    value={value}
                  />
                )}
                rules={
                  hidden
                    ? {}
                    : {
                        ...validationRules,
                        validate: {
                          required: value => (validationRules.required ? mandatoryCheckboxValidation(value) : true),
                          sufficientFunds: value =>
                            validationRules.sufficientFunds ? mandatoryCheckboxValidation(value) : true,
                        },
                      }
                }
              />
            ) : componentType === ComponentTypes.CheckboxGroup ? (
              <Controller
                control={control}
                defaultValue={defaultValue}
                key={`question-${fieldName}`}
                name={fieldName}
                render={({ onChange, value }) => (
                  <CheckboxGroup
                    dataQa={`question-checkbox-${questionInRow.questionKey}`}
                    error={!!inputErrors}
                    items={
                      questionInRow.checkBoxes?.map(checkBox => ({
                        name: checkBox.dataPointKey,
                        disabled: checkBox.disabled,
                        label: (
                          <RteContent
                            data={
                              questionContents.find(questionDetails => questionDetails.key === checkBox.questionKey)
                                ?.question ?? ''
                            }
                          />
                        ),
                      })) ?? []
                    }
                    onChange={onChange}
                    value={value}
                  />
                )}
                rules={
                  hidden
                    ? {}
                    : {
                        ...validationRules,
                        validate: {
                          noCheckBoxSeletected: value => {
                            return !Object.values(value).reduce((noCheckBoxSeletected: boolean, val) => {
                              if (mandatoryCheckboxValidation(val)) {
                                noCheckBoxSeletected = false;
                              }
                              return noCheckBoxSeletected;
                            }, true);
                          },
                        },
                      }
                }
              />
            ) : componentType === ComponentTypes.Radio ? (
              <Controller
                control={control}
                defaultValue={defaultValue}
                key={`question-${fieldName}`}
                name={fieldName}
                render={({ onChange, value }) => (
                  <RadioGroup
                    dataQa={`question-radio-${questionInRow.questionKey}`}
                    disabled={isFieldDisabled}
                    error={!!inputErrors}
                    items={questionContent.options ?? []}
                    legend={questionContent.question}
                    name={fieldName}
                    onChange={e => {
                      onRadioAnswerChange(fieldName, e, questionInRow.clearPrefilledValue);
                      questionInRow.onChange?.(e);
                      onChange(e);
                    }}
                    value={value}
                  />
                )}
                rules={hidden ? {} : { ...validationRules }}
              />
            ) : componentType === ComponentTypes.Label ? (
              <Typography component="h3" data-qa={`component-label-${questionInRow.questionKey}`} variant="subtitle1">
                {questionContent.question}
              </Typography>
            ) : componentType === ComponentTypes.Info ? (
              <Alert
                contentOptions={contentOptions}
                data-qa={`component-info-${questionInRow.questionKey}`}
                severity="info"
              >
                {questionContent.question}
              </Alert>
            ) : componentType === ComponentTypes.Text ? (
              <RteContent
                data={questionContent.question}
                data-qa={`component-text-${questionInRow.questionKey}`}
                defaultBodyVariant="body2"
              />
            ) : componentType === ComponentTypes.Subtext ? (
              <Typography
                color="text.secondary"
                data-qa={`component-subtext-${questionInRow.questionKey}`}
                variant="body2"
              >
                {questionContent.question}
              </Typography>
            ) : componentType === ComponentTypes.AssetsHeldAway ? (
              <AssetsHeldAway
                accountType={accountType ?? FinancialAccountType.UNKNOWN_FINANCIAL_ACCOUNT_TYPE}
                assetsHeldAwayConfig={assetsHeldAwayConfig}
                content={{
                  questions: getCustomContent(questionInRow.questionKey, inputType, componentType),
                }}
                contentOptions={contentOptions}
                disablePageEditing={disablePageEditing}
                formHooks={formHooks}
                isAccountProfileEdit={isAccountProfileEdit}
                isSaving={isSaving}
                onSubmit={onSubmit}
                onUnsuccessfulCallback={onUnsuccessfulCallback}
                prefillValues={symphonyData}
                section={section}
              />
            ) : componentType === ComponentTypes.Beneficiary ? (
              (questionInRow.questionKey === 'question:beneficiary_primary' ||
                !!numberOfPrimaryBeneficiaries ||
                isAccountProfileEdit) && (
                <Beneficiaries
                  beneficiaryConfig={beneficiaryConfig}
                  beneficiaryData={filterRelationshipSymphonyData(questionInRow.questionKey)}
                  content={{
                    countriesList: dropdownsContent.countriesList,
                    questions: getCustomContent(questionInRow.questionKey, inputType, componentType),
                    validationMessages,
                  }}
                  contentOptions={contentOptions}
                  contingentBeneficiaryIds={contingentBeneficiaryIds}
                  ctas={ctas}
                  deleteKeysFromResult={deleteKeysFromResult}
                  formHooks={formHooks}
                  hidden={dependentDataPoints[question.dataPointKey]}
                  isAccountProfileEdit={isAccountProfileEdit}
                  maximumBeneficiaries={validationRules.max ?? 4}
                  minimumBeneficiaries={validationRules.min ?? 0}
                  numberOfContingentBeneficiaries={numberOfContingentBeneficiaries}
                  numberOfPrimaryBeneficiaries={numberOfPrimaryBeneficiaries}
                  questionKey={questionInRow.questionKey}
                  setBeneficiaryIds={setBeneficiaryIds}
                  setContingentBeneficiaryIds={setContingentBeneficiaryIds}
                  setNumberOfContingentBeneficiaries={setNumberOfContingentBeneficiaries}
                  setNumberOfPrimaryBeneficiaries={setNumberOfPrimaryBeneficiaries}
                  symphonyMappingKey={questionInRow.questionKey.split(':')[1]}
                />
              )
            ) : componentType === ComponentTypes.Contacts ? (
              <Contacts
                contactData={filterRelationshipSymphonyData(questionInRow.questionKey)}
                content={{
                  questions: getCustomContent(questionInRow.questionKey, inputType, componentType),
                  validationMessages,
                }}
                control={control}
                ctas={ctas}
                deleteKeysFromResult={deleteKeysFromResult}
                fieldsErrors={fieldsErrors}
                getValues={getValues}
                hidden={dependentDataPoints[question.dataPointKey]}
                isAccountProfileEdit={isAccountProfileEdit}
                maximumContacts={validationRules.max ?? 2}
                minimumContacts={validationRules.min ?? 1}
                numberOfContacts={numberOfContacts}
                questionKey={questionInRow.questionKey}
                setNumberOfContacts={setNumberOfContacts}
                statesList={getDropdownItems(CmsKeys.States, dropdownsContent, questionContent)}
                symphonyMappingKey={questionInRow.questionKey.split(':')[1]}
                trigger={trigger}
              />
            ) : componentType === ComponentTypes.Regulatory ? (
              <Regulatory
                content={{
                  questions: getCustomContent(questionInRow.questionKey, inputType, componentType),
                  validationMessages,
                  ...getRegulatoryTickerSearchContent(questionsContent),
                }}
                dropdownsContent={dropdownsContent}
                employmentData={symphonyData?.party?.partyCompany}
                employmentStatus={employmentStatus}
                formHooks={formHooks}
                paperworkId={symphonyData?.id ?? null}
                questionKey={questionInRow.questionKey}
                regulatoryData={symphonyData?.regulatoryInformation}
              />
            ) : componentType === ComponentTypes.InvestmentRestrictions ? (
              <InvestmentRestrictions
                content={{
                  questions: getCustomContent(questionInRow.questionKey, inputType, componentType),
                }}
                contentOptions={contentOptions}
                managedProductId={managedProductId}
                partyId={partyId}
                questionKey={questionInRow.questionKey}
              />
            ) : componentType === ComponentTypes.Tlh ? (
              <>
                <Button
                  aria-haspopup="true"
                  onClick={openModal}
                  startIcon={showTlhButtonIcon ? <LeafIcon color="primary" /> : ''}
                  variant={showTlhButtonIcon ? 'outlined' : 'contained'}
                >
                  {
                    getQuestionContent({
                      customComponents,
                      singleOptionInputContents,
                      textInputContents,
                      type: inputType,
                      questionKey: questionInRow.questionKey + (tlhValue ? '_off' : '_on'),
                      componentType,
                    }).question
                  }
                </Button>
                <TaxLossHarvestingModal
                  contentOptions={contentOptions}
                  onClose={onClose}
                  onFinish={handleTLHOnFinish}
                  open={open}
                  partyId={partyId}
                />
              </>
            ) : componentType === ComponentTypes.Currency ? (
              <Controller
                control={control}
                defaultValue={defaultValue}
                key={`question-${fieldName}`}
                name={fieldName}
                render={({ ref, value, name, onChange }) => {
                  return (
                    <CurrencyTextField
                      FormHelperTextProps={{ component: 'div' } as FormHelperTextProps}
                      contentOptions={contentOptions}
                      // We do not want to allow any decimal places for now as symphony doesn't support d.p.
                      decimalScale={0}
                      error={!!inputErrors}
                      fullWidth
                      id={fieldName}
                      inputRef={ref}
                      isAllowed={values =>
                        !!validationRules.maxLength && values.value.length <= +validationRules.maxLength
                      }
                      label={questionContent.question}
                      name={name}
                      onBlur={e => {
                        e.target.value = e.target.value.trim();
                        onChange(e.target.value.replace(/[$,]/g, ''));
                      }}
                      onValueChange={values => {
                        const { value: inputValue } = values;
                        if (validationRules.maxLength && inputValue.length > +validationRules.maxLength) {
                          return;
                        } else {
                          onChange(inputValue);
                        }
                      }}
                      value={parseFloat(value)}
                    />
                  );
                }}
                rules={
                  hidden
                    ? {}
                    : {
                        ...validationRules,
                        validate: {},
                      }
                }
              />
            ) : (
              componentType === ComponentTypes.FileUploader && (
                <FileUploader
                  ctaText={questionContent.question}
                  maxFileSizeInMB={validationRules.maxFileSize}
                  maxFileSizeMessage={getErrorMessage(ValidationNames.MaxFileSize, validationMessages)}
                  maxNumberOfFiles={validationRules.maxNumberOfFiles}
                  maxNumberOfFilesMessage={getErrorMessage(ValidationNames.MaxNumberOfFiles, validationMessages)}
                />
              )
            )}
          </Box>
          {/* Adding minHeight: '2.5vh' to mainatian all the fields in the same line, even if there are form errors for few fields */}
          <Box sx={{ width: 1, ...(isAccountProfileEdit && { minHeight: '2.5vh' }) }}>
            {!!inputErrors && errorMessage && (
              <>
                {componentType === ComponentTypes.CheckboxGroup ? (
                  <Alert severity="error" sx={{ my: 1 }}>
                    {errorMessage}
                  </Alert>
                ) : (
                  <Typography key={`error-${fieldName}`} role="alert" sx={{ color: 'error.main' }} variant="caption">
                    {errorMessage}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
      );
    });
  };
  return (
    <>
      {fetchingQuestions ||
      ((symphonyData || questionnaireInvestWealthData) && Object.keys(defaultValues ?? {}).length === 0) ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : isAccountProfileEdit ? (
        <Box data-qa={dataQa} sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {filteredQuestions.map((question, index) => {
            if (questionsRendered.has(question.questionKey)) {
              return null;
            }

            const hasAdjacent = question.adjacent !== undefined && question.adjacent !== null;
            const adjacentQuestion = hasAdjacent ? questions.find(q => q.questionKey === question.adjacent) : undefined;
            const questionsInRow = adjacentQuestion ? [question, adjacentQuestion] : [question];
            const hidden = dependentDataPoints[question.dataPointKey];
            const newLine = questionsInRow[questionsInRow.length - 1]?.rules[0]?.newLine ?? false;
            const fullWidthComponent =
              question.componentType === ComponentTypes.Label ||
              question.componentType === ComponentTypes.Info ||
              question.componentType === ComponentTypes.Beneficiary ||
              question.componentType === ComponentTypes.Contacts;

            return (
              <Box
                key={index}
                sx={{
                  width: newLine || fullWidthComponent ? 1 : 0.33,
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'flex-start',
                    my: 2,
                    width: newLine && !fullWidthComponent ? 0.33 : 1,
                    display: hidden ? 'none' : 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {renderQuestionComponent(questionsInRow, question, hidden)}
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box data-qa={dataQa}>
          {filteredQuestions.map((question, index) => {
            if (question.componentType === ComponentTypes.Hidden) {
              questionsRendered.add(question.questionKey);
              return null;
            }
            if (questionsRendered.has(question.questionKey)) {
              return null;
            }

            const hasAdjacent = question.adjacent !== undefined && question.adjacent !== null;
            const adjacentQuestion = hasAdjacent ? questions.find(q => q.questionKey === question.adjacent) : undefined;
            const questionsInRow = adjacentQuestion ? [question, adjacentQuestion] : [question];
            const hidden = dependentDataPoints[question.dataPointKey];

            return (
              <Box
                key={index}
                sx={{
                  alignItems: 'flex-start',
                  display: hidden ? 'none' : 'flex',
                  my: 2,
                  width: 1,
                }}
              >
                {renderQuestionComponent(questionsInRow, question, hidden)}
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};
