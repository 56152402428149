import { InputLabel, TextField as MuiTextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import uniqueId from 'lodash.uniqueid';
import * as React from 'react';

import { RteContent } from '~/components';

export type TextFieldVariant = TextFieldProps['variant'] | 'outlinedClassic';

export interface Props extends Omit<TextFieldProps, 'variant'> {
  ariaLabel?: string;
  dataQa?: string;
  variant?: TextFieldVariant;
}

export const TextField: React.FC<Props> = ({
  ariaLabel,
  dataQa = 'text-field',
  InputLabelProps,
  inputProps,
  label,
  variant = 'outlinedClassic',
  ...rest
}) => {
  const isOutlinedClassic = variant === 'outlinedClassic';
  // Generating Id if rest.id is undefined
  if (!rest.id) {
    rest.id = uniqueId(dataQa + '-id');
  }

  return (
    <>
      {isOutlinedClassic && (
        // When in a form, MuiFormLabel may set position: absolute and tranform, so override them here.
        <InputLabel
          error={rest.error}
          htmlFor={rest.id}
          {...InputLabelProps}
          sx={{ position: 'relative', transform: 'none', ...InputLabelProps?.sx }}
        >
          <RteContent data={(label || '') as string} sx={{ whiteSpace: 'normal' }} />
        </InputLabel>
      )}
      <MuiTextField
        data-qa={dataQa}
        inputProps={{
          'aria-label': ariaLabel,
          ...inputProps,
        }}
        label={isOutlinedClassic ? undefined : label}
        variant={isOutlinedClassic ? 'outlined' : (variant as TextFieldProps['variant'])}
        {...rest}
      />
    </>
  );
};
