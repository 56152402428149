import { styled, useTheme } from '@mui/material';

import { UncheckedRadioIcon } from './UncheckedIcon';

export const CheckedRadioIcon = styled(UncheckedRadioIcon)(() => {
  const {
    sfRadioGroup: {
      styles: { radioIcons: radioIconStyles },
    },
  } = useTheme();

  return {
    ...radioIconStyles?.checkedIcon,
  };
});
