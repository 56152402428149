// The function runs a regular expression to validate the input value.
// It checks if the input value contains only alphanumeric characters and some special characters except |.
const validateByRegex = (value: string) => /^[:;"'<>,./?0-9a-zA-Z `~!@#$%^&*()-_=+[\]{}\\]*$/.test(value);

export const isInputLengthValid = (input: string, maxLength?: number) => !maxLength || input.length <= maxLength;

export const isCompanyNameInputChangeAllowed = (input: string, maxLength?: number) => {
  if (input.length > 0) {
    return validateByRegex(input) && isInputLengthValid(input, maxLength);
  }
  return true;
};
