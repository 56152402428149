import { visuallyHidden } from '@mui/utils';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { useGetPaperworkAccessibilityContent } from '../hooks/useGetPaperworkAccessibilityContent';
import { BeneficiaryConfig, QuestionContent, ValidationMessage } from '../Section/Questions/types';
import { FormData } from '../types';
import { getTempBeneficiaryId } from '../utils/beneficiary';
import { getCtaText } from '../utils/ctatext';

import { Beneficiary } from './Beneficiary';

import { RelationshipSubtype } from '~/__generated__';
import { AccountProfileContext } from '~/components/AccountProfileUpdateWrapper/context';
import { Box, Button, DropdownItem, Typography, useTheme } from '~/components/ui';
import { CTAs } from '~/containers/AccountProfile/contentstack';
import { PaperworkRelationships } from '~/containers/Paperwork/symphony';
import { ContentOptions, SfTheme } from '~/utils';

export interface BeneficiaryTypeError {
  spouseCount: boolean;
}
export interface RelationshipDetails {
  fieldValues: {
    [x: string]: any;
  };
  ids: string[];
  isPrimaryBeneficiary: boolean;
  removeId?: string;
  symphonyMappingKey: string;
}

export interface Props {
  beneficiaryConfig: BeneficiaryConfig;
  beneficiaryData?: (PaperworkRelationships | null)[];
  content: {
    countriesList: DropdownItem[];
    questions: QuestionContent[];
    validationMessages?: ValidationMessage[];
  };
  contentOptions: ContentOptions;
  contingentBeneficiaryIds?: string[];
  ctas?: CTAs[];
  dataQa?: string;
  deleteKeysFromResult: (keys: string[], relationshipDetails?: RelationshipDetails) => void;
  formHooks: UseFormMethods<FormData>;
  hidden: boolean;
  isAccountProfileEdit?: boolean;
  maximumBeneficiaries: number;
  minimumBeneficiaries: number;
  numberOfContingentBeneficiaries: any;
  numberOfPrimaryBeneficiaries: any;
  questionKey: string;
  setBeneficiaryIds?: (ids: string[]) => void;
  setContingentBeneficiaryIds?: (ids: string[]) => void;
  setNumberOfContingentBeneficiaries: any;
  setNumberOfPrimaryBeneficiaries: any;
  symphonyMappingKey: string;
}

export const Beneficiaries: FC<Props> = ({
  ctas,
  dataQa = 'beneficiaries',
  formHooks,
  beneficiaryConfig,
  content,
  contentOptions,
  contingentBeneficiaryIds,
  hidden,
  isAccountProfileEdit,
  minimumBeneficiaries,
  maximumBeneficiaries,
  setNumberOfPrimaryBeneficiaries,
  numberOfPrimaryBeneficiaries,
  setNumberOfContingentBeneficiaries,
  numberOfContingentBeneficiaries,
  questionKey,
  symphonyMappingKey,
  beneficiaryData,
  deleteKeysFromResult,
  setBeneficiaryIds,
  setContingentBeneficiaryIds,
}) => {
  const {
    sfPaperwork: { styles: style },
  } = useTheme<SfTheme>();
  const { disablePageEditing } = useContext(AccountProfileContext);
  const { data: accessibilityContent } = useGetPaperworkAccessibilityContent(contentOptions);
  const ctaStyle = style.sectionCTA ? { ...style.sectionCTA } : {};
  const [percentages, setPercentages] = useState<Record<string, number>>({});
  const beneficiaryTypeIndexValueObjRef = useRef<string[]>([]);
  const [percentageSumError, setPercentageSumError] = useState(false);
  const [beneficiaryTypeError, setBeneficiaryTypeError] = useState<BeneficiaryTypeError>({
    spouseCount: false,
  });
  const [beneficiaryIdList, setBeneficiaryIdList] = useState<string[]>([]);
  const [addOrRemoveClickedLabel, setAddOrRemoveClickedLabel] = useState('');
  const isPrimaryBeneficiary = questionKey === 'question:beneficiary_primary';
  useEffect(() => {
    const values = Object.values(percentages);
    if (values.length > 0) {
      const totalPercentage = values.reduce((acc, curr) => acc + curr);

      if (totalPercentage !== 100) {
        setPercentageSumError(true);
      } else if (percentageSumError) {
        setPercentageSumError(false);
      }
    }
  }, [percentages]);
  useEffect(() => {
    if (!isPrimaryBeneficiary && contingentBeneficiaryIds) {
      setBeneficiaryIdList(contingentBeneficiaryIds);
    }
  }, [contingentBeneficiaryIds]);
  useEffect(() => {
    if (beneficiaryData) {
      beneficiaryData.map(beneficiary => {
        beneficiaryIdList.push(beneficiary?.id ?? '');
      });
      setBeneficiaryIdList(beneficiaryIdList);
      if (isPrimaryBeneficiary) {
        setNumberOfPrimaryBeneficiaries(beneficiaryData.length);
      } else {
        setNumberOfContingentBeneficiaries(beneficiaryData.length);
      }
      updateBeneficiariesIds(beneficiaryIdList);
    }
    // Populate minimum benef. only when there is no pre-populated data.
    if (!beneficiaryData || beneficiaryData?.length === 0) {
      for (let i = 0; i < minimumBeneficiaries; i++) {
        onAdd(true);
      }
    }
  }, []);

  const updateBeneficiariesIds = (beneficiaryIds: string[], _isPrimaryBeneficiary: boolean = isPrimaryBeneficiary) => {
    if (_isPrimaryBeneficiary) {
      setBeneficiaryIds?.(beneficiaryIds);
    } else {
      setContingentBeneficiaryIds?.(beneficiaryIds);
    }
  };

  // For addition of Primary Benef, isPrimaryBenef = true. For contingent, isPrimaryBenef = false
  const onAdd = (_isPrimaryBeneficiary: boolean) => {
    const newBeneficiaryIds = [...beneficiaryIdList, getTempBeneficiaryId()];
    setBeneficiaryIdList(newBeneficiaryIds);
    updateBeneficiariesIds(newBeneficiaryIds, _isPrimaryBeneficiary);
    if (_isPrimaryBeneficiary) {
      setNumberOfPrimaryBeneficiaries(numberOfPrimaryBeneficiaries + 1);
    } else {
      setNumberOfContingentBeneficiaries(numberOfContingentBeneficiaries + 1);
    }

    if (accessibilityContent?.beneficiaryAdded) {
      setAddOrRemoveClickedLabel(accessibilityContent.beneficiaryAdded);
    }
  };

  const checkSpouseCount = () => {
    let spouseCount = 0;
    beneficiaryTypeIndexValueObjRef.current.forEach(beneficiaryType => {
      if (beneficiaryType === RelationshipSubtype.SPOUSE) {
        spouseCount++;
      }
    });
    setBeneficiaryTypeError({
      spouseCount: spouseCount > 1,
    });
  };

  const onRemove = (removeIndex?: number) => {
    if (isPrimaryBeneficiary) {
      if (numberOfPrimaryBeneficiaries === 1) {
        setNumberOfContingentBeneficiaries(0);
        setContingentBeneficiaryIds?.([]);
      }
      setNumberOfPrimaryBeneficiaries(numberOfPrimaryBeneficiaries - 1);
    } else {
      setNumberOfContingentBeneficiaries(numberOfContingentBeneficiaries - 1);
    }
    const indexOfRemovedItem =
      removeIndex !== undefined
        ? beneficiaryIdList[removeIndex]
        : isPrimaryBeneficiary
        ? numberOfPrimaryBeneficiaries - 1
        : numberOfContingentBeneficiaries - 1;
    setPercentages(prevPercentages => {
      delete prevPercentages[`${symphonyMappingKey}-percentage-${indexOfRemovedItem}`];
      return { ...prevPercentages };
    });
    deleteKeysFromResult(
      [
        `${symphonyMappingKey}-name-${indexOfRemovedItem}`,
        `${symphonyMappingKey}-first_name-${indexOfRemovedItem}`,
        `${symphonyMappingKey}-last_name-${indexOfRemovedItem}`,
        `${symphonyMappingKey}-ulid-${indexOfRemovedItem}`,
        `${symphonyMappingKey}-dob-${indexOfRemovedItem}`,
        `${symphonyMappingKey}-type-${indexOfRemovedItem}`,
        `${symphonyMappingKey}-percentage-${indexOfRemovedItem}`,
        `${symphonyMappingKey}-ssn-${indexOfRemovedItem}`,
      ],
      removeIndex !== undefined
        ? {
            ids: [...beneficiaryIdList],
            fieldValues: formHooks.control.getValues(),
            symphonyMappingKey,
            removeId: beneficiaryIdList[removeIndex],
            isPrimaryBeneficiary,
          }
        : undefined,
    );

    if (removeIndex !== undefined) {
      beneficiaryIdList.splice(removeIndex, 1);
      beneficiaryTypeIndexValueObjRef.current.splice(removeIndex, 1);
    } else {
      beneficiaryIdList.pop();
    }
    setBeneficiaryIdList(beneficiaryIdList);
    updateBeneficiariesIds(beneficiaryIdList);
    if (accessibilityContent?.beneficiaryRemoved) {
      setAddOrRemoveClickedLabel(accessibilityContent.beneficiaryRemoved);
    }
    checkSpouseCount();
  };

  useEffect(() => {
    if (addOrRemoveClickedLabel) {
      // remove label value once it is announced.
      setTimeout(() => setAddOrRemoveClickedLabel(''), 1000);
    }
  }, [addOrRemoveClickedLabel]);

  const onPercentageChange = (name: string, value: number) => {
    setPercentages(prevPercentages => {
      return { ...prevPercentages, [name]: value };
    });
  };

  const onBeneficiaryTypeChange = (index: number, value: string) => {
    beneficiaryTypeIndexValueObjRef.current[index] = value;
    checkSpouseCount();
  };
  const { questions } = content;
  const addCtaText =
    isAccountProfileEdit && ctas
      ? isPrimaryBeneficiary
        ? getCtaText(ctas, 'addPrimaryBeneficiary')
        : getCtaText(ctas, 'addContingentBeneficiary')
      : questions.find(q => q.key === `${questionKey}_add_cta`)?.question;
  return (
    <Box data-qa={dataQa}>
      {!isAccountProfileEdit && (
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ mr: 1 }} variant="subtitle1">
            {questions.find(q => q.key === `${questionKey}_title`)?.question}
          </Typography>
          <Typography sx={{ alignSelf: 'center' }} variant="caption">
            {questions.find(q => q.key === `${questionKey}_subtitle`)?.question}
          </Typography>
        </Box>
      )}
      {beneficiaryIdList.map((beneficiaryId, number) => {
        return (
          <Box key={beneficiaryId}>
            <Beneficiary
              beneficiaryConfig={beneficiaryConfig}
              beneficiaryTypeError={beneficiaryTypeError}
              beneficiaryUniqueId={isAccountProfileEdit ? beneficiaryId : number.toString()}
              content={content}
              formHooks={formHooks}
              hidden={hidden}
              index={number}
              isAccountProfileEdit={isAccountProfileEdit}
              key={beneficiaryId}
              onBeneficiaryTypeChange={onBeneficiaryTypeChange}
              onPercentageChange={onPercentageChange}
              percentageSumError={percentageSumError}
              prefillData={beneficiaryData ? beneficiaryData[number] : ''}
              questionKey={questionKey}
              symphonyMappingKey={symphonyMappingKey}
            />
            {isAccountProfileEdit && (!isPrimaryBeneficiary || numberOfPrimaryBeneficiaries > 1) && (
              <Button disabled={disablePageEditing} onClick={() => onRemove(number)} sx={ctaStyle}>
                {questions.find(q => q.key === `${questionKey}_account-profile-remove_cta`)?.question ?? 'Remove'}
              </Button>
            )}
          </Box>
        );
      })}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: isAccountProfileEdit ? 3 : 2 }}>
        {(isPrimaryBeneficiary ? numberOfPrimaryBeneficiaries : numberOfContingentBeneficiaries) <
          maximumBeneficiaries && (
          <Button
            aria-label={
              isPrimaryBeneficiary
                ? accessibilityContent?.primaryBeneficiaryAddButton
                : accessibilityContent?.beneficiaryAddButton
            }
            disabled={disablePageEditing}
            onClick={() => onAdd(isPrimaryBeneficiary)}
            sx={ctaStyle}
            variant={isAccountProfileEdit ? 'outlined' : 'text'}
          >
            {addCtaText ?? '+ Add'}
          </Button>
        )}
        {(isPrimaryBeneficiary ? numberOfPrimaryBeneficiaries : numberOfContingentBeneficiaries) >
          minimumBeneficiaries &&
          !isAccountProfileEdit && (
            <Button
              aria-label={
                isPrimaryBeneficiary
                  ? accessibilityContent?.primaryBeneficiaryRemoveButton
                  : accessibilityContent?.beneficiaryRemoveButton
              }
              onClick={() => onRemove()}
              sx={ctaStyle}
            >
              {questions.find(q => q.key === `${questionKey}_remove_cta`)?.question ?? 'Remove'}
            </Button>
          )}
      </Box>
      <Typography aria-live="polite" style={visuallyHidden}>
        {addOrRemoveClickedLabel}
      </Typography>
    </Box>
  );
};
