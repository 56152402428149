import React, { FC, useEffect, useState } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';

import { FinancialAccountType } from '../../../__generated__/symphonyTypes';
import { Alert, Box, CurrencyTextField, Grid, InputAdornment, Typography } from '../../../components';
import { AssetsHeldAwayConfig, QuestionContent, ValidationMessage } from '../Section/Questions/types';
import { FormData } from '../types';

import { completeAssetsHeldAwayMapping } from './config';

import { ContentOptions, isTrustAccountType } from '~/utils';

export interface Props {
  accountType: FinancialAccountType;
  assetsHeldAwayConfig?: AssetsHeldAwayConfig;
  content?: {
    questions: QuestionContent[];
    validationMessages?: ValidationMessage[];
  };
  contentOptions: ContentOptions;
  dataQa?: string;
  disablePageEditing?: boolean;
  formHooks: UseFormMethods<FormData>;
  isAccountProfileEdit?: boolean;
  isSaving?: boolean;
  onSubmit: (section: string, formData: any, hiddenDataPoints: Record<string, boolean>) => void;
  onUnsuccessfulCallback: () => void;
  prefillValues: any;
  section: string;
}

export const AssetsHeldAway: FC<Props> = ({
  dataQa = 'assets-held-away',
  accountType,
  assetsHeldAwayConfig,
  content,
  contentOptions,
  formHooks: {
    control,
    formState: { isSubmitted },
    getValues,
    handleSubmit,
    setValue,
    watch,
  },
  isAccountProfileEdit,
  isSaving,
  disablePageEditing,
  onSubmit,
  onUnsuccessfulCallback,
  prefillValues,
  section,
}) => {
  const [percentageArray, setPercentageArray] = useState<number[]>([]);
  const [totalFunds, setTotalFunds] = useState<string | number>(
    prefillValues?.investment?.experienceDetail?.total?.qualified ?? '0.00',
  );
  const watchAllFields = watch();

  const getUpdatedLargestValueIndicesArray = (allFieldsValues: any[]): number[] => {
    const arrWithIndices = allFieldsValues.filter(field => !isNaN(field)).map((value, index) => ({ value, index }));
    return arrWithIndices.sort((a, b) => Number(b.value) - Number(a.value)).map(obj => obj.index);
  };

  const roundToTwoDecimal = (value: number): number => {
    return Math.round(value * 100) / 100;
  };

  useEffect(() => {
    const allFieldsValues = Object.values(getValues());
    const { total: totalAssetValues, largestValueIndicesArray: largestAssetValueIndicesArray } = allFieldsValues.reduce(
      ({ total, largestValueIndicesArray }, currValue) => {
        largestValueIndicesArray = getUpdatedLargestValueIndicesArray(allFieldsValues);
        total += Number(currValue);
        return { total, largestValueIndicesArray };
      },
      { total: 0, largestValueIndicesArray: [] },
    );

    let sumOfAllPercentage = 0;
    const newPercentageArray = allFieldsValues.map(value => {
      const percentageValue =
        !isNaN(value) && Number(totalAssetValues) > 0 ? Math.round((Number(value) / totalAssetValues) * 100) : 0;
      sumOfAllPercentage += percentageValue;
      return percentageValue;
    });

    // updating the largest asset value percentage to make sum equals to 100
    while (100 - sumOfAllPercentage !== 0 && sumOfAllPercentage !== 0) {
      const largestAssetValueIndex = largestAssetValueIndicesArray.shift();
      if (100 - sumOfAllPercentage < 0) {
        newPercentageArray[largestAssetValueIndex] -= 1;
        sumOfAllPercentage -= 1;
      } else {
        newPercentageArray[largestAssetValueIndex] += 1;
        sumOfAllPercentage += 1;
      }
    }

    setTotalFunds(totalAssetValues > 0 ? roundToTwoDecimal(totalAssetValues) : '0.00');
    if (JSON.stringify(newPercentageArray) !== JSON.stringify(percentageArray)) {
      setPercentageArray([...newPercentageArray]);
    }
  }, [watchAllFields, getValues, percentageArray]);

  useEffect(() => {
    setPercentageArray(
      new Array(completeAssetsHeldAwayMapping.length - (assetsHeldAwayConfig?.excludeQuestions?.length ?? 0)).fill(0),
    );
  }, []);

  const isTotalFundsPositive = typeof totalFunds === 'string' ? parseFloat(totalFunds) > 0 : totalFunds > 0;

  const handleSuccessfulSubmit = (formData: any) => {
    const formDataObj = formData as Record<string, any>;
    if (isTotalFundsPositive || isSaving) {
      const assetPercentageMap = Object.keys(formDataObj)
        .map((k, i) => {
          const keySplit = k.split(':');
          keySplit.splice(1, 1, keySplit[1] + 'Percentage');
          const newKey = keySplit.join(':');
          return { [newKey]: percentageArray[i] };
        })
        .reduce((obj, item) => ({ ...obj, ...item }), {});
      onSubmit(
        section,
        { ...formDataObj, ...assetPercentageMap, 'data_point:totalValue:string': totalFunds.toString() },
        {},
      );
    } else {
      onUnsuccessfulCallback();
    }
  };

  useEffect(() => {
    if (isSaving) {
      handleSuccessfulSubmit(getValues());
    }
  }, [isSaving, handleSuccessfulSubmit, getValues]);

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit(handleSuccessfulSubmit, onUnsuccessfulCallback)();
    }
  }, [isSubmitted]);

  const getController = (componentName: string, percentageValue?: number, label?: string, defaultValue?: string) => {
    return (
      <Grid item xs={isAccountProfileEdit ? 4 : 12}>
        <Box
          key={componentName}
          sx={{
            my: isAccountProfileEdit ? 2 : 1,
            display: 'flex',
            flexDirection: 'row',
            width: 1,
          }}
        >
          <Box sx={{ minWidth: 0.83 }}>
            <Controller
              control={control}
              defaultValue={defaultValue ?? ''}
              name={componentName}
              render={({ ref, value }) => (
                <CurrencyTextField
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  contentOptions={contentOptions}
                  currencySymbol=""
                  data-qa={`${dataQa}-${componentName}`}
                  decimalScale={0}
                  disabled={disablePageEditing}
                  error={isSubmitted && !isTotalFundsPositive}
                  fullWidth
                  id={componentName}
                  inputRef={ref}
                  label={label}
                  name={componentName}
                  onValueChange={({ value: newValue }) =>
                    setValue(componentName, newValue, { shouldDirty: true, shouldValidate: true })
                  }
                  placeholder="0"
                  value={parseFloat(value)}
                />
              )}
            />
            <Box sx={{ width: 1 }}>
              {!isTotalFundsPositive && isSubmitted && errorMessage && (
                <Typography key={`error-${componentName}`} role="alert" sx={{ color: 'error.main' }} variant="caption">
                  {errorMessage}
                </Typography>
              )}
            </Box>
          </Box>
          <Typography data-testid={`${dataQa}-percentage`} sx={{ mt: 4, ml: 1.5 }} variant="body1">
            {`${percentageValue ?? 0}%`}
          </Typography>
        </Box>
      </Grid>
    );
  };

  const errorMessage = content?.questions.find(question => question.key === 'question:assets_held_away_error_message')
    ?.question;

  const totalAssetLabel = content?.questions.find(
    question => question.key === 'question:assets_held_away_total_asset_value',
  )?.question;

  const assetHeldAwayInfo = content?.questions.find(
    question =>
      question.key ===
      (isTrustAccountType(accountType) ? 'question:assets_held_away_trust_info' : 'question:assets_held_away_info'),
  )?.question;

  return (
    <Box data-qa={dataQa}>
      <Alert contentOptions={contentOptions} data-qa="component-info-" severity="info" sx={{ mt: 2 }}>
        {assetHeldAwayInfo}
      </Alert>
      <Grid container>
        {completeAssetsHeldAwayMapping
          .filter(asset => !assetsHeldAwayConfig?.excludeQuestions?.includes(asset.questionKey))
          .map((assetConfig, index) => {
            const questionLabel = content?.questions.find(question => question.key === assetConfig.questionKey)
              ?.question;
            const defaultValue =
              prefillValues?.investment?.experienceDetail?.[assetConfig.symphonyMapping.split('.')[2]]?.qualified ?? '';
            return getController(assetConfig.dataPointKey, percentageArray[index], questionLabel ?? '', defaultValue);
          })}
      </Grid>
      <Box sx={{ mt: 3, mb: 1.5, display: 'flex', flexDirection: 'row', width: 1 }}>
        <Box sx={{ minWidth: 0.83 }}>
          <Typography variant="caption">{totalAssetLabel}</Typography>
          <Typography
            component="p"
            data-testid={`${dataQa}-total-asset`}
            sx={{ mt: 2 }}
            variant="subtitle1"
          >{`$${totalFunds}`}</Typography>
        </Box>
        <Typography component="p" sx={{ mt: 4, ml: 1.5 }} variant="subtitle1">{`${
          Number(totalFunds) > 0 ? '100%' : '0%'
        }`}</Typography>
      </Box>
    </Box>
  );
};
