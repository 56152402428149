import { CompletionMessageContent } from '~/components/CompletionMessage';
import { DocusignUserType } from '~/containers/DocusignDAProCompleted';

export interface DocusignWaitingOrCompletedContentInput {
  clientDeclinedClientView?: CompletionMessageContent;
  clientDeclinedFaView?: CompletionMessageContent;
  clientDeclinedUnauthenticatedView?: CompletionMessageContent;
  clientSignDocusign?: CompletionMessageContent;
  clientSignedClientView?: CompletionMessageContent;
  clientSignedFaView?: CompletionMessageContent;
  clientSignedUnauthenticatedView?: CompletionMessageContent;
  clientWillFinishLaterClientView?: CompletionMessageContent;
  clientWillFinishLaterUnauthenticated?: CompletionMessageContent;
  clientWithBeneficiariesSignedUnauthenticatedView?: CompletionMessageContent;
  faDeclined?: CompletionMessageContent;
  faSignDocusign?: CompletionMessageContent;
  faSigned?: CompletionMessageContent;
  faWillFinishLater?: CompletionMessageContent;
  waitingForClientSignature?: CompletionMessageContent;
  waitingForFASignature?: CompletionMessageContent;
}

interface DocusignCompletionMessageContentProps {
  clientDeclined?: boolean;
  clientSignDocusign?: boolean;
  clientSigned?: boolean;
  clientWillFinishLater?: boolean;
  clientWithBeneficiariesSigned?: boolean;
  contentData?: DocusignWaitingOrCompletedContentInput;
  faDeclined?: boolean;
  faSignDocusign?: boolean;
  faSigned?: boolean;
  faWillFinishLater?: boolean;
  isUserUnauthenticated?: boolean;
  userType: DocusignUserType;
}

export const getContentData = (content?: CompletionMessageContent | null): CompletionMessageContent => {
  return {
    heading: content?.heading ?? '',
    subHeading: content?.subHeading ?? { data: '' },
    moreInfo: content?.moreInfo ?? '',
    imageUrl: content?.imageUrl ?? '',
    primaryCTA: content?.primaryCTA ?? '',
  };
};

export const getDocusignCompletionMessageContent = ({
  contentData,
  clientSigned,
  clientWithBeneficiariesSigned,
  clientDeclined,
  clientSignDocusign,
  clientWillFinishLater,
  faSignDocusign,
  faSigned,
  faDeclined,
  faWillFinishLater,
  userType,
  isUserUnauthenticated,
}: DocusignCompletionMessageContentProps): CompletionMessageContent => {
  let content;
  if (userType === DocusignUserType.FA) {
    if (clientDeclined) {
      content = contentData?.clientDeclinedFaView;
    } else if (faSignDocusign) {
      content = contentData?.faSignDocusign;
    } else if (clientSigned) {
      content = contentData?.clientSignedFaView;
    } else if (faDeclined) {
      content = contentData?.faDeclined;
    } else if (faSigned) {
      content = contentData?.faSigned;
    } else if (faWillFinishLater) {
      content = contentData?.faWillFinishLater;
    } else {
      content = contentData?.waitingForClientSignature;
    }
  } else if (isUserUnauthenticated) {
    if (clientDeclined) {
      content = contentData?.clientDeclinedUnauthenticatedView;
    } else if (clientSigned) {
      content = contentData?.clientSignedUnauthenticatedView;
    } else if (clientWithBeneficiariesSigned) {
      content = contentData?.clientWithBeneficiariesSignedUnauthenticatedView;
    } else if (clientWillFinishLater) {
      content = contentData?.clientWillFinishLaterUnauthenticated;
    }
  } else {
    // if userType is client
    if (clientDeclined) {
      content = contentData?.clientDeclinedClientView;
    } else if (clientSigned) {
      content = contentData?.clientSignedClientView;
    } else if (clientWillFinishLater) {
      content = contentData?.clientWillFinishLaterClientView;
    } else if (clientSignDocusign) {
      content = contentData?.clientSignDocusign;
    }
  }
  // TODO: Add Waiting for Fa signature case in future
  return getContentData(content);
};
