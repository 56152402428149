import { DocusignWaitingOrCompletedContentInput } from '../DocusignWaitingOrCompleted/utils';

import { LegalDocuments } from './symphony';

import { CompletionMessageContent } from '~/components/CompletionMessage';
import { DocusignUserType } from '~/containers/DocusignDAProCompleted';

export const concateUserEmailtoSubHeading = ({
  contentData,
  userEmail,
  userType,
}: {
  contentData?: DocusignWaitingOrCompletedContentInput;
  userEmail: string;
  userType: DocusignUserType;
}): DocusignWaitingOrCompletedContentInput => {
  const waitingForClientSignature: CompletionMessageContent = {
    ...contentData?.waitingForClientSignature,
    subHeading: { data: contentData?.waitingForClientSignature?.subHeading?.data ?? '', config: { email: userEmail } },
  };
  const waitingForFASignature: CompletionMessageContent = {
    ...contentData?.waitingForClientSignature,
    subHeading: { data: contentData?.waitingForFASignature?.subHeading?.data ?? '', config: { email: userEmail } },
  };
  const contentDataWithEmail = {
    ...contentData,
    ...(userType === DocusignUserType.FA ? { waitingForClientSignature } : { waitingForFASignature }),
  };
  return contentDataWithEmail;
};

export const getLegalDocumentsWithSigningDocumentId = (
  signingDocumentId: string,
  legalDocuments?: LegalDocuments[] | null,
): LegalDocuments | undefined => {
  return legalDocuments?.find(item => item.signingDocumentId === signingDocumentId);
};
