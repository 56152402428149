import React, { FC, useEffect, useState } from 'react';

import { DocusignWaitingOrCompleted } from '../DocusignWaitingOrCompleted';
import { DocusignWaitingOrCompletedContentInput } from '../DocusignWaitingOrCompleted/utils';

import { useGetDocusignDocument } from './symphony';

import { AssociatedEntityType, LegalDocumentStatus } from '~/__generated__';
import { Box, Skeleton } from '~/components';
import { DocusignIframe } from '~/components/DocusignIframe';
import { Alert } from '~/components/ui';
import { DocusignUserType } from '~/containers/DocusignDAProCompleted';
import { ContentOptions } from '~/utils/contentstack/src/types';

export interface Props {
  baseUrl: string;
  contentData?: DocusignWaitingOrCompletedContentInput;
  contentError?: Error;
  contentLoading: boolean;
  contentOptions: ContentOptions;
  dataQa?: string;
  docusignPartyId: string;
  managedProductId: string;
  onBack: () => void;
  onIframeReady?: () => void;
  returnToUrl: string;
  updateWorkflowId: string;
  updateWorkflowType: AssociatedEntityType;
  userType: DocusignUserType;
}

export const SignDocusign: FC<Props> = ({
  contentOptions,
  dataQa = 'docusign',
  baseUrl,
  returnToUrl,
  docusignPartyId,
  managedProductId,
  onIframeReady,
  contentError,
  contentLoading,
  onBack,
  contentData,
  updateWorkflowId,
  updateWorkflowType,
  userType,
}) => {
  const [loading, setLoading] = useState(true);
  const [linkToDocument, setLinkToDocument] = useState<string>();

  const { data: docusignData, loading: docusignDataLoading, error: docusignDataError } = useGetDocusignDocument({
    variables: { partyId: docusignPartyId, managedProductId, baseUrl, returnToUrl },
  });

  useEffect(() => {
    if (docusignData) {
      const legalDocuments = docusignData.managedProduct?.legalDocuments;
      if (updateWorkflowId) {
        let legalDocument;
        if (updateWorkflowType === AssociatedEntityType.PLAN_UPDATE_WORKFLOW) {
          legalDocument = legalDocuments?.find(ld =>
            ld.associatedEntities?.planUpdateWorkflowIds.includes(updateWorkflowId),
          );
        } else if (updateWorkflowType === AssociatedEntityType.BANK_ACCOUNT_ASSOCIATION) {
          legalDocument = legalDocuments?.find(
            ld =>
              ld.associatedEntities?.bankAccountAssociationIds.includes(updateWorkflowId) &&
              [
                LegalDocumentStatus.PENDING,
                LegalDocumentStatus.PENDING_PRIMARY,
                LegalDocumentStatus.PENDING_SECONDARY,
              ].includes(ld.status),
          );
        } else if (updateWorkflowType === AssociatedEntityType.ASSET_DEPOSIT) {
          legalDocument = legalDocuments?.find(ld => ld.associatedEntities?.assetDepositIds.includes(updateWorkflowId));
        } else {
          legalDocument = legalDocuments?.find(ld =>
            ld.associatedEntities?.entityUpdateWorkflowIds.includes(updateWorkflowId),
          );
        }

        if (legalDocument?.linkToDocument) {
          setLinkToDocument(legalDocument.linkToDocument);
          onIframeReady?.();
        }
      }
    }
  }, [docusignData, onIframeReady, updateWorkflowId, updateWorkflowType]);

  const loadingState = docusignDataLoading || contentLoading;
  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  return (
    <Box data-qa={dataQa} sx={{ py: 2 }}>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : docusignDataError || contentError ? (
        <Alert contentOptions={contentOptions} error={docusignDataError || contentError} severity="error" />
      ) : (
        <>
          <DocusignWaitingOrCompleted
            clientSignDocusign={userType === DocusignUserType.Client}
            contentData={contentData}
            contentError={contentError}
            contentLoading={contentLoading}
            contentOptions={contentOptions}
            faSignDocusign={userType === DocusignUserType.FA}
            onBack={onBack}
            userType={userType}
          />
          {linkToDocument && (
            <>
              <DocusignIframe
                dataQa={dataQa}
                helperHeading={
                  userType === DocusignUserType.FA
                    ? contentData?.faSignDocusign?.helperTexts?.heading
                    : contentData?.clientSignDocusign?.helperTexts?.heading
                }
                helperSubHeading={
                  userType === DocusignUserType.FA
                    ? contentData?.faSignDocusign?.helperTexts?.subHeading
                    : contentData?.clientSignDocusign?.helperTexts?.subHeading
                }
                linkToDocument={linkToDocument}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};
