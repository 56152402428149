import {
  GetAccountProfileManagedProduct,
  GetAccountProfileManagedProduct_managedProduct_entityUpdateWorkflow,
  GetAccountProfileManagedProduct_managedProduct_entityUpdateWorkflow_temporaryPaperworkList_paperwork,
  GetAccountProfileManagedProduct_managedProduct_entityUpdateWorkflow_temporaryPaperworkList_paperwork_party,
  GetAccountProfileManagedProduct_managedProduct_entityUpdateWorkflow_temporaryPaperworkList_paperwork_party_partyPerson,
  GetAccountProfileManagedProduct_managedProduct_entityUpdateWorkflow_temporaryPaperworkList_paperwork_party_partyPerson_language,
  GetAccountProfileManagedProductVariables,
} from './__generated__/GetAccountProfileManagedProduct';
import * as queries from './query.gql';

import { useSymphonyQuery } from '~/utils';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';

export type EntityUpdateWorkflowPaperwork = GetAccountProfileManagedProduct_managedProduct_entityUpdateWorkflow_temporaryPaperworkList_paperwork;
export type EntityUpdateWorkflow = GetAccountProfileManagedProduct_managedProduct_entityUpdateWorkflow;
export type PartyPerson = GetAccountProfileManagedProduct_managedProduct_entityUpdateWorkflow_temporaryPaperworkList_paperwork_party_partyPerson;
export type PaperworkParty = GetAccountProfileManagedProduct_managedProduct_entityUpdateWorkflow_temporaryPaperworkList_paperwork_party;
export type PartyPersonLanguage = GetAccountProfileManagedProduct_managedProduct_entityUpdateWorkflow_temporaryPaperworkList_paperwork_party_partyPerson_language;
export const useGetAccountProfileManagedProduct = (
  options?: QueryHookOptions<GetAccountProfileManagedProduct, GetAccountProfileManagedProductVariables>,
): QueryResult<GetAccountProfileManagedProduct, GetAccountProfileManagedProductVariables> => {
  return useSymphonyQuery(queries.GetAccountProfileManagedProduct, options);
};
