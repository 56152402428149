import React, { FC } from 'react';

import { DocusignWaitingOrCompletedContentInput, getDocusignCompletionMessageContent } from './utils';

import { CompletionMessage, CompletionMessageContent } from '~/components/CompletionMessage';
import { DocusignUserType } from '~/containers/DocusignDAProCompleted';
import { ContentOptions } from '~/utils';

export interface Props {
  clientDeclined?: boolean;
  clientSignDocusign?: boolean;
  clientSigned?: boolean;
  clientWillFinishLater?: boolean;
  clientWithBeneficiariesSigned?: boolean;
  contentData?: DocusignWaitingOrCompletedContentInput;
  contentError?: Error;
  contentLoading: boolean;
  contentOptions: ContentOptions;
  faDeclined?: boolean;
  faSignDocusign?: boolean;
  faSigned?: boolean;
  faWillFinishLater?: boolean;
  isUserUnauthenticated?: boolean;
  onBack: () => void;
  userType: DocusignUserType;
}

export const DocusignWaitingOrCompleted: FC<Props> = ({
  clientDeclined,
  clientSignDocusign,
  clientSigned,
  clientWithBeneficiariesSigned,
  clientWillFinishLater,
  contentData,
  contentError,
  contentLoading,
  contentOptions,
  faDeclined,
  faSignDocusign,
  faWillFinishLater,
  faSigned,
  isUserUnauthenticated,
  onBack,
  userType,
}) => {
  const completionMessageContent: CompletionMessageContent = getDocusignCompletionMessageContent({
    contentData,
    clientSigned,
    clientWithBeneficiariesSigned,
    clientDeclined,
    clientSignDocusign,
    clientWillFinishLater,
    faSignDocusign,
    faSigned,
    faDeclined,
    faWillFinishLater,
    userType,
    isUserUnauthenticated,
  });

  return (
    <CompletionMessage
      content={completionMessageContent}
      contentError={contentError}
      contentLoading={contentLoading}
      contentOptions={contentOptions}
      isUserLoggedIn={!isUserUnauthenticated}
      onPrimaryCtaCallback={onBack}
    />
  );
};
