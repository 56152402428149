import {
  GetAccountProfileContent,
  GetAccountProfileContent_all_account_profile_items_ctas,
  GetAccountProfileContent_all_account_profile_items_disclaimers,
  GetAccountProfileContent_all_account_profile_items_section,
  GetAccountProfileContentVariables,
} from './__generated__/GetAccountProfileContent';
import * as queries from './query.gql';

import { useContentstackQuery } from '~/utils';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';

export type CTAs = GetAccountProfileContent_all_account_profile_items_ctas | null;
export type ContentDataSection = GetAccountProfileContent_all_account_profile_items_section | null;
export type Disclaimers = GetAccountProfileContent_all_account_profile_items_disclaimers | null;

export const useGetAccountProfileContent = (
  options?: QueryHookOptions<GetAccountProfileContent, GetAccountProfileContentVariables>,
): QueryResult<GetAccountProfileContent, GetAccountProfileContentVariables> => {
  return useContentstackQuery(queries.GetAccountProfileContent, options);
};
