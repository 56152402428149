import React, { useEffect, useMemo, useState } from 'react';

import { DocusignWaitingOrCompleted } from '../DocusignWaitingOrCompleted';
import { DocusignWaitingOrCompletedContentInput } from '../DocusignWaitingOrCompleted/utils';

import { LegalDocuments, LegalDocumentSignees, useGetDocusignSignatureStatus } from './symphony';
import { concateUserEmailtoSubHeading, getLegalDocumentsWithSigningDocumentId } from './utils';

import { AssociatedEntityType, LegalDocumentStatus } from '~/__generated__';
import { DocusignUserType } from '~/containers/DocusignDAProCompleted';
import { ContentOptions } from '~/utils';
import { useInterval } from '~/utils/interval';

export interface Props {
  contentData?: DocusignWaitingOrCompletedContentInput;
  contentError?: Error;
  contentLoading: boolean;
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  onBack: () => void;
  onClientDocusignCompleted: () => void;
  onClientDocusignDeclined: () => void;
  partyId: string;
  partyIdFA?: string;
  signingDocumentId?: string;
  updateWorkflowId: string;
  updateWorkflowType: AssociatedEntityType;
  userType: DocusignUserType;
}
const pollFrequency = 20 * 1000; // 20 seconds
export const WaitingForUserSignature: React.FC<Props> = ({
  contentData,
  contentError,
  contentLoading,
  contentOptions,
  managedProductId,
  onBack,
  onClientDocusignCompleted,
  onClientDocusignDeclined,
  partyId,
  partyIdFA,
  signingDocumentId,
  updateWorkflowId,
  updateWorkflowType,
  userType,
}) => {
  const [isStatusPending, setIsStatusPending] = useState(true);
  const [associatedLegalDocuments, setAssociatedLegalDocuments] = useState<LegalDocuments>();
  const [userEmail, setUserEmail] = useState('');

  const {
    data: clientStatusData,
    loading: clientStatusLoading,
    error: clientStatusError,
    refetch,
  } = useGetDocusignSignatureStatus({
    variables: { managedProductId, partyId },
  });

  // long polling
  useInterval(
    async () => {
      await refetch();
      setIsStatusPending(true);
    },
    isStatusPending ? pollFrequency : null,
  );

  const getEmailIdList = (signees: LegalDocumentSignees | undefined): string => {
    let emailIdList = '';
    if (signees) {
      signees.forEach((signee, index) => {
        if (signees.length === 1) {
          emailIdList = `${signee.email}`;
        } else if (index < signees.length - 1) {
          emailIdList = `${emailIdList} ${signee.email} and`;
        } else {
          emailIdList = `${emailIdList} ${signee.email}`;
        }
      });
    }
    return emailIdList;
  };

  /**
   * Currently checking only for client's signatures and not FA
   * There is no such requiremnt to wait for FA signature
   * But this component is created as WaitingForUserSignature to use it in future
   * for waiting for FA's signature also
   */
  useEffect(() => {
    if (updateWorkflowId && !clientStatusLoading) {
      let legalDocument;
      const legalDocuments: LegalDocuments[] | null | undefined = clientStatusData?.managedProduct?.legalDocuments;
      if (updateWorkflowType === AssociatedEntityType.ENTITY_UPDATE_WORKFLOW) {
        legalDocument = signingDocumentId
          ? getLegalDocumentsWithSigningDocumentId(signingDocumentId, legalDocuments)
          : legalDocuments?.find(item => item.associatedEntities?.entityUpdateWorkflowIds.includes(updateWorkflowId));
      } else if (updateWorkflowType === AssociatedEntityType.BANK_ACCOUNT_ASSOCIATION) {
        legalDocument = signingDocumentId
          ? getLegalDocumentsWithSigningDocumentId(signingDocumentId, legalDocuments)
          : legalDocuments?.find(item => item.associatedEntities?.bankAccountAssociationIds.includes(updateWorkflowId));
      } else if (updateWorkflowType === AssociatedEntityType.ASSET_DEPOSIT) {
        legalDocument = signingDocumentId
          ? getLegalDocumentsWithSigningDocumentId(signingDocumentId, legalDocuments)
          : legalDocuments?.find(item => item.associatedEntities?.assetDepositIds.includes(updateWorkflowId));
      } else {
        legalDocument = signingDocumentId
          ? getLegalDocumentsWithSigningDocumentId(signingDocumentId, legalDocuments)
          : legalDocuments?.find(item => item.associatedEntities?.planUpdateWorkflowIds.includes(updateWorkflowId));
      }
      setAssociatedLegalDocuments(legalDocument);
    }
  }, [
    clientStatusData?.managedProduct?.legalDocuments,
    clientStatusLoading,
    updateWorkflowType,
    updateWorkflowId,
    signingDocumentId,
  ]);

  useEffect(() => {
    if (updateWorkflowId && !clientStatusLoading && associatedLegalDocuments) {
      const clientList = associatedLegalDocuments.signees.filter(item => item.partyId !== partyIdFA);
      setUserEmail(getEmailIdList(clientList));
      if (clientList.every(client => client.status === LegalDocumentStatus.SUCCEEDED)) {
        setIsStatusPending(false);
        onClientDocusignCompleted();
      } else if (clientList.some(client => client.status === LegalDocumentStatus.DECLINED)) {
        setIsStatusPending(false);
        onClientDocusignDeclined();
      } else {
        setIsStatusPending(true);
      }
    }
  }, [
    associatedLegalDocuments,
    clientStatusLoading,
    onClientDocusignCompleted,
    onClientDocusignDeclined,
    partyIdFA,
    updateWorkflowId,
  ]);

  useEffect(() => {
    if (clientStatusError) {
      console.error(clientStatusError);
    }
  }, [clientStatusError]);

  const contentDataWithEmail = useMemo(() => {
    if (userEmail) {
      return concateUserEmailtoSubHeading({ contentData, userEmail, userType });
    }
    return contentData;
  }, [contentData, userEmail, userType]);

  return (
    <DocusignWaitingOrCompleted
      contentData={contentDataWithEmail}
      contentError={contentError}
      contentLoading={contentLoading}
      contentOptions={contentOptions}
      onBack={onBack}
      userType={userType}
    />
  );
};
