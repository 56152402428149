import {
  CreateSigningDocument,
  CreateSigningDocument_createSigningDocument,
  CreateSigningDocumentVariables,
} from './__generated__/CreateSigningDocument';
import {
  ResendEnvelopeToNextRecipient,
  ResendEnvelopeToNextRecipientVariables,
} from './__generated__/ResendEnvelopeToNextRecipient';
import { UpdateSigningDocument, UpdateSigningDocumentVariables } from './__generated__/UpdateSigningDocument';
import * as mutations from './mutation.gql';

import { useSymphonyMutation } from '~/utils';
import { MutationHookOptions, MutationTuple } from '~/utils/apollo-client';

export type SigningDocument = CreateSigningDocument_createSigningDocument;

export const useCreateSigningDocument = (
  options?: MutationHookOptions<CreateSigningDocument, CreateSigningDocumentVariables>,
): MutationTuple<CreateSigningDocument, CreateSigningDocumentVariables> => {
  return useSymphonyMutation(mutations.CreateSigningDocument, options);
};

export const useResendEnvelopeToNextRecipient = (
  options?: MutationHookOptions<ResendEnvelopeToNextRecipient, ResendEnvelopeToNextRecipientVariables>,
): MutationTuple<ResendEnvelopeToNextRecipient, ResendEnvelopeToNextRecipientVariables> => {
  return useSymphonyMutation(mutations.ResendEnvelopeToNextRecipient, options);
};

export const useUpdateSigningDocument = (
  options?: MutationHookOptions<UpdateSigningDocument, UpdateSigningDocumentVariables>,
) => {
  return useSymphonyMutation(mutations.UpdateSigningDocument, options);
};
