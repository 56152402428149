import {
  CreateSecurityRestriction,
  CreateSecurityRestrictionVariables,
} from './__generated__/CreateSecurityRestriction';
import {
  DeleteSecurityRestriction,
  DeleteSecurityRestrictionVariables,
} from './__generated__/DeleteSecurityRestriction';
import {
  GetInvestmentRestrictions,
  GetInvestmentRestrictions_managedProduct_restrictions,
  GetInvestmentRestrictionsVariables,
} from './__generated__/GetInvestmentRestrictions';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type Restriction = GetInvestmentRestrictions_managedProduct_restrictions;

export const useGetInvestmentRestrictions = (
  options?: QueryHookOptions<GetInvestmentRestrictions, GetInvestmentRestrictionsVariables>,
): QueryResult<GetInvestmentRestrictions, GetInvestmentRestrictionsVariables> => {
  return useSymphonyQuery(queries.GetInvestmentRestrictions, options);
};

export const useCreateSecurityRestriction = (
  options?: MutationHookOptions<CreateSecurityRestriction, CreateSecurityRestrictionVariables>,
): MutationTuple<CreateSecurityRestriction, CreateSecurityRestrictionVariables> => {
  return useSymphonyMutation(mutations.CreateSecurityRestriction, options);
};

export const useDeleteSecurityRestriction = (
  options?: MutationHookOptions<DeleteSecurityRestriction, DeleteSecurityRestrictionVariables>,
): MutationTuple<DeleteSecurityRestriction, DeleteSecurityRestrictionVariables> => {
  return useSymphonyMutation(mutations.DeleteSecurityRestriction, options);
};
