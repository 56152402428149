import { GetDocusignDocument, GetDocusignDocumentVariables } from './__generated__/GetDocusignDocument';
import * as queries from './query.gql';

import { useSymphonyQuery } from '~/utils';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';

export const useGetDocusignDocument = (
  options?: QueryHookOptions<GetDocusignDocument, GetDocusignDocumentVariables>,
): QueryResult<GetDocusignDocument, GetDocusignDocumentVariables> => {
  return useSymphonyQuery(queries.GetDocusignDocument, options);
};
