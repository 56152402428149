import { useMemo } from 'react';

import { AccountProfileContentType } from '../contentstack';
import { useGetAccountProfileUpdateCompletedContent } from '../contentstack';
import { GetAccountProfileUpdateCompletedContent } from '../contentstack/__generated__/GetAccountProfileUpdateCompletedContent';

import { CompletionMessageContent } from '~/components/CompletionMessage';
import { DocusignWaitingOrCompletedContentInput } from '~/components/Docusign/DocusignWaitingOrCompleted/utils';
import { DocusignTriggerSource } from '~/components/Docusign/utils';
import { AsyncResult, ContentOptions } from '~/utils';

interface Variables {
  contentOptions: ContentOptions;
  source?: DocusignTriggerSource;
}

export const getContentData = (
  content?: AccountProfileContentType | null,
  source?: DocusignTriggerSource,
): CompletionMessageContent => {
  return {
    heading: content?.heading ?? '',
    subHeading: { data: content?.sub_heading ?? '' },
    moreInfo: content?.more_info ?? '',
    imageUrl: content?.imageConnection?.edges?.[0]?.node?.url ?? '',
    primaryCTA:
      (source === DocusignTriggerSource.ACCOUNT_DETAILS
        ? content?.ctas?.primary_account_details
        : content?.ctas?.primary) ?? '',
  };
};

export const findAction = (
  contentData: GetAccountProfileUpdateCompletedContent,
  actionType: string,
): AccountProfileContentType | null => {
  const contentItems = contentData.all_account_profile_update_completed?.items?.[0]?.update_action;
  return contentItems?.find(action => action?.action_type_text === actionType) ?? null;
};

export const useGetEntityUpdateWorkflowContentData = ({
  contentOptions,
  source,
}: Variables): AsyncResult<DocusignWaitingOrCompletedContentInput> => {
  const {
    data: contentData,
    loading: contentLoading,
    error: contentError,
  } = useGetAccountProfileUpdateCompletedContent({
    variables: contentOptions,
  });
  const loadingState = contentLoading;
  const errorState = contentError;
  const data: DocusignWaitingOrCompletedContentInput = useMemo(() => {
    if (contentData) {
      return {
        waitingForClientSignature: getContentData(findAction(contentData, 'client_pending'), source),
        clientSignedFaView: getContentData(findAction(contentData, 'client_completed'), source),
        clientDeclinedFaView: getContentData(findAction(contentData, 'client_declined'), source),
        clientDeclinedUnauthenticatedView: getContentData(
          findAction(contentData, 'client_declined_client_view'),
          source,
        ),
        clientSignedUnauthenticatedView: getContentData(findAction(contentData, 'client_completed'), source),
        clientWithBeneficiariesSignedUnauthenticatedView: getContentData(
          findAction(contentData, 'client_complete_with_updated_beneficiaries'),
          source,
        ),
        clientSignedClientView: getContentData(findAction(contentData, 'client_completed'), source),
        clientDeclinedClientView: getContentData(findAction(contentData, 'client_declined_client_view'), source),
        clientSignDocusign: getContentData(findAction(contentData, 'client_docusign'), source),
        clientWillFinishLaterClientView: getContentData(
          findAction(contentData, 'client_will_finish_later_client_view'),
          source,
        ),
        clientWillFinishLaterUnauthenticated: getContentData(
          findAction(contentData, 'client_will_finish_later_unauthenticated_view'),
          source,
        ),
        faWillFinishLater: getContentData(findAction(contentData, 'fa_will_finish_later'), source),
      };
    }
    return {};
  }, [contentData, source]);

  return { data, loading: loadingState, error: errorState };
};
