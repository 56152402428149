import React, { FC, ReactNode } from 'react';

import { Alert, Box, Button, Grid, RteContent, Skeleton, Typography, useTheme } from '~/components/ui';
import { SfTheme, useIsMediumScreen } from '~/utils';
import { ContentOptions } from '~/utils/contentstack/src/types';

export interface CompletionMessageContent {
  heading?: string;
  helperTexts?: {
    heading?: string;
    subHeading?: string;
  };
  imageUrl?: string;
  moreInfo?: string;
  primaryCTA?: string;
  secondaryCTA?: string;
  subHeading?: { config?: Record<string, ReactNode>; data: string };
  subHeadingText?: string;
}

export interface Props {
  content: CompletionMessageContent;
  contentError: any;
  contentLoading: boolean;
  contentOptions: ContentOptions;
  dataQa?: string;
  isUserLoggedIn?: boolean;
  onPrimaryCtaCallback?: () => void;
  onSecondaryCtaCallback?: () => void;
}

export const CompletionMessage: FC<Props> = ({
  dataQa = 'on-success',
  contentOptions,
  onPrimaryCtaCallback,
  onSecondaryCtaCallback,
  isUserLoggedIn = false,
  content,
  contentError,
  contentLoading,
}) => {
  const {
    sfOnboardingCompleted: { styles: style, typographyVariants },
  } = useTheme<SfTheme>();

  const isMediumScreen = useIsMediumScreen();

  return (
    <Grid
      alignItems="center"
      container
      data-qa={dataQa}
      direction="column"
      height={isMediumScreen ? '100vh' : 'initial'}
      p={2}
      spacing={4}
    >
      {contentLoading ? (
        <Grid item>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Grid>
      ) : contentError ? (
        <Grid item>
          <Alert contentOptions={contentOptions} error={contentError} severity="error" />
        </Grid>
      ) : (
        <>
          <Grid item textAlign="center" xs>
            {content?.imageUrl && (
              <Box data-qa={`${dataQa}-image`} sx={{ height: '100px', pb: 2 }}>
                <img alt="" src={content.imageUrl} />
              </Box>
            )}
            {content?.heading && (
              <Typography
                data-qa={`${dataQa}-heading`}
                sx={{ color: `${style.headingColor}`, pb: 2 }}
                variant={typographyVariants?.heading}
              >
                {content.heading}
              </Typography>
            )}
            {content?.subHeading && (
              <RteContent config={content.subHeading.config} data={content.subHeading.data ?? ''} />
            )}
            {content?.subHeadingText && (
              <Typography data-qa={`${dataQa}-sub-heading`} sx={{ pb: 2 }} variant={typographyVariants?.subHeading}>
                {content.subHeadingText}
              </Typography>
            )}
            {content?.moreInfo && (
              <Typography
                data-qa={`${dataQa}-more-info`}
                sx={{ maxWidth: '1000px' }}
                variant={typographyVariants?.subtitle2}
              >
                {content.moreInfo}
              </Typography>
            )}
          </Grid>
          {isUserLoggedIn && (
            <Grid
              container
              direction={isMediumScreen ? 'column-reverse' : 'row'}
              flexWrap="nowrap"
              item
              justifyContent="center"
              spacing={2}
            >
              {content?.secondaryCTA && (
                <Grid item>
                  <Button
                    data-qa={`${dataQa}-cta-secondary`}
                    fullWidth={isMediumScreen}
                    onClick={onSecondaryCtaCallback}
                    variant="outlined"
                  >
                    {content.secondaryCTA}
                  </Button>
                </Grid>
              )}
              {content?.primaryCTA && (
                <Grid item>
                  <Button
                    data-qa={`${dataQa}-cta-primary`}
                    fullWidth={isMediumScreen}
                    onClick={onPrimaryCtaCallback}
                    variant="contained"
                  >
                    {content.primaryCTA}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
