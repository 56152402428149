import { GetAccessibilityContent, GetAccessibilityContentVariables } from './__generated__/GetAccessibilityContent';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack/src/hooks';

export const useGetAccessibilityContent = (
  options?: QueryHookOptions<GetAccessibilityContent, GetAccessibilityContentVariables>,
): QueryResult<GetAccessibilityContent, GetAccessibilityContentVariables> => {
  return useContentstackQuery(queries.GetAccessibilityContent, options);
};

export { getAccessibilityContentMock } from './mock';
