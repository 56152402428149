import { range } from 'fp-ts/Array';

import { getPaperworkBooleanFieldValue, isMasked } from './helper';

import {
  IdentifierType,
  PartyIdentifierInput,
  RelationshipInput,
  RelationshipName,
  RelationshipSubtype,
} from '~/__generated__';
import { isoDateFormat, isValidDate, randomIntegerWithRange } from '~/utils';

const calculateNumberOfBeneficiariesFromKeys = (beneficiaryKeys: string[]): number =>
  new Set(beneficiaryKeys.map(key => key.split('-').pop())).size;

const getBeneficiariesFromBeneficiaryKeys = (
  beneficiaryKeys: string[],
  result: Record<string, any>,
  benIds?: string[],
) => {
  const numOfBeneficiaries = benIds?.length ?? calculateNumberOfBeneficiariesFromKeys(beneficiaryKeys);
  const beneficiaries: RelationshipInput[] = [];
  if (numOfBeneficiaries > 0) {
    range(0, numOfBeneficiaries - 1).forEach(i => {
      const index = benIds && benIds[i] ? benIds[i] : i.toString();
      const indexedBeneficiaryKeys = beneficiaryKeys.filter(k => k.endsWith(index.toString()));
      if (indexedBeneficiaryKeys.length > 0) {
        const relationshipName = indexedBeneficiaryKeys[0].split('-')[0];
        const beneficiaryName = result[`${relationshipName}-name-${index}`];
        const beneficiaryFirstName = result[`${relationshipName}-first_name-${index}`];
        const beneficiaryLastName = result[`${relationshipName}-last_name-${index}`];
        const beneficiaryPercentage = result[`${relationshipName}-percentage-${index}`];
        const beneficiaryCitizenship = result[`${relationshipName}-citizenship-${index}`];
        const beneficiaryTaxReportingType = result[`${relationshipName}-tax-reporting-number-type-${index}`];
        const beneficiaryTaxReportingValue = result[`${relationshipName}-tax-reporting-number-${index}`];
        const beneficiaryType = result[`${relationshipName}-type-${index}`];
        const beneficiaryCountry = result[`${relationshipName}-organization-country-${index}`];
        const beneficiaryNameOfTrustee = result[`${relationshipName}-name-of-trustees-${index}`];
        const beneficiarySSNvalue = result[`${relationshipName}-ssn-${index}`];
        const beneficiaryIsPerStipes = getPaperworkBooleanFieldValue(
          result,
          `${relationshipName}-per-stirpes-${index}`,
        );
        const birthDate = isValidDate(result[`${relationshipName}-dob-${index}`])
          ? isoDateFormat(result[`${relationshipName}-dob-${index}`])
          : null;
        const isTrustOrCharity = (selectedType: RelationshipSubtype) =>
          selectedType === RelationshipSubtype.TRUST || selectedType === RelationshipSubtype.CHARITY;

        const getPartyIdentifier = (): PartyIdentifierInput[] => {
          return [IdentifierType.SSN, IdentifierType.TIN].map(identifier => {
            const isSelectedTaxReportingType =
              isTrustOrCharity(beneficiaryType) && beneficiaryTaxReportingType
                ? beneficiaryTaxReportingType === identifier
                : identifier === IdentifierType.SSN;
            const selectedTaxReportingTypeValue =
              identifier === IdentifierType.SSN ? beneficiarySSNvalue : beneficiaryTaxReportingValue;
            return {
              type: identifier,
              identifierValue:
                isSelectedTaxReportingType &&
                !isMasked(selectedTaxReportingTypeValue?.toString() ?? undefined) &&
                selectedTaxReportingTypeValue?.length
                  ? selectedTaxReportingTypeValue
                  : null,
              setValueToNull:
                isSelectedTaxReportingType &&
                beneficiaryType !== RelationshipSubtype.CHARITY &&
                !selectedTaxReportingTypeValue,
            };
          });
        };

        if ((beneficiaryName || beneficiaryFirstName) && beneficiaryPercentage) {
          const beneficiary: RelationshipInput = {
            id:
              !index.includes('temp-beneficiary-id-') && result[`${relationshipName}-ulid-${index}`]
                ? result[`${relationshipName}-ulid-${index}`]
                : null,
            name: relationshipName
              ? (relationshipName.toUpperCase() as RelationshipName)
              : RelationshipName.UNKNOWN_RELATIONSHIP_NAME,
            isPerStirpes: beneficiaryIsPerStipes,
            nameOfTrustees: beneficiaryNameOfTrustee,
            party: {
              partyPerson: {
                givenName: beneficiaryFirstName ?? beneficiaryName,
                familyName: beneficiaryLastName,
                birthDate,
                citizenship: beneficiaryCitizenship
                  ? beneficiaryCitizenship
                  : beneficiaryCountry
                  ? beneficiaryCountry
                  : null,
              },
              ...(isTrustOrCharity(beneficiaryType) && {
                partyBusinessEntity: {
                  ...(beneficiaryType === RelationshipSubtype.TRUST && {
                    attributes: {
                      trustDate: birthDate,
                    },
                  }),
                  country: beneficiaryCitizenship,
                  name: beneficiaryName,
                },
              }),
              identifiers: getPartyIdentifier(),
            },
            percentage: beneficiaryPercentage,
            relationshipType: Object.values(RelationshipSubtype).includes(beneficiaryType)
              ? (beneficiaryType as RelationshipSubtype)
              : RelationshipSubtype.OTHER,
          };
          // Remove the keys if null or undefined so that it doesn't get passed to symphony
          if (!result[`${relationshipName}-dob-${index}`] && beneficiary.party.partyPerson?.birthDate) {
            delete beneficiary.party.partyPerson.birthDate;
          }

          // Don't send partyPerson details if Trust / Charity type
          if (isTrustOrCharity(beneficiaryType)) {
            delete beneficiary.party.partyPerson;
          }

          beneficiaries.push(beneficiary);
        }
      }
    });
  }

  return beneficiaries;
};

export const getBeneficiaries = (
  result: Record<string, any>,
  hiddenDataPoints: { [key: string]: boolean },
  beneficiaryIds?: string[],
  contingentBeneficiaryIds?: string[],
): RelationshipInput[] => {
  const hidePrimaryBeneficiaries = hiddenDataPoints['custom:primary_beneficiaries:beneficiaries'] === true;
  const hideContingentBeneficiaries = hiddenDataPoints['custom:contingent_beneficiaries:beneficiaries'] === true;

  const beneficiaryKeys = Object.keys(result).filter(k => k.startsWith('beneficiary'));

  const primaryBeneficiaryKeys = beneficiaryKeys.filter(k => k.startsWith('beneficiary_primary'));
  const primaryBeneficiaries = hidePrimaryBeneficiaries
    ? []
    : getBeneficiariesFromBeneficiaryKeys(primaryBeneficiaryKeys, result, beneficiaryIds);

  const contingentBeneficiaryKeys = beneficiaryKeys.filter(
    k => k.startsWith('beneficiary') && !k.startsWith('beneficiary_primary'),
  );
  const contingentBeneficiaries = hideContingentBeneficiaries
    ? []
    : getBeneficiariesFromBeneficiaryKeys(contingentBeneficiaryKeys, result, contingentBeneficiaryIds);

  return primaryBeneficiaries.concat(contingentBeneficiaries);
};

export const getTempBeneficiaryId = () => {
  return `temp-beneficiary-id-${randomIntegerWithRange(100, 2000, 2).toString()}`;
};
