import {
  GetInvestmentRestrictionsContent,
  GetInvestmentRestrictionsContentVariables,
} from './__generated__/GetInvestmentRestrictionsContent';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetInvestmentRestrictionsContent = (
  options?: QueryHookOptions<GetInvestmentRestrictionsContent, GetInvestmentRestrictionsContentVariables>,
): QueryResult<GetInvestmentRestrictionsContent, GetInvestmentRestrictionsContentVariables> => {
  return useContentstackQuery(queries.GetInvestmentRestrictionsContent, options);
};
