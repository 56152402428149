import ErrorIcon from '@mui/icons-material/Error';
import { Alert, AlertProps } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import React, { ReactElement } from 'react';

import { Typography } from '~/components';

export interface Props extends Omit<AlertProps, 'error' | 'variant'> {
  dataQa?: string;
  error?: boolean;
  id?: string;
  variant?: AlertProps['variant'] | 'string'; // remove after mui upgrade that comes with 'string' variant
}

/**
 * For ADA, Screen readers to read this visually hidden text.
 * TODO: ANR-6427 - CMS update ticket for ADA.
 */
const ErrorTag = (): ReactElement => (
  <Typography component="span" style={visuallyHidden}>
    Error
  </Typography>
);

/**
 * Primarily used as in-line text or error message for a form field.
 */
export const HelperTextContent: React.FC<Props> = ({
  children,
  dataQa = 'helper-text-content',
  error,
  id,
  variant,
  ...rest
}) => {
  if (!children) {
    return null;
  }

  if (error && variant !== 'string') {
    return (
      <Alert aria-live="polite" data-qa={dataQa} id={id} severity="error" sx={{ mt: 2 }} {...rest}>
        <ErrorTag /> {children}
      </Alert>
    );
  }

  return (
    <Alert
      aria-live="polite"
      data-qa={dataQa}
      icon={error ? <ErrorIcon fontSize="small" /> : false}
      id={id}
      sx={{
        background: 'none',
        p: 0,
        '&, .MuiAlert-icon': {
          color: error ? 'error.main' : 'inherit',
          opacity: 1,
          mr: 0.5,
        },
      }}
      {...rest}
    >
      {error && <ErrorTag />} {children}
    </Alert>
  );
};
