import { PartyPerson, PartyPersonLanguage } from './symphony';

import { EmploymentStatus, MaritalStatus, TaxStatus } from '~/__generated__';

interface TemporaryPartyPerson {
  birthDate?: SymphonyDate | null;
  citizenship?: string | null;
  employmentStatus?: EmploymentStatus | null;
  familyName?: string | null;
  givenName?: string | null;
  language?: PartyPersonLanguage | null;
  maritalStatus?: MaritalStatus | null;
  middleName?: string | null;
  occupation?: string | null;
  residentType?: number | null;
  taxStatus?: TaxStatus | null;
}

export const getTemporaryPaperworkPartyPersonObject = (partyPerson: TemporaryPartyPerson): PartyPerson => {
  const {
    residentType,
    birthDate,
    citizenship,
    employmentStatus,
    middleName,
    language,
    occupation,
    maritalStatus,
    taxStatus,
    familyName,
    givenName,
  } = partyPerson;
  return {
    __typename: 'PartyPerson',
    residentType: residentType ?? null,
    birthDate: birthDate ?? null,
    citizenship: citizenship ?? null,
    employmentStatus: employmentStatus ?? null,
    middleName: middleName ?? null,
    language: language ?? null,
    occupation: occupation ?? null,
    maritalStatus: maritalStatus ?? null,
    taxStatus: taxStatus ?? null,
    familyName: familyName ?? null,
    givenName: givenName ?? null,
  };
};
