import React from 'react';

import { Box, Button, Modal, Typography } from '~/components/ui';

export interface Props {
  content: {
    ctas: {
      primary: string;
      secondary: string;
    };
    header: string;
    subHeader: string;
    title: string;
  };
  dataQa?: string;
  disableSaving?: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onSave: () => void;
  open: boolean;
}

export const BackToAccountDetails: React.FC<Props> = ({
  content,
  dataQa = 'back-to-details-modal',
  disableSaving,
  open,
  onClose,
  onSave,
  onDiscard,
}) => {
  return (
    <Modal
      actions={
        <>
          <Button data-qa={`${dataQa}-discard-cta`} onClick={onDiscard} variant="outlined">
            {content.ctas.secondary}
          </Button>
          <Button data-qa={`${dataQa}-save-cta`} disabled={disableSaving} onClick={onSave} variant="contained">
            {content.ctas.primary}
          </Button>
        </>
      }
      content={
        <Box sx={{ mt: 2, mb: 6 }}>
          <Typography component="h3" data-qa={`${dataQa}-body-header`} sx={{ mb: 3 }} variant="h4">
            {content.header}
          </Typography>
          <Typography data-qa={`${dataQa}-body-sub-header`} variant="body2">
            {content.subHeader}
          </Typography>
        </Box>
      }
      data-qa={dataQa}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      title={content.title}
    />
  );
};
