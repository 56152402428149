import React, { FC, useEffect, useState } from 'react';

import { DocusignTriggerSource, DocusignWaitingOrCompleted, SignDocusign, WaitingForUserSignature } from '../Docusign';

import { AccountProfileContext, AccountProfileContextVariables, defaultAccountProfileContextValues } from './context';
import { useGetEntityUpdateWorkflowContentData } from './hooks/useGetEntityUpdateWorkflowContentData';
import { useGetEntityUpdateWorkflowData } from './hooks/useGetEntityUpdateWorkflowData';

import { AssociatedEntityType } from '~/__generated__';
import { Skeleton } from '~/components/ui';
import { Alert } from '~/components/ui/Alert';
import { AccountProfile, Props as AccountProfileProps } from '~/containers/AccountProfile';
import { DocusignUserType } from '~/containers/DocusignDAProCompleted';

export interface Props {
  accountProfileProps: Omit<AccountProfileProps, 'onNext'>;
  accountProfileUpdateStatesOrder?: AccountProfileUpdateState[];
  entityId?: string;
  onDocusignIframeReady?: () => void;
  onLastStage?: (feedbackMessage?: string) => void;
  source?: DocusignTriggerSource;
  updateDisabledState?: (isPageEditingDisabled: boolean) => void;
  updateStep?: (step: AccountProfileUpdateState) => void;
}

export enum AccountProfileUpdateState {
  CLIENT_COMPLETED_CLIENT_VIEW = 'CLIENT_COMPLETED_CLIENT_VIEW',
  CLIENT_COMPLETED_FA_VIEW = 'CLIENT_COMPLETED_FA_VIEW',
  CLIENT_COMPLETED_UNAUTHENTICATED_VIEW = 'CLIENT_COMPLETED_UNAUTHENTICATED_VIEW',
  CLIENT_DECLINED_CLIENT_VIEW = 'CLIENT_DECLINED_CLIENT_VIEW',
  CLIENT_DECLINED_FA_VIEW = 'CLIENT_DECLINED_FA_VIEW',
  CLIENT_DECLINED_UNAUTHENTICATED_VIEW = 'CLIENT_DECLINED_UNAUTHENTICATED_VIEW',
  CLIENT_DOCUSIGN = 'CLIENT_DOCUSIGN',
  CLIENT_WITH_BENEFICIARIES_COMPLETED_UNAUTHENTICATED_VIEW = 'CLIENT_WITH_BENEFICIARIES_COMPLETED_UNAUTHENTICATED_VIEW',
  EDIT_ACCOUNT_PROFILE = 'EDIT_ACCOUNT_PROFILE',
  FINISH_LATER = 'FINISH_LATER',
  FINISH_LATER_UNAUTHENTICATED_VIEW = 'FINISH_LATER_UNAUTHENTICATED_VIEW',
  WAITING_FOR_CLIENT_SIGNATURE = 'WAITING_FOR_CLIENT_SIGNATURE',
}

export const AccountProfileUpdateWrapper: FC<Props> = ({
  accountProfileProps,
  accountProfileUpdateStatesOrder = [
    AccountProfileUpdateState.EDIT_ACCOUNT_PROFILE,
    AccountProfileUpdateState.WAITING_FOR_CLIENT_SIGNATURE,
    AccountProfileUpdateState.CLIENT_COMPLETED_FA_VIEW,
    AccountProfileUpdateState.CLIENT_DECLINED_FA_VIEW,
  ],
  entityId = '',
  onDocusignIframeReady,
  onLastStage,
  source = DocusignTriggerSource.ACCOUNT_SUMMARY,
  updateDisabledState,
  updateStep,
}) => {
  const [currentStep, setCurrentStep] = useState<AccountProfileUpdateState>(accountProfileUpdateStatesOrder[0]);
  const [contextValues, setContextValues] = useState<AccountProfileContextVariables>(
    defaultAccountProfileContextValues,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [entityUpdateWorkflowID, setEntityUpdateWorkflowID] = useState<string>(entityId);

  const { contentOptions, onBack, docusignParams, managedProductId, partyId } = accountProfileProps;
  const {
    data: entityUpdateWorkflowData,
    loading: entityUpdateWorkflowDataLoading,
    error: entityUpdateWorkflowDataError,
    refetch: refetchEntityUpdateWorkflowData,
  } = useGetEntityUpdateWorkflowData({
    managedProductId,
    partyId: accountProfileProps.partyId,
    currentStep,
  });

  const {
    data: DocusignContentData,
    loading: DocusignContentLoading,
    error: DocusignContentError,
  } = useGetEntityUpdateWorkflowContentData({ contentOptions, source });

  useEffect(() => {
    setLoading(false);
    setContextValues({
      disablePageEditing:
        (entityUpdateWorkflowData?.isSigningRequired || entityUpdateWorkflowData?.isSignedAndNotCompleted) ?? false,
      entityUpdateWorkflowID: entityUpdateWorkflowData?.entityUpdateWorkflowId ?? '',
      entityUpdateWorkflowPaperwork: entityUpdateWorkflowData?.paperworkList ?? null,
      isSubmitAgain: entityUpdateWorkflowData?.isSignedAndNotCompleted ?? false,
      isUpdateChangesPending: entityUpdateWorkflowData?.isSigningRequired ?? false,
      partyIdForSigningDocuments: entityUpdateWorkflowData?.partyIdForSigningDocuments ?? partyId,
    });
    setEntityUpdateWorkflowID(entityId);
  }, [entityUpdateWorkflowData, entityId, partyId]);

  useEffect(() => {
    updateDisabledState?.(contextValues.disablePageEditing);
  }, [contextValues.disablePageEditing, updateDisabledState]);

  const handleNext = ({
    isSigningRequired,
    entityUpdateWorkflowId,
    clientDeclined,
  }: {
    clientDeclined?: boolean;
    entityUpdateWorkflowId?: string;
    isSigningRequired?: boolean;
  }) => {
    if (entityUpdateWorkflowId) {
      setEntityUpdateWorkflowID(entityUpdateWorkflowId);
    }
    let newIndex = 0;
    if (currentStep === AccountProfileUpdateState.EDIT_ACCOUNT_PROFILE && !isSigningRequired) {
      newIndex = accountProfileUpdateStatesOrder.length;
    } else if (currentStep === AccountProfileUpdateState.WAITING_FOR_CLIENT_SIGNATURE && clientDeclined) {
      newIndex = accountProfileUpdateStatesOrder.indexOf(AccountProfileUpdateState.CLIENT_DECLINED_FA_VIEW);
    } else {
      newIndex = accountProfileUpdateStatesOrder.indexOf(currentStep) + 1;
    }
    if (newIndex === accountProfileUpdateStatesOrder.length) {
      onLastStage?.();
    } else {
      const nextStep = accountProfileUpdateStatesOrder[newIndex];
      setCurrentStep(nextStep);
      updateStep?.(nextStep);
    }
  };

  const getCurrentStepPage = () => {
    switch (currentStep) {
      case AccountProfileUpdateState.EDIT_ACCOUNT_PROFILE:
        return (
          !entityUpdateWorkflowDataLoading && (
            <AccountProfileContext.Provider value={contextValues}>
              <AccountProfile
                onDiscardPendingChange={() => {
                  setLoading(true);
                  refetchEntityUpdateWorkflowData();
                }}
                onNext={handleNext}
                {...accountProfileProps}
              />
            </AccountProfileContext.Provider>
          )
        );
      case AccountProfileUpdateState.WAITING_FOR_CLIENT_SIGNATURE:
        return (
          <WaitingForUserSignature
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            managedProductId={managedProductId}
            onBack={onBack}
            onClientDocusignCompleted={() => handleNext({})}
            onClientDocusignDeclined={() => handleNext({ clientDeclined: true })}
            partyId={partyId}
            partyIdFA={docusignParams?.faPartyId}
            updateWorkflowId={entityUpdateWorkflowID ?? ''}
            updateWorkflowType={AssociatedEntityType.ENTITY_UPDATE_WORKFLOW}
            userType={DocusignUserType.FA}
          />
        );
      case AccountProfileUpdateState.CLIENT_COMPLETED_FA_VIEW:
        return (
          <DocusignWaitingOrCompleted
            clientSigned
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            onBack={onBack}
            userType={DocusignUserType.FA}
          />
        );
      case AccountProfileUpdateState.CLIENT_DECLINED_FA_VIEW:
        return (
          <DocusignWaitingOrCompleted
            clientDeclined
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            onBack={onBack}
            userType={DocusignUserType.FA}
          />
        );
      case AccountProfileUpdateState.CLIENT_COMPLETED_CLIENT_VIEW:
        return (
          <DocusignWaitingOrCompleted
            clientSigned
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            onBack={onBack}
            userType={DocusignUserType.Client}
          />
        );
      case AccountProfileUpdateState.CLIENT_DECLINED_CLIENT_VIEW:
        return (
          <DocusignWaitingOrCompleted
            clientDeclined
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            onBack={onBack}
            userType={DocusignUserType.Client}
          />
        );
      case AccountProfileUpdateState.CLIENT_COMPLETED_UNAUTHENTICATED_VIEW:
        return (
          <DocusignWaitingOrCompleted
            clientSigned
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            isUserUnauthenticated
            onBack={onBack}
            userType={DocusignUserType.Client}
          />
        );
      case AccountProfileUpdateState.CLIENT_WITH_BENEFICIARIES_COMPLETED_UNAUTHENTICATED_VIEW:
        return (
          <DocusignWaitingOrCompleted
            clientWithBeneficiariesSigned
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            isUserUnauthenticated
            onBack={onBack}
            userType={DocusignUserType.Client}
          />
        );
      case AccountProfileUpdateState.CLIENT_DECLINED_UNAUTHENTICATED_VIEW:
        return (
          <DocusignWaitingOrCompleted
            clientDeclined
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            isUserUnauthenticated
            onBack={onBack}
            userType={DocusignUserType.Client}
          />
        );
      case AccountProfileUpdateState.FINISH_LATER:
        return (
          <DocusignWaitingOrCompleted
            clientWillFinishLater
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            onBack={onBack}
            userType={DocusignUserType.Client}
          />
        );
      case AccountProfileUpdateState.FINISH_LATER_UNAUTHENTICATED_VIEW:
        return (
          <DocusignWaitingOrCompleted
            clientWillFinishLater
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            isUserUnauthenticated
            onBack={onBack}
            userType={DocusignUserType.Client}
          />
        );
      case AccountProfileUpdateState.CLIENT_DOCUSIGN:
        return (
          <SignDocusign
            baseUrl={docusignParams?.baseUrl ?? ''}
            contentData={DocusignContentData}
            contentError={DocusignContentError}
            contentLoading={DocusignContentLoading}
            contentOptions={contentOptions}
            docusignPartyId={accountProfileProps.partyId}
            managedProductId={managedProductId}
            onBack={onBack}
            onIframeReady={onDocusignIframeReady}
            returnToUrl={docusignParams?.returnToUrl ?? ''}
            updateWorkflowId={entityUpdateWorkflowID ?? ''}
            updateWorkflowType={AssociatedEntityType.ENTITY_UPDATE_WORKFLOW}
            userType={DocusignUserType.Client}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {entityUpdateWorkflowDataLoading || DocusignContentLoading || loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : entityUpdateWorkflowDataError || DocusignContentError ? (
        <Alert
          contentOptions={contentOptions}
          error={entityUpdateWorkflowDataError || DocusignContentError}
          severity="error"
        />
      ) : (
        getCurrentStepPage()
      )}
    </>
  );
};
