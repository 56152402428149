import { GetHarvestLosses, GetHarvestLossesVariables } from './__generated__/GetHarvestLosses';
import { SaveHarvestLosses, SaveHarvestLossesVariables } from './__generated__/SaveHarvestLosses';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import { useSymphonyMutation, useSymphonyQuery } from '~/utils';
import { MutationHookOptions, QueryHookOptions } from '~/utils/apollo-client';

export const useSaveHarvestLosses = (options?: MutationHookOptions<SaveHarvestLosses, SaveHarvestLossesVariables>) => {
  return useSymphonyMutation(mutations.SaveHarvestLosses, options);
};

export const useGetHarvestLosses = (options?: QueryHookOptions<GetHarvestLosses, GetHarvestLossesVariables>) => {
  return useSymphonyQuery(queries.GetHarvestLosses, options);
};
