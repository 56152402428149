import * as React from 'react';

import { Box, Typography } from '../ui';

import { useIsMediumScreen } from '~/utils';

export interface Props {
  dataQa?: string;
  helperHeading?: string | null;
  helperSubHeading?: string | null;
  linkToDocument: string;
}
export const DocusignIframe: React.FC<Props> = ({
  dataQa = 'docusign',
  linkToDocument,
  helperHeading,
  helperSubHeading,
}) => {
  const isMediumScreen = useIsMediumScreen();
  return (
    <>
      <Box data-qa={`${dataQa}-document`} px={isMediumScreen ? 0 : 2}>
        <iframe
          height={700}
          sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-downloads allow-popups allow-popups-to-escape-sandbox"
          src={linkToDocument}
          width="100%"
        />
      </Box>
      <Box p={8}>
        <Typography align="center" component="div" data-qa={`${dataQa}-helper-heading`} sx={{ pb: 2 }} variant="h6">
          {helperHeading}
        </Typography>
        <Typography align="center" component="div" data-qa={`${dataQa}-helper-sub-heading`} variant="body2">
          {helperSubHeading}
        </Typography>
      </Box>
    </>
  );
};
