import { QueryResult } from '@apollo/client';

import { CreateAnnuityTransfer, CreateAnnuityTransferVariables } from './__generated__/CreateAnnuityTransfer';
import { CreateCashTransfer, CreateCashTransferVariables } from './__generated__/CreateCashTransfer';
import {
  GetBankAccountBalances,
  GetBankAccountBalances_client_financialAccounts,
  GetBankAccountBalances_client_financialAccounts_balances,
  GetBankAccountBalances_client_financialAccounts_positions,
  GetBankAccountBalances_client_financialAccounts_positions_identifiers,
  GetBankAccountBalances_client_financialAccounts_positions_quantities,
  GetBankAccountBalances_client_financialAccounts_positions_security,
  GetBankAccountBalancesVariables,
} from './__generated__/GetBankAccountBalances';
import { GetBankAccounts, GetBankAccountsVariables } from './__generated__/GetBankAccounts';
import {
  GetBankAccountsForTransfer_client_financialAccounts_products,
  GetBankAccountsForTransfer_client_financialAccounts_products_ManagedProduct,
} from './__generated__/GetBankAccountsForTransfer';
import {
  GetBrokerageAccountBalances,
  GetBrokerageAccountBalances_client_financialAccounts,
  GetBrokerageAccountBalances_client_financialAccounts_positions,
  GetBrokerageAccountBalancesVariables,
} from './__generated__/GetBrokerageAccountBalances';
import {
  GetExternalBrokerageAccountBalances,
  GetExternalBrokerageAccountBalances_client_financialAccounts,
  GetExternalBrokerageAccountBalances_client_financialAccounts_positionsV2,
  GetExternalBrokerageAccountBalancesVariables,
} from './__generated__/GetExternalBrokerageAccountBalances';
import {
  GetFinancialInstitutionByRoutingNumber,
  GetFinancialInstitutionByRoutingNumberVariables,
} from './__generated__/GetFinancialInstitutionByRoutingNumber';
import {
  SaveFinancialAccountAssociation,
  SaveFinancialAccountAssociationVariables,
} from './__generated__/SaveFinancialAccountAssociation';
import * as mutations from './mutation.gql';
import * as queries from './query.gql';

import {
  CreateAssetTransfer,
  CreateAssetTransferVariables,
} from '~/hooks/financial-account/symphony/__generated__/CreateAssetTransfer';
import { MutationHookOptions, QueryHookOptions, QueryTuple } from '~/utils/apollo-client';
import { useLazySymphonyQuery, useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type FinancialAccount = GetBankAccountBalances_client_financialAccounts;
export type ManagedProduct = GetBankAccountsForTransfer_client_financialAccounts_products_ManagedProduct;
export type BrokerageFinancialAccount = GetBrokerageAccountBalances_client_financialAccounts;
export type ExternalBrokerageFinancialAccount = GetExternalBrokerageAccountBalances_client_financialAccounts;
export type Position =
  | GetBankAccountBalances_client_financialAccounts_positions
  | GetBrokerageAccountBalances_client_financialAccounts_positions;
export type PositionV2 = GetExternalBrokerageAccountBalances_client_financialAccounts_positionsV2;
export type Identifier = GetBankAccountBalances_client_financialAccounts_positions_identifiers;
export type Security = GetBankAccountBalances_client_financialAccounts_positions_security;
export type Quantity = GetBankAccountBalances_client_financialAccounts_positions_quantities;
export type Product = GetBankAccountsForTransfer_client_financialAccounts_products;
export type Balances = GetBankAccountBalances_client_financialAccounts_balances;
export const isManagedProduct = (product: Product): product is ManagedProduct =>
  product.__typename === 'ManagedProduct';

export enum Weekday {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
}

export const useCreateCashTransfer = (
  options?: MutationHookOptions<CreateCashTransfer, CreateCashTransferVariables>,
) => {
  return useSymphonyMutation(mutations.CreateCashTransfer, options);
};

export const useCreateAssetTransfer = (
  options?: MutationHookOptions<CreateAssetTransfer, CreateAssetTransferVariables>,
) => {
  return useSymphonyMutation(mutations.CreateAssetTransfer, options);
};

export const useCreateAnnuityTransfer = (
  options?: MutationHookOptions<CreateAnnuityTransfer, CreateAnnuityTransferVariables>,
) => useSymphonyMutation(mutations.CreateAnnuityTransfer, options);

export const useSaveFinancialAccountAssociation = (
  options?: MutationHookOptions<SaveFinancialAccountAssociation, SaveFinancialAccountAssociationVariables>,
) => useSymphonyMutation(mutations.SaveFinancialAccountAssociation, options);

export const useGetBrokerageAccountBalances = (
  options?: QueryHookOptions<GetBrokerageAccountBalances, GetBrokerageAccountBalancesVariables>,
): QueryResult<GetBrokerageAccountBalances, GetBrokerageAccountBalancesVariables> => {
  return useSymphonyQuery(queries.GetBrokerageAccountBalances, options);
};

export const useLazyGetBankAccountBalances = (
  options?: QueryHookOptions<GetBankAccountBalances, GetBankAccountBalancesVariables>,
): QueryTuple<GetBankAccountBalances, GetBankAccountBalancesVariables> => {
  return useLazySymphonyQuery(queries.GetBankAccountBalances, options);
};

export const useLazyGetBrokerageAccountBalances = (
  options?: QueryHookOptions<GetBrokerageAccountBalances, GetBrokerageAccountBalancesVariables>,
): QueryTuple<GetBrokerageAccountBalances, GetBrokerageAccountBalancesVariables> => {
  return useLazySymphonyQuery(queries.GetBrokerageAccountBalances, options);
};

export const useLazyGetBankAccounts = (
  options?: QueryHookOptions<GetBankAccounts, GetBankAccountsVariables>,
): QueryTuple<GetBankAccounts, GetBankAccountsVariables> => {
  return useLazySymphonyQuery(queries.GetBankAccounts, options);
};

export const useLazyGetExternalBrokerageAccountBalances = (
  options?: QueryHookOptions<GetExternalBrokerageAccountBalances, GetExternalBrokerageAccountBalancesVariables>,
): QueryTuple<GetExternalBrokerageAccountBalances, GetExternalBrokerageAccountBalancesVariables> => {
  return useLazySymphonyQuery(queries.GetExternalBrokerageAccountBalances, options);
};

export const useLazyGetFinancialInstitutionByRoutingNumber = (
  options?: QueryHookOptions<GetFinancialInstitutionByRoutingNumber, GetFinancialInstitutionByRoutingNumberVariables>,
) => {
  return useLazySymphonyQuery(queries.GetFinancialInstitutionByRoutingNumber, options);
};
