import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import omit from 'lodash.omit';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { DocusignParams } from '../Comparison';
import { RelationshipDetails } from '../Paperwork/Beneficiaries';
import { CMSQuestions } from '../Paperwork/contentstack';
import { defaultPaperworkContext, PaperworkContext, PaperworkContextVariables } from '../Paperwork/context';
import { useGetPaperworkData } from '../Paperwork/hooks/useGetPaperworkData';
import { Questions } from '../Paperwork/Section/Questions';
import { BeneficiaryConfig } from '../Paperwork/Section/Questions/types';
import { ManagedProduct, ManagedProductPaperwork, Relationship } from '../Paperwork/symphony';
import { GetPaperwork_managedProduct_paperwork } from '../Paperwork/symphony/__generated__/GetPaperwork';
import { PaperworkSection as Section } from '../Paperwork/types';
import { getBeneficiaries, getTempBeneficiaryId } from '../Paperwork/utils/beneficiary';
import { getCtaText } from '../Paperwork/utils/ctatext';
import { getDisclaimerText } from '../Paperwork/utils/disclaimerText';
import { getPaperworkBooleanFieldValue } from '../Paperwork/utils/helper';
import { getUpdatedInvestment, initialiseInvestmentObject } from '../Paperwork/utils/investment';
import { getUpdatedParty } from '../Paperwork/utils/party';
import { getCustomRegulatoryInformation } from '../Paperwork/utils/regulatory';
import { getTrustedContacts, getUpdatedRelationship } from '../Paperwork/utils/relationships';
import { getUpdatedWealthInformation } from '../Paperwork/utils/wealth';

import { useGetAccountProfileContent } from './contentstack';
import { useEntityUpdateWorkFlow } from './hooks/useEntityUpdateWorkFlow';
import { useCreateEntityUpdateWorkflow, useUpdateEntityUpdateWorkflow } from './symphony';
import { getSectionHeading } from './utils';

import {
  AddressInput,
  AddressType,
  FinancialAccountType,
  IdentifierType,
  PaperworkInput,
  PaperworkType,
  RelationshipName,
  RelationshipSubtype,
  UpdateWorkflowStatus,
} from '~/__generated__';
import { RteContent, useModalState } from '~/components';
import { AccountProfileContext } from '~/components/AccountProfileUpdateWrapper/context';
import { BackToAccountDetails } from '~/components/modals/BackToAccountDetails';
import { DiscardPendingChange } from '~/components/modals/DiscardPendingChange';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  LoadingButton,
  Skeleton,
  Typography,
  useTheme,
} from '~/components/ui';
import { useCreateSigningDocument } from '~/hooks/docusign/symphony';
import { isoDateFormat, isTrustAccountType, isValidDate, useCoreConfig } from '~/utils';
import { ContentOptions } from '~/utils/contentstack/src/types';

export interface AccountProfileSection extends Section {
  isSectionSecondaryPaperwork?: boolean;
  sectionSecondaryPaperworkIndex?: number;
}

export interface Props {
  beneficiaryConfig?: BeneficiaryConfig;
  closeBackToAccountDetailsModal: () => void;
  contentOptions: ContentOptions;
  dataQa?: string;
  docusignParams?: DocusignParams;
  getSections: (
    financialAccountType?: FinancialAccountType | null,
    numberOfSecondaryUsers?: number,
  ) => AccountProfileSection[];
  managedProductId: string;
  onBack: () => void;
  onDiscardPendingChange?: () => void;
  onNext?: ({
    isSigningRequired,
    entityUpdateWorkflowId,
    clientDeclined,
  }: {
    clientDeclined?: boolean;
    entityUpdateWorkflowId?: string;
    isSigningRequired?: boolean;
  }) => void;
  openBackToAccountDetailsModalState: boolean;
  partyId: string;
}

export const AccountProfile: FC<Props> = ({
  beneficiaryConfig = { hideSsn: true },
  closeBackToAccountDetailsModal,
  contentOptions,
  dataQa = 'account-profile',
  docusignParams,
  onBack,
  onDiscardPendingChange,
  onNext,
  managedProductId,
  openBackToAccountDetailsModalState,
  partyId,
  getSections,
}) => {
  const {
    sfAccountProfile: { styles: sfAccountProfileStyles },
  } = useTheme();

  const {
    components: {
      sfAccountProfile: { showSecondaryCTA },
    },
  } = useCoreConfig();

  const [beneficiaryIds, setBeneficiaryIds] = useState<string[]>([]);
  const [contingentBeneficiaryIds, setContingentBeneficiaryIds] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [sectionDependentDataPoints, setSectionDependentDataPoints] = useState<Record<string, boolean>>({});
  const [sectionResultStatus, setSectionResultStatus] = useState<Record<string, boolean>>({});
  const [hiddenDataPoints, setHiddenDataPoints] = useState<Record<string, boolean>>({});
  const [results, setResults] = useState<Record<string, Record<string, any>>>({});
  const [sectionResult, setSectionResult] = useState<{ id: string; result?: Record<string, any> }>({ id: '' });
  const [errorSaving, setErrorSaving] = useState<Error | undefined>();
  const [successOnSave, setSuccessOnSave] = useState(false);
  const [createEntityUpdateWorkflow] = useCreateEntityUpdateWorkflow();
  const [createSigningDocument] = useCreateSigningDocument();
  const [updateEntityUpdateWorkflow] = useUpdateEntityUpdateWorkflow();
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [accordionStates, setAccordionStates] = useState<Record<string, boolean>>({});
  const primaryAddresses = useRef<AddressInput[]>([]);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const {
    disablePageEditing,
    entityUpdateWorkflowID,
    entityUpdateWorkflowPaperwork,
    isSubmitAgain,
    isUpdateChangesPending,
    partyIdForSigningDocuments,
  } = useContext(AccountProfileContext);
  const [isDiscardStagedChanges, setIsDiscardStagedChanges] = useState<boolean>(false);
  const [isOnSubmitAgainChanges, setIsOnSubmitAgainChanges] = useState<boolean>(false);
  const { open: isDiscardModalOpen, openModal: openDiscardModal, onClose: onDiscardModalClose } = useModalState();
  const [contextValues, setContextValues] = useState<PaperworkContextVariables>(defaultPaperworkContext);

  const { data, loading: contentLoading, error: contentError } = useGetAccountProfileContent({
    variables: contentOptions,
  });

  const { data: accountProfileContent, error, loading } = useGetPaperworkData({
    contentOptions,
    managedProductId,
    partyId,
    performSyncWithCustodian: true,
  });

  const contentData = data?.all_account_profile?.items?.[0];
  const maskedAccountNumber =
    accountProfileContent?.paperworkSymphonyData?.client?.financialAccounts?.[0].maskedAccountNumber;
  const managedAccount: ManagedProduct | null | undefined =
    accountProfileContent?.paperworkSymphonyData?.managedProduct;
  const allPaperworkData: ManagedProductPaperwork[] | null | undefined =
    disablePageEditing && entityUpdateWorkflowPaperwork ? entityUpdateWorkflowPaperwork : managedAccount?.paperwork;

  const primaryPaperworkData: GetPaperwork_managedProduct_paperwork | undefined = allPaperworkData
    ? allPaperworkData.find(p => p.paperworkType === PaperworkType.PRIMARY)
    : undefined;
  const secondayPaperworkProfiles: GetPaperwork_managedProduct_paperwork[] | undefined = allPaperworkData
    ? allPaperworkData
        .filter(p => p.paperworkType === PaperworkType.SECONDARY)
        .filter(p => (isTrustAccountType(managedAccount?.accountType) ? !p.trustInformation?.isTrusteeGrantor : true))
    : undefined;

  const isUpdatingBeneficiariesAllowed = primaryPaperworkData?.isUpdatingBeneficiariesAllowed;

  const sections = useMemo(() => {
    return managedAccount ? getSections(managedAccount.accountType, secondayPaperworkProfiles?.length) : [];
  }, [getSections, managedAccount, secondayPaperworkProfiles?.length]);

  primaryPaperworkData?.relationships?.forEach(relationship => {
    if (relationship?.party && !relationship.party.id) {
      relationship.party.id = getTempBeneficiaryId();
    }
  });

  const entityUpdateWorkFlow = useEntityUpdateWorkFlow({
    managedProductId,
    docusignParams,
    partyId: partyIdForSigningDocuments,
    createEntityUpdateWorkflow,
    createSigningDocument,
    setSuccessOnSave,
    onNext,
    onBack,
    setErrorSaving,
    setIsBusy,
    setIsSubmitted,
  });

  /**
   * Setting each section to be false in sectionResultStatus
   * which will be switched to true while submitting each formData from each section
   */
  useEffect(() => {
    if (sections.length) {
      const status: Record<string, boolean> = {};
      sections.forEach(s => {
        if (s.titleKey) {
          status[`${s.titleKey}`] = false;
        }
      });
      setSectionResultStatus(status);
    }
  }, [sections]);

  const validationMessages = [
    ...(accountProfileContent?.content?.validation
      ?.filter(v => v?.__typename === 'PaperworkValidationMessages')
      ?.map(v => ({ key: v?.messages?.key ?? '', label: v?.messages?.label ?? '' })) ?? []),
    ...(accountProfileContent?.content?.validation_rte
      ?.filter(v => v?.__typename === 'PaperworkValidationRte')
      ?.map(v => ({ key: v?.key ?? '', label: v?.label ?? '' })) ?? []),
  ];

  useEffect(() => {
    if (openBackToAccountDetailsModalState && (error || contentError)) {
      closeBackToAccountDetailsModal();
      onBack();
    }
  }, [openBackToAccountDetailsModalState]);

  useEffect(() => {
    setHiddenDataPoints({ ...hiddenDataPoints, ...sectionDependentDataPoints });
  }, [sectionDependentDataPoints]);

  /**
   * Creating a result Object
   * which will have the symphony data id as the key and their respective form results mapped to it.
   */
  useEffect(() => {
    const modifiedResults = {
      ...results,
      [sectionResult.id]: { ...results[sectionResult.id], ...sectionResult.result },
    };
    delete modifiedResults[''];
    setResults(modifiedResults);
  }, [sectionResult]);

  useEffect(() => {
    if (!beneficiaryIds.length || !isUpdatingBeneficiariesAllowed) {
      setSectionResultStatus(currentState => ({
        ...currentState,
        [`contingent_beneficiary`]: true,
        [`primary_beneficiary`]: true,
      }));
    } else if (!contingentBeneficiaryIds.length) {
      setSectionResultStatus(currentState => ({
        ...currentState,
        [`contingent_beneficiary`]: true,
      }));
    }
  }, [beneficiaryIds.length, contingentBeneficiaryIds.length, isSubmitted, isUpdatingBeneficiariesAllowed]);

  useEffect(() => {
    if (!isBusy && isSubmitted && !Object.values(sectionResultStatus).includes(false)) {
      const dataPointKeysInPage = sections.flatMap(section =>
        section.questions?.order.orderSteps
          .filter(
            step =>
              step.dataPointKey.startsWith('data_point') &&
              (!step.accountTypes ||
                (managedAccount?.accountType && step.accountTypes.includes(managedAccount.accountType))),
          )
          .map(step => step.dataPointKey),
      );
      /**
       * Creating a flat object
       * with all the dataPointKeys and their results.
       * And removing the symphonyDataID layer in results to check for allAnswersInPageAvailable
       */
      let dataPointKeysInResults = {};
      Object.keys(results).forEach(id => {
        dataPointKeysInResults = { ...dataPointKeysInResults, ...results[id] };
      });

      const allAnswersInPageAvailable = !dataPointKeysInPage.find(dpk => dpk && !(dpk in dataPointKeysInResults));
      if (allAnswersInPageAvailable) {
        setIsBusy(true);

        const paperworkDataListToSave: PaperworkInput[] = Object.keys(results).map(id => {
          return mapResultToSymphonyData(id, results[id]);
        });

        (async () => {
          await entityUpdateWorkFlow(paperworkDataListToSave);
        })();
      }
    }
  }, [isSubmitted, results]);

  const getNewBeneficiaryData = (
    symphonyMappingKey: string,
    benPartyId: string,
    keyValues: RelationshipDetails['fieldValues'],
    beneficiaryId: string,
    isPrimaryBeneficiary: boolean,
  ): Relationship => {
    const beneficiaryName = `${symphonyMappingKey}-name-${beneficiaryId}`;
    const beneficiaryCitizenship = `${symphonyMappingKey}-citizenship-${beneficiaryId}`;
    const beneficiaryFirstName = `${symphonyMappingKey}-first_name-${beneficiaryId}`;
    const beneficiaryLastName = `${symphonyMappingKey}-last_name-${beneficiaryId}`;
    const beneficiaryType = `${symphonyMappingKey}-type-${beneficiaryId}`;
    const beneficiaryPercentage = `${symphonyMappingKey}-percentage-${beneficiaryId}`;
    const beneficiaryDOB = `${symphonyMappingKey}-dob-${beneficiaryId}`;
    const beneficiarySSN = `${symphonyMappingKey}-ssn-${beneficiaryId}`;
    const isTrustOrCharity =
      keyValues[beneficiaryType] === RelationshipSubtype.CHARITY ||
      keyValues[beneficiaryType] === RelationshipSubtype.TRUST;

    return {
      __typename: 'Relationship',
      id: beneficiaryId,
      name: isPrimaryBeneficiary ? RelationshipName.BENEFICIARY_PRIMARY : RelationshipName.BENEFICIARY,
      isPerStirpes: false,
      nameOfTrustees: null,
      party: {
        __typename: 'Party',
        addresses: [],
        partyBusinessEntity: {
          __typename: 'BusinessEntity',
          name: keyValues[beneficiaryName],
          country: (isTrustOrCharity && keyValues[beneficiaryCitizenship]) ?? null,
          attributes: {
            __typename: 'BusinessEntityAttributes',
            trustDate: null,
            roleType: null,
          },
        },
        partyContacts: [],
        id: benPartyId,
        identifiers: [
          {
            __typename: 'PartyIdentifier',
            identifierValue: keyValues[beneficiarySSN],
            type: IdentifierType.SSN,
          },
        ],
        partyPerson: {
          __typename: 'PartyPerson',
          birthDate: isValidDate(keyValues[beneficiaryDOB]) ? isoDateFormat(keyValues[beneficiaryDOB]) : '',
          givenName: keyValues[beneficiaryFirstName],
          middleName: null,
          familyName: keyValues[beneficiaryLastName],
          deathDate: null,
          residentType: null,
          citizenship: (!isTrustOrCharity && keyValues[beneficiaryCitizenship]) ?? null,
          taxStatus: null,
        },
      },
      percentage: keyValues[beneficiaryPercentage],
      relationshipType: keyValues[beneficiaryType],
    };
  };

  const getNestedValue = (obj: any, keys: string[]): any => {
    return keys.reduce((o, key) => (o && o[key] !== undefined ? o[key] : null), obj);
  };

  const setNestedValue = (obj: any, keys: string[], value: any): void => {
    keys.reduce((o, key, index) => {
      if (index === keys.length - 1) {
        o[key] = value;
      } else {
        if (!o[key]) {
          o[key] = {};
        }
        return o[key];
      }
    }, obj);
  };

  const updateRelationshipsData = (
    paperworkData: Relationship[],
    fieldValues: { [x: string]: any },
    symphonyMappingKey: string,
  ): Relationship[] => {
    paperworkData.forEach(relationship => {
      const id = relationship?.id;
      if (id) {
        const fieldValuePrefix = `${symphonyMappingKey}-ulid-${id}`;
        const ssnIdentifierIndex = relationship?.party?.identifiers?.findIndex(
          (identifier: any) => identifier.type === IdentifierType.SSN,
        );
        if (fieldValues[fieldValuePrefix] === id) {
          const keysToCheck = [
            { fieldKey: 'type', relationshipKey: 'relationshipType' },
            { fieldKey: 'name', relationshipKey: 'party.partyBusinessEntity.name' },
            { fieldKey: 'first_name', relationshipKey: 'party.partyPerson.givenName' },
            { fieldKey: 'last_name', relationshipKey: 'party.partyPerson.familyName' },
            { fieldKey: 'percentage', relationshipKey: 'percentage' },
            { fieldKey: 'dob', relationshipKey: 'party.partyPerson.birthDate' },
            {
              fieldKey: 'ssn',
              relationshipKey:
                ssnIdentifierIndex !== -1 ? `party.identifiers.${ssnIdentifierIndex}.identifierValue` : null,
            },
            { fieldKey: 'per-stirpes', relationshipKey: 'isPerStirpes' },
          ];

          const citizenshipKey =
            fieldValues[`${symphonyMappingKey}-type-${id}`] === 'CHARITY' ||
            fieldValues[`${symphonyMappingKey}-type-${id}`] === 'TRUST'
              ? 'party.partyBusinessEntity.country'
              : 'party.partyPerson.citizenship';
          keysToCheck.push({ fieldKey: 'citizenship', relationshipKey: citizenshipKey });

          keysToCheck.forEach(({ fieldKey, relationshipKey }) => {
            const fieldKeyWithId = `${symphonyMappingKey}-${fieldKey}-${id}`;
            let fieldValue = fieldValues[fieldKeyWithId];

            if (fieldKey === 'dob') {
              fieldValue = isValidDate(fieldValue) ? isoDateFormat(fieldValue) : '';
            }

            if (fieldKey === 'per-stirpes') {
              fieldValue = fieldValue[fieldKeyWithId];
            }
            if (relationshipKey !== null) {
              const relationshipValue = getNestedValue(relationship, relationshipKey.split('.'));
              if (fieldValue !== relationshipValue) {
                setNestedValue(relationship, relationshipKey.split('.'), fieldValue);
              }
            }
          });
        }
      }
    });

    return paperworkData;
  };

  const deleteKeysFromResult = (keys: string[], relationshipDetails?: RelationshipDetails, symphonyDataId?: string) => {
    if (relationshipDetails && primaryPaperworkData) {
      const { ids, isPrimaryBeneficiary, fieldValues, symphonyMappingKey, removeId } = relationshipDetails;
      /**
       * Verify if there are changes in existing relationships data from primaryPaperwork. If changes found, display the updated data
       * else show primaryPaperwork data in newPaperworkRelationships.
       * Except the Beneficiary which is removed from existing beneficiaries
       */
      const newPaperworkRelationships: (Relationship | null)[] =
        primaryPaperworkData.relationships
          ?.filter(datum => datum?.id !== relationshipDetails.removeId && !datum?.id?.includes('temp-beneficiary-id-'))
          ?.flatMap(datum => updateRelationshipsData([datum], fieldValues, symphonyMappingKey)) ?? [];
      /**
       * Creating newBeneficiaryData for newly added Beneficiaries
       * Except for the Beneficiary which is removed from newly added beneficiaries
       */
      ids
        .filter(id => id.includes('temp-beneficiary-id-'))
        .map(id => {
          if (id !== removeId) {
            newPaperworkRelationships.push(
              getNewBeneficiaryData(
                symphonyMappingKey,
                (newPaperworkRelationships.length + 1).toString(),
                fieldValues,
                id,
                isPrimaryBeneficiary,
              ),
            );
          }
        });

      const primaryBeneficiaryCount = newPaperworkRelationships.filter(datum => datum?.name === 'BENEFICIARY_PRIMARY');
      if (!primaryBeneficiaryCount.length && isPrimaryBeneficiary) {
        primaryPaperworkData.relationships = newPaperworkRelationships.filter(datum => datum?.name !== 'BENEFICIARY');
      } else {
        primaryPaperworkData.relationships = newPaperworkRelationships;
      }
    }
    setResults(prevResult => {
      const prevProfileResult =
        symphonyDataId && Object.keys(prevResult).includes(symphonyDataId) ? prevResult[symphonyDataId] : undefined;
      if (prevProfileResult) {
        keys.forEach(key => {
          delete prevProfileResult[key];
        });
        const modifiedResults = {
          ...prevResult,
          [`${symphonyDataId}`]: { ...prevProfileResult },
        };
        return modifiedResults;
      }
      return prevResult;
    });
  };

  const handleUpdateEmploymentStatus = useCallback((status: string) => {
    setEmploymentStatus(status);
  }, []);

  const onSectionSubmit = useCallback(
    (
      section: string,
      formData: { [key: string]: string },
      dependentDataPointsFromSection: { [key: string]: boolean },
      symphonyDataId?: string,
    ) => {
      const responseObj = Object.entries(formData).reduce((acc: Record<string, string>, [key, value]) => {
        // We use this method to pass an empty string for values which are hidden
        acc[key] = dependentDataPointsFromSection[key] ? '' : value;
        return acc;
      }, {});
      setSectionResultStatus(currentState => ({
        ...currentState,
        [`${section}`]: true,
      }));
      setSectionDependentDataPoints(dependentDataPointsFromSection);
      setSectionResult({
        id: symphonyDataId ?? '',
        result: { ...responseObj },
      });
    },
    [],
  );

  const handleNext = () => {
    const status = Object.keys(sectionResultStatus).reduce((acc: Record<string, boolean>, s) => {
      acc[s] = false;
      return acc;
    }, {});
    setSectionResultStatus(status);
    setIsSubmitted(true);
    setErrorSaving(undefined);
  };

  const mapResultToSymphonyData = (symphonyDataId: string, result: Record<string, any>) => {
    const paperworkDataToUse = allPaperworkData?.find(paperworkData => paperworkData.id === symphonyDataId);
    const isPrimaryPaperwork = paperworkDataToUse?.paperworkType === PaperworkType.PRIMARY;

    const paperwork: PaperworkInput = {
      id: paperworkDataToUse?.id ?? null,
      managedId: managedProductId,
      paperworkType: paperworkDataToUse?.paperworkType ?? PaperworkType.PRIMARY,
      party: {
        id: partyId,
        partyPerson: paperworkDataToUse?.party?.partyPerson
          ? {
              ...omit(paperworkDataToUse.party.partyPerson, ['__typename', 'language']),
              language: paperworkDataToUse.party.partyPerson.language
                ? omit(paperworkDataToUse.party.partyPerson.language, '__typename')
                : {},
            }
          : {},
        partyCompany: paperworkDataToUse?.party?.partyCompany
          ? omit(paperworkDataToUse.party.partyCompany, '__typename')
          : {},
        partyContacts: paperworkDataToUse?.party?.partyContacts
          ? paperworkDataToUse.party.partyContacts.map(c => omit(c, '__typename', 'isPrimary'))
          : [],
        addresses: paperworkDataToUse?.party?.addresses
          ? paperworkDataToUse.party.addresses.map(a => omit(a, '__typename'))
          : [],
        identifiers: paperworkDataToUse?.party?.identifiers
          ? paperworkDataToUse.party.identifiers.map(i => omit(i, '__typename'))
          : [],
        relationships: [],
      },
      investment: paperworkDataToUse?.investment ? initialiseInvestmentObject(paperworkDataToUse.investment) : {},
      relationships: [],
      regulatoryInformation: paperworkDataToUse?.regulatoryInformation
        ? {
            ...omit(paperworkDataToUse.regulatoryInformation, '__typename', 'exchangeAddress'),
            exchangeAddress: paperworkDataToUse.regulatoryInformation.exchangeAddress
              ? omit(paperworkDataToUse.regulatoryInformation.exchangeAddress, '__typename')
              : null,
          }
        : {},
      wealthInformation: paperworkDataToUse?.wealthInformation
        ? omit(paperworkDataToUse.wealthInformation, '__typename')
        : {},
      isHomeAddressDerivedFromPrimary: false,
      isMailingAddressDerivedFromPrimary: false,
      isMailingAddressSameAsHomeAddress: paperworkDataToUse?.isMailingAddressSameAsHomeAddress ?? null,
      additionalAttributes: paperworkDataToUse?.additionalAttributes
        ? omit(paperworkDataToUse.additionalAttributes, '__typename')
        : {},
    };

    let dataPointSymphonyMap: Record<string, string> = {};
    sections.forEach(section => {
      if (section.dataPointSymphonyMap) {
        Object.keys(section.dataPointSymphonyMap).forEach(dpk => {
          dataPointSymphonyMap[dpk] = section.dataPointSymphonyMap?.[dpk] ?? '';
        });
        dataPointSymphonyMap = { ...dataPointSymphonyMap, ...section.dataPointSymphonyMap };
      } else {
        section.questions?.order.orderSteps.forEach(step => {
          if (
            !step.accountTypes ||
            (managedAccount?.accountType && step.accountTypes.includes(managedAccount.accountType))
          ) {
            dataPointSymphonyMap[step.dataPointKey] = step.symphonyMapping;
          }
        });
      }
    });

    const dataPointSymphonyMapKeys = Object.keys(dataPointSymphonyMap);
    Object.keys(result).forEach(key => {
      if (dataPointSymphonyMapKeys.includes(key)) {
        const symphonyMappingSplit = dataPointSymphonyMap[key].split('.');
        switch (symphonyMappingSplit[0]) {
          case 'party':
            paperwork.party = getUpdatedParty(symphonyMappingSplit, key, paperwork.party, result, hiddenDataPoints);
            if (isPrimaryPaperwork && paperwork.party.addresses) {
              primaryAddresses.current = paperwork.party.addresses;
            }
            break;
          case 'investment':
            if (paperwork.investment) {
              paperwork.investment = getUpdatedInvestment(symphonyMappingSplit, key, paperwork.investment, result);
            }
            break;
          case 'wealthInformation':
            if (paperwork.wealthInformation) {
              paperwork.wealthInformation = getUpdatedWealthInformation(
                symphonyMappingSplit[1],
                key,
                paperwork.wealthInformation,
                result,
              );
            }
            break;
          case 'isMailingAddressSameAsHomeAddress':
            /*
             * The difference between above case and this is the negative of the boolean values
             * The question asked here to the user is Use Different Mailing address.
             */
            paperwork.isMailingAddressSameAsHomeAddress = !getPaperworkBooleanFieldValue(result, key);
            break;
          default:
            break;
        }
      }
    });

    const regulatoryInformation = getCustomRegulatoryInformation(
      result,
      !isPrimaryPaperwork,
      paperwork.regulatoryInformation,
      paperwork.party.partyCompany,
      paperwork.party.addresses?.find(address => address.type === AddressType.OFFICE),
    );

    if (paperwork.regulatoryInformation) {
      paperwork.regulatoryInformation = regulatoryInformation;
    }

    const beneficiaries = getBeneficiaries(result, {}, beneficiaryIds, contingentBeneficiaryIds);
    if (paperwork.relationships) {
      paperwork.relationships = paperwork.relationships.concat(beneficiaries);
    } else {
      paperwork.relationships = beneficiaries;
    }
    const trustedContacts = getTrustedContacts(result, hiddenDataPoints);
    if (paperwork.relationships) {
      paperwork.relationships = paperwork.relationships.concat(trustedContacts);
    } else {
      paperwork.relationships = trustedContacts;
    }
    const relationshipDataPointKeys = dataPointSymphonyMapKeys.filter(k =>
      dataPointSymphonyMap[k].startsWith('relationships'),
    );
    relationshipDataPointKeys.forEach(dpk => {
      const symphonyMapping = dataPointSymphonyMap[dpk];
      const symphonyMappingSplit = symphonyMapping.split(':');
      const relationship =
        paperwork.relationships && paperwork.relationships.length > 0
          ? paperwork.relationships.find(r => r.name === (symphonyMappingSplit[1].toUpperCase() as RelationshipName))
          : null;

      let relationshipIndex = 0;
      if (paperwork.relationships) {
        if (relationship) {
          relationshipIndex = paperwork.relationships.indexOf(relationship);
        } else {
          relationshipIndex =
            paperwork.relationships.push({
              name: symphonyMappingSplit[1].toUpperCase() as RelationshipName,
              party: {
                partyPerson: {},
                partyCompany: {},
                partyContacts: [],
                addresses: [],
                identifiers: [],
                relationships: [],
              },
            }) - 1;
        }

        paperwork.relationships[relationshipIndex] = getUpdatedRelationship(
          symphonyMappingSplit.slice(2).join(':').split('.'),
          dpk,
          paperwork.relationships[relationshipIndex],
          result,
        );
      }
    });

    paperwork.relationships = paperwork.relationships.filter(
      rs =>
        (rs.party.partyPerson && Object.keys(rs.party.partyPerson).length && rs.party.partyPerson.givenName) ||
        (rs.party.partyBusinessEntity &&
          Object.keys(rs.party.partyBusinessEntity).length &&
          rs.party.partyBusinessEntity.name),
    );

    const isHomeAddressDerivedFromPrimaryChecked = getPaperworkBooleanFieldValue(
      result,
      'data_point:same_home_mailing_address:boolean',
    );
    if (!isPrimaryPaperwork && isHomeAddressDerivedFromPrimaryChecked) {
      if (paperwork.party.addresses) {
        // Filter home and mailing primary addresses and map them to secondary addresses
        const primaryHomeAndMailingAddresses: AddressInput[] = primaryAddresses.current.filter(
          (address: AddressInput) => [AddressType.HOME, AddressType.MAILING].includes(address.type),
        );
        const mappedAddressResult = paperwork.party.addresses.map(
          address => primaryHomeAndMailingAddresses.find(({ type }) => type === address.type) || address,
        );
        paperwork.party.addresses = mappedAddressResult;
      }
    }

    return paperwork;
  };

  const onUnsuccessfulCallback = () => {
    setIsSubmitted(false);
  };

  const discardStagedChanges = async () => {
    setIsDiscardStagedChanges(true);
    try {
      await updateEntityUpdateWorkflow({
        variables: {
          id: entityUpdateWorkflowID,
          status: UpdateWorkflowStatus.CANCELLED,
        },
      });
      onDiscardPendingChange?.();
    } catch (err: any) {
      setErrorSaving(err);
    } finally {
      setIsDiscardStagedChanges(false);
      onDiscardModalClose();
    }
  };

  const onSubmitAgain = async () => {
    setIsOnSubmitAgainChanges(true);
    try {
      await updateEntityUpdateWorkflow({
        variables: {
          id: entityUpdateWorkflowID,
          status: UpdateWorkflowStatus.COMPLETED,
        },
      });
      onBack();
    } catch (err: any) {
      setErrorSaving(err);
    } finally {
      setIsOnSubmitAgainChanges(false);
    }
  };

  useEffect(() => {
    if (accountProfileContent?.stateZipCodeMap) {
      setContextValues(prevContext => ({
        ...prevContext,
        stateZipCodeMap: accountProfileContent.stateZipCodeMap,
      }));
    }
  }, [accountProfileContent?.stateZipCodeMap]);

  return (
    <PaperworkContext.Provider value={contextValues}>
      <Box>
        {errorSaving && (
          <Box flexDirection="column" sx={{ pt: 2 }}>
            <Alert contentOptions={contentOptions} error={errorSaving} severity="error">
              There was an error saving the paperwork form.
            </Alert>
          </Box>
        )}
        {successOnSave && (
          <Box flexDirection="column" sx={{ pt: 2 }}>
            <Alert>Your updates were saved.</Alert>
          </Box>
        )}
        {isSubmitAgain ? (
          <Box flexDirection="column" sx={{ pt: 2 }}>
            <Alert
              action={
                <>
                  <LoadingButton
                    loading={isDiscardStagedChanges}
                    onClick={openDiscardModal}
                    sx={{ alignSelf: 'center' }}
                    variant="text"
                  >
                    {contentData?.view_only_profile_info?.labels?.find(label => label?.key === 'discard_changes')
                      ?.label ?? ''}
                  </LoadingButton>
                  <LoadingButton
                    loading={isOnSubmitAgainChanges}
                    onClick={onSubmitAgain}
                    sx={{ alignSelf: 'center' }}
                    variant="contained"
                  >
                    {contentData?.view_only_profile_info?.labels?.find(label => label?.key === 'submit_again')?.label ??
                      ''}
                  </LoadingButton>
                </>
              }
              severity="error"
            >
              <Box display="flex" flexDirection="row">
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                    {contentData?.view_only_profile_info?.labels?.find(
                      label => label?.key === 'custodian_failure_heading',
                    )?.label ?? ''}
                  </Typography>
                  <Typography variant="body2">
                    {contentData?.view_only_profile_info?.labels?.find(
                      label => label?.key === 'custodian_failure_sub_heading',
                    )?.label ?? ''}
                  </Typography>
                </Box>
              </Box>
            </Alert>
          </Box>
        ) : (
          isUpdateChangesPending && (
            <Box flexDirection="column" sx={{ pt: 2 }}>
              <Alert
                action={
                  <LoadingButton loading={isDiscardStagedChanges} onClick={openDiscardModal} variant="outlined">
                    {contentData?.view_only_profile_info?.labels?.find(label => label?.key === 'discard_changes')
                      ?.label ?? ''}
                  </LoadingButton>
                }
                severity="info"
              >
                <Box display="flex" flexDirection="row">
                  <Box>
                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                      {contentData?.view_only_profile_info?.labels?.find(
                        label => label?.key === 'pending_signature_heading',
                      )?.label ?? ''}
                    </Typography>
                    <Typography variant="body2">
                      {contentData?.view_only_profile_info?.labels?.find(
                        label => label?.key === 'pending_signature_sub_heading',
                      )?.label ?? ''}
                    </Typography>
                  </Box>
                </Box>
              </Alert>
            </Box>
          )
        )}
        {loading || contentLoading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : error || contentError ? (
          <Alert contentOptions={contentOptions} error={error || contentError} severity="error" />
        ) : sections.length === 0 ? (
          <></>
        ) : (
          <>
            <Box data-qa={dataQa} sx={{ ...sfAccountProfileStyles.container }}>
              <Grid container direction="row">
                <RteContent
                  config={{
                    accountNumber: maskedAccountNumber,
                    clientName: `${primaryPaperworkData?.party?.partyPerson?.givenName} ${primaryPaperworkData?.party?.partyPerson?.familyName}`,
                  }}
                  data={contentData?.heading?.account_number ?? ''}
                  data-qa={`${dataQa}-account-heading`}
                />
              </Grid>
              <RteContent
                data={contentData?.sub_heading ?? ''}
                data-qa={`${dataQa}-account-sub-heading`}
                sx={{ mb: 2, mt: 3 }}
              />
              {sections.map((section, index) => {
                const questionContent: CMSQuestions | undefined = accountProfileContent?.questionsContent?.find(
                  c => c.section_key === section.heading,
                );

                const CustomSectionComponent = section.customSectionComponent;
                // for editing a beneficiary section isUpdatingBeneficiariesAllowed should be true and in case of contingent_beneficiary atleast one primary beneficiary should exist.
                const hiddenSection = section.titleKey?.includes('beneficiary')
                  ? primaryPaperworkData?.isUpdatingBeneficiariesAllowed
                    ? section.titleKey === 'contingent_beneficiary'
                      ? !beneficiaryIds.length
                      : false
                    : true
                  : false;

                const symphonyData =
                  section.isSectionSecondaryPaperwork && secondayPaperworkProfiles
                    ? secondayPaperworkProfiles[section.sectionSecondaryPaperworkIndex ?? 0]
                    : primaryPaperworkData;

                const sectionHeading = getSectionHeading({
                  contentDataSection: contentData?.section,
                  section,
                  symphonyData,
                });
                if (!questionContent) {
                  return (
                    <Box data-qa={`cms-error-${section.heading}`} key={index} sx={{ py: 2, px: 4 }}>
                      <Alert severity="error">Question content not found in CMS for section: {section.heading}</Alert>
                    </Box>
                  );
                }
                const disclaimerText = getDisclaimerText(contentData?.disclaimers, section.titleKey);
                return (
                  <Box key={index}>
                    {!hiddenSection && (
                      <>
                        <Accordion
                          expanded={accordionStates[`${index}`] ?? true}
                          key={index}
                          onChange={(_, expanded) => {
                            setAccordionStates({ ...accordionStates, [`${index}`]: expanded });
                          }}
                          sx={{ py: 3 }}
                        >
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            expandIcon={<ExpandMoreIcon />}
                            id="panel1a-header"
                            sx={{
                              px: 0,
                              my: 0.5,
                              '&.Mui-expanded': {
                                mt: index ? -2.5 : 0,
                                mb: -2.5,
                              },
                            }}
                          >
                            <Typography data-qa={`${dataQa}-section-${section.titleKey}`} variant="subtitle1">
                              {sectionHeading ?? section.heading}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails data-qa={`${dataQa}-accordion-details-${section.titleKey}`} sx={{ p: 0 }}>
                            <Box sx={{ pt: 2 }}>
                              {!section.customSectionComponent && section.questions ? (
                                <Questions
                                  accountType={managedAccount?.accountType}
                                  allPaperworkData={managedAccount?.paperwork ?? []}
                                  beneficiaryConfig={beneficiaryConfig}
                                  content={{
                                    questions: questionContent,
                                    validationMessages,
                                  }}
                                  contentOptions={contentOptions}
                                  ctas={contentData?.ctas ?? undefined}
                                  dataQa={`${dataQa}-section-questions`}
                                  deleteKeysFromResult={(keys, relationshipDetails) =>
                                    deleteKeysFromResult(keys, relationshipDetails, symphonyData?.id ?? '')
                                  }
                                  dropdownsContent={{
                                    statesList: accountProfileContent?.statesList ?? [],
                                    countriesList: accountProfileContent?.countriesList ?? [],
                                  }}
                                  employmentStatus={employmentStatus}
                                  isAccountProfileEdit
                                  isSecondary={section.isSectionSecondaryPaperwork}
                                  isSubmitted={isSubmitted}
                                  managedProductId={managedProductId}
                                  minorAgesByState={accountProfileContent?.minorAgesByState ?? []}
                                  onSubmit={(
                                    sectionKey: string,
                                    formData: {
                                      [key: string]: string;
                                    },
                                    dependentDataPointsFromSection: {
                                      [key: string]: boolean;
                                    },
                                  ) => {
                                    onSectionSubmit(
                                      section.titleKey ?? sectionKey,
                                      formData,
                                      dependentDataPointsFromSection,
                                      symphonyData?.id ?? '',
                                    );
                                  }}
                                  onUnsuccessfulCallback={onUnsuccessfulCallback}
                                  order={section.questions.order}
                                  partyId={partyId}
                                  primaryAddresses={primaryAddresses.current}
                                  repCodes={accountProfileContent?.repCodes ?? []}
                                  results={results[symphonyData?.id ?? ''] ?? {}}
                                  section={section.heading}
                                  sectionIndex={index}
                                  setBeneficiaryIds={setBeneficiaryIds}
                                  setContextValues={setContextValues}
                                  setContingentBeneficiaryIds={setContingentBeneficiaryIds}
                                  symphonyData={symphonyData}
                                  updateAccordionStates={i =>
                                    setAccordionStates({ ...accordionStates, [`${i}`]: true })
                                  }
                                  updateEmploymentStatus={handleUpdateEmploymentStatus}
                                />
                              ) : (
                                CustomSectionComponent &&
                                managedAccount?.accountType && (
                                  <CustomSectionComponent
                                    accountType={managedAccount.accountType}
                                    content={{ questions: questionContent, validationMessages }}
                                    contentOptions={contentOptions}
                                    disablePageEditing={disablePageEditing}
                                    dropdownsContent={{
                                      countriesList: [],
                                    }}
                                    isAccountProfileEdit
                                    isSubmitted={isSubmitted}
                                    managedProductId={managedProductId}
                                    onSubmit={(
                                      sectionKey: string,
                                      formData: {
                                        [key: string]: string;
                                      },
                                      dependentDataPointsFromSection: {
                                        [key: string]: boolean;
                                      },
                                    ) => {
                                      onSectionSubmit(
                                        section.titleKey ?? sectionKey,
                                        formData,
                                        dependentDataPointsFromSection,
                                        symphonyData?.id ?? '',
                                      );
                                    }}
                                    onUnsuccessfulCallback={onUnsuccessfulCallback}
                                    prefillValues={symphonyData}
                                    section={section.heading}
                                    sectionResultStatus={{}}
                                  />
                                )
                              )}
                              {disclaimerText && (
                                <Alert
                                  contentOptions={contentOptions}
                                  data-qa={`section-disclaimer-${index}`}
                                  severity="info"
                                >
                                  {disclaimerText}
                                </Alert>
                              )}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        {!(index === sections.length - 1) && <Divider sx={{ my: '1px' }} />}
                      </>
                    )}
                  </Box>
                );
              })}
              <Divider sx={{ my: 5 }} />
              <Typography data-qa={`${dataQa}-disclaimer`} variant="body2">
                {contentData?.disclaimer}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pb: 14 }}>
              {showSecondaryCTA && (
                <Button onClick={onBack} sx={{ mr: 2, mb: 1.5 }} variant="outlined">
                  {getCtaText(contentData?.ctas, 'secondary')}
                </Button>
              )}
              <LoadingButton
                disabled={disablePageEditing}
                loading={isSubmitted}
                onClick={handleNext}
                sx={{ mb: 1.5 }}
                variant="contained"
              >
                {getCtaText(contentData?.ctas, 'primary')}
              </LoadingButton>
            </Box>
          </>
        )}
        <BackToAccountDetails
          content={{
            ctas: {
              primary: contentData?.confirmation_modal?.ctas?.primary ?? '',
              secondary: contentData?.confirmation_modal?.ctas?.secondary ?? '',
            },
            header: contentData?.confirmation_modal?.header ?? '',
            subHeader: contentData?.confirmation_modal?.sub_header ?? '',
            title: contentData?.confirmation_modal?.title ?? '',
          }}
          disableSaving={disablePageEditing}
          onClose={closeBackToAccountDetailsModal}
          onDiscard={() => {
            closeBackToAccountDetailsModal();
            onBack();
          }}
          onSave={() => {
            handleNext();
            closeBackToAccountDetailsModal();
          }}
          open={openBackToAccountDetailsModalState}
        />
        <DiscardPendingChange
          content={{
            ctas: {
              cancel: contentData?.discard_pending_update_modal?.ctas?.find(cta => cta?.key === 'cancel')?.label ?? '',
              close: contentData?.discard_pending_update_modal?.ctas?.find(cta => cta?.key === 'cancel')?.label ?? '',
              discard:
                contentData?.discard_pending_update_modal?.ctas?.find(cta => cta?.key === 'discard_changes')?.label ??
                '',
            },
            discardMessage: contentData?.discard_pending_update_modal?.discard_message ?? '',
            title: contentData?.discard_pending_update_modal?.title ?? '',
          }}
          contentOptions={contentOptions}
          dataQa={`${dataQa}-discard-staged-changes-model`}
          loading={isDiscardStagedChanges}
          onClose={onDiscardModalClose}
          onDiscard={discardStagedChanges}
          open={isDiscardModalOpen}
          showDiscardSuccessful
          showWarningIcon
        />
      </Box>
    </PaperworkContext.Provider>
  );
};
