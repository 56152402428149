import omit from 'lodash.omit';

import {
  exchangeAffiliationTypeFieldName,
  exchangeCompanyAffiliationLetterFieldName,
  exchangeCompanyCityFieldName,
  exchangeCompanyNameFieldName,
  exchangeCompanyStateFieldName,
  exchangeCompanyStreetAddressFieldName,
  exchangeCompanyStreetLineFieldName,
  exchangeCompanyZipCodeFieldName,
  exchangeRelationshipFieldName,
  phcControlTypeFieldName,
  phcNameFieldName,
  phcRelationshipFieldName,
  phcTickerFieldName,
  RegulatoryKeys,
  sameAsEmployerFieldName,
  spfCountryFieldName,
  spfNameFieldName,
  spfRelationshipFieldName,
  spfTitleFieldName,
} from '../Regulatory';

import { getPaperworkBooleanFieldValue } from './helper';

import { AddressInput, AddressType, CompanyInput, RegulatoryInformationInput } from '~/__generated__';

export const getCustomRegulatoryInformation = (
  result: Record<string, any>,
  isSecondaryPaperwork?: boolean,
  prefilledRegulatoryInformation?: RegulatoryInformationInput | null,
  companyDetails?: CompanyInput | null,
  employmentAddress?: AddressInput | null,
) => {
  const regulatoryInformation: RegulatoryInformationInput = {};
  const {
    accreditedInvestorKey,
    spfKey,
    phcKey,
    exchangeKey,
    associatedWithRegisteredInvestmentAdvisorKey,
    associatedWithAccountHoldingBrokerDealerKey,
    associatedWithNonAccountHoldingBrokerDealerKey,
  } = RegulatoryKeys;

  // If one key exists in result, we assume that all other regulatory keys will also be present in the result object
  const usePrefilledValues = !(spfKey in result);
  regulatoryInformation.hasPartnerEmployeeConnection = prefilledRegulatoryInformation?.hasPartnerEmployeeConnection;
  regulatoryInformation.hasSpfConnection = usePrefilledValues
    ? prefilledRegulatoryInformation?.hasSpfConnection
    : getPaperworkBooleanFieldValue(result, spfKey);
  regulatoryInformation.isPhcSeniorOfficer = usePrefilledValues
    ? prefilledRegulatoryInformation?.isPhcSeniorOfficer
    : getPaperworkBooleanFieldValue(result, phcKey);
  regulatoryInformation.isExchangeEmployed = usePrefilledValues
    ? prefilledRegulatoryInformation?.isExchangeEmployed
    : getPaperworkBooleanFieldValue(result, exchangeKey);

  regulatoryInformation.hasAffiliationLetter = usePrefilledValues
    ? prefilledRegulatoryInformation?.hasAffiliationLetter
    : getPaperworkBooleanFieldValue(result, exchangeCompanyAffiliationLetterFieldName);
  regulatoryInformation.isAccreditedInvestor = usePrefilledValues
    ? prefilledRegulatoryInformation?.isAccreditedInvestor
    : getPaperworkBooleanFieldValue(result, accreditedInvestorKey);
  regulatoryInformation.isEmployedByAccountBroker = usePrefilledValues
    ? prefilledRegulatoryInformation?.isEmployedByAccountBroker
    : getPaperworkBooleanFieldValue(result, associatedWithAccountHoldingBrokerDealerKey);
  regulatoryInformation.isEmployedByAnotherBroker = usePrefilledValues
    ? prefilledRegulatoryInformation?.isEmployedByAnotherBroker
    : getPaperworkBooleanFieldValue(result, associatedWithNonAccountHoldingBrokerDealerKey);
  regulatoryInformation.isAssociatedWithUsRegisteredInvestmentAdvisor = usePrefilledValues
    ? prefilledRegulatoryInformation?.isAssociatedWithUsRegisteredInvestmentAdvisor
    : getPaperworkBooleanFieldValue(result, associatedWithRegisteredInvestmentAdvisorKey);

  if (regulatoryInformation.hasSpfConnection === true) {
    regulatoryInformation.nameOfSeniorPoliticalFigure = usePrefilledValues
      ? prefilledRegulatoryInformation?.nameOfSeniorPoliticalFigure
      : result[spfNameFieldName];
    regulatoryInformation.relationship = usePrefilledValues
      ? prefilledRegulatoryInformation?.relationship
      : result[spfRelationshipFieldName];
    regulatoryInformation.politicalTitle = usePrefilledValues
      ? prefilledRegulatoryInformation?.politicalTitle
      : result[spfTitleFieldName];
    regulatoryInformation.countryOfOffice = usePrefilledValues
      ? prefilledRegulatoryInformation?.countryOfOffice
      : result[spfCountryFieldName];
  }
  if (regulatoryInformation.isPhcSeniorOfficer === true) {
    regulatoryInformation.publiclyHeldCompanyName = usePrefilledValues
      ? prefilledRegulatoryInformation?.publiclyHeldCompanyName
      : result[phcNameFieldName];
    regulatoryInformation.ticker = usePrefilledValues
      ? prefilledRegulatoryInformation?.ticker
      : result[phcTickerFieldName];
    regulatoryInformation.phcRelationship = usePrefilledValues
      ? prefilledRegulatoryInformation?.phcRelationship
      : result[phcRelationshipFieldName];
    regulatoryInformation.controlType = usePrefilledValues
      ? prefilledRegulatoryInformation?.controlType
      : result[phcControlTypeFieldName];
  }
  if (regulatoryInformation.isExchangeEmployed === true) {
    // Setting a default value because we don't always ask for the exchange affiliation type
    regulatoryInformation.finraRelationship = usePrefilledValues
      ? prefilledRegulatoryInformation?.finraRelationship
      : result[exchangeRelationshipFieldName];
    regulatoryInformation.exchangeAffiliationType = usePrefilledValues
      ? prefilledRegulatoryInformation?.exchangeAffiliationType
      : result[exchangeAffiliationTypeFieldName].length > 0
      ? result[exchangeAffiliationTypeFieldName]
      : 'FINRA';
    const exchangeFieldsAddress = {
      addressLine1: usePrefilledValues
        ? prefilledRegulatoryInformation?.exchangeAddress?.addressLine1
        : result[exchangeCompanyStreetAddressFieldName] ?? '',
      addressLine2: usePrefilledValues
        ? prefilledRegulatoryInformation?.exchangeAddress?.addressLine2
        : result[exchangeCompanyStreetLineFieldName] ?? '',
      countryPrimarySubdivision: usePrefilledValues
        ? prefilledRegulatoryInformation?.exchangeAddress?.countryPrimarySubdivision
        : result[exchangeCompanyStateFieldName] ?? '',
      countrySecondarySubdivision: usePrefilledValues
        ? prefilledRegulatoryInformation?.exchangeAddress?.countrySecondarySubdivision
        : result[exchangeCompanyCityFieldName] ?? '',
      postalCode: usePrefilledValues
        ? prefilledRegulatoryInformation?.exchangeAddress?.postalCode
        : result[exchangeCompanyZipCodeFieldName] ?? '',
      type: AddressType.OFFICE,
    };

    if (usePrefilledValues) {
      regulatoryInformation.exchangeEmployerName = prefilledRegulatoryInformation?.exchangeEmployerName;
      regulatoryInformation.exchangeAddress = omit(prefilledRegulatoryInformation?.exchangeAddress, '__typename');
    } else if (
      // Check for null/undefined as a fallback where the value isn't selected
      !result[sameAsEmployerFieldName] ||
      result[sameAsEmployerFieldName] === 'data_point:regulatory_same_as_employer:single_option_no'
    ) {
      regulatoryInformation.exchangeEmployerName = result[exchangeCompanyNameFieldName];
      regulatoryInformation.exchangeAddress = exchangeFieldsAddress;
    } else if (result[sameAsEmployerFieldName] === 'data_point:regulatory_same_as_employer:single_option_yes') {
      regulatoryInformation.exchangeEmployerName =
        companyDetails?.organizationName ?? result[exchangeCompanyNameFieldName];
      regulatoryInformation.exchangeAddress =
        // Check that employmentAddress has valid information, otherwise use exchangeFieldsAddress
        employmentAddress?.addressLine1 ? employmentAddress : exchangeFieldsAddress;
      if (isSecondaryPaperwork) {
        if (result['data_point:company_street_address:string'] !== '') {
          regulatoryInformation.exchangeAddress = {
            addressLine1: result['data_point:company_street_address:string'] ?? '',
            addressLine2: result['data_point:company_street_line_2:string'] ?? '',
            countryPrimarySubdivision: result['data_point:company_state:single_option'] ?? '',
            countrySecondarySubdivision: result['data_point:company_city:string'] ?? '',
            postalCode: result['data_point:company_zip_code:number'] ?? '',
            type: AddressType.OFFICE,
          };
        } else if (result['data_point:self_employed_company_street_address:string'] !== '') {
          regulatoryInformation.exchangeAddress = {
            addressLine1: result['data_point:self_employed_company_street_address:string'] ?? '',
            addressLine2: result['data_point:self_employed_company_street_line_2:string'] ?? '',
            countryPrimarySubdivision: result['data_point:self_employed_company_state:single_option'] ?? '',
            countrySecondarySubdivision: result['data_point:self_employed_company_city:string'] ?? '',
            postalCode: result['data_point:self_employed_company_zip_code:number'] ?? '',
            type: AddressType.OFFICE,
          };
        } else {
          regulatoryInformation.exchangeAddress = exchangeFieldsAddress;
        }
      }
    }
  }
  return regulatoryInformation;
};
export const getRegulatoryInformation = (
  objKey: string,
  key: string,
  regulatoryInformation: RegulatoryInformationInput,
  result: Record<string, any>,
) => {
  switch (objKey) {
    case 'isGovernmentEmployee':
      regulatoryInformation.isGovernmentEmployee =
        typeof result[key] === 'boolean' ? result[key] : JSON.parse(result[key].toLowerCase());
      break;
    case 'isForeignGovernmentEmployee':
      regulatoryInformation.isForeignGovernmentEmployee =
        typeof result[key] === 'boolean' ? result[key] : JSON.parse(result[key].toLowerCase());
      break;
    case 'gamblingIndustryParticipation':
      regulatoryInformation.gamblingIndustryParticipation =
        typeof result[key] === 'boolean' ? result[key] : JSON.parse(result[key].toLowerCase());
      break;
    case 'marijuanaBusinessesParticipation':
      regulatoryInformation.marijuanaBusinessesParticipation =
        typeof result[key] === 'boolean' ? result[key] : JSON.parse(result[key].toLowerCase());
      break;
    case 'isDiplomaticEmployee':
      regulatoryInformation.isDiplomaticEmployee =
        typeof result[key] === 'boolean' ? result[key] : JSON.parse(result[key].toLowerCase());
      break;
    case 'isAssociatedWithOfacOrSdn':
      regulatoryInformation.isAssociatedWithOfacOrSdn =
        typeof result[key] === 'boolean' ? result[key] : JSON.parse(result[key].toLowerCase());
      break;
    default:
      regulatoryInformation[objKey as keyof RegulatoryInformationInput] = key.split(':').includes('boolean')
        ? getPaperworkBooleanFieldValue(result, key)
        : result[key];
      break;
  }
  return regulatoryInformation;
};
