import { range } from 'fp-ts/Array';

import { getDefaultValueForPartyKey, getUpdatedParty } from './party';

import { RegulatoryInformationInput } from '~/__generated__';
import {
  AddressType,
  ContactType,
  PartyInput,
  RelationshipInput,
  RelationshipName,
  RelationshipSubtype,
} from '~/__generated__';

export const getDefaultValueForRelationshipsKey = (relationships: RelationshipInput[], symphonyMapping: string) => {
  const symphonyMappingSplit = symphonyMapping.split(':');
  const name = symphonyMappingSplit[1].toUpperCase() as RelationshipName;
  const relationship = relationships?.find(r => r?.name === name);
  if (relationship) {
    const fieldName = symphonyMappingSplit.slice(2).join(':');
    const fieldNameSplit = fieldName.split('.');
    switch (fieldNameSplit[0]) {
      case 'party':
        return getDefaultValueForPartyKey(relationship.party as PartyInput, fieldNameSplit.slice(1).join('.'));
      case 'relationshipType':
        return relationship.relationshipType;
    }
  }
};

const getContactsFromTrustedContactKeys = (
  trustedContactKeys: string[],
  result: Record<string, any>,
  hideTrustedContacts: boolean,
) => {
  const numOfTrustedContacts = trustedContactKeys.length / 11;
  const trustedContacts: RelationshipInput[] = [];
  if (numOfTrustedContacts > 0 && !hideTrustedContacts) {
    range(0, numOfTrustedContacts - 1).forEach(i => {
      const indexedBeneficiaryKeys = trustedContactKeys.filter(k => k.endsWith(i.toString()));
      if (indexedBeneficiaryKeys.length > 0) {
        const relationshipName = indexedBeneficiaryKeys[0].split('-')[0];
        const contactFirstName = result[`${relationshipName}-first-name-${i}`];
        const contactMiddleName = result[`${relationshipName}-middle-name-${i}`];
        const contactLastName = result[`${relationshipName}-last-name-${i}`];
        if (contactFirstName) {
          const trustedContact: RelationshipInput = {
            name: relationshipName
              ? (relationshipName.toUpperCase() as RelationshipName)
              : RelationshipName.UNKNOWN_RELATIONSHIP_NAME,
            party: {
              partyPerson: {
                givenName: contactFirstName,
                middleName: contactMiddleName ?? '',
                familyName: contactLastName,
              },
              partyContacts: [
                {
                  contact: result[`${relationshipName}-email-${i}`] ?? '',
                  type: ContactType.EMAIL,
                },
                {
                  contact: result[`${relationshipName}-phone-number-${i}`] ?? '',
                  type: ContactType.MOBILE,
                },
              ].filter(contact => contact.contact.length),
              addresses: [
                {
                  countryPrimarySubdivision: result[`${relationshipName}-state-${i}`],
                  countrySecondarySubdivision: result[`${relationshipName}-city-${i}`],
                  postalCode: result[`${relationshipName}-zip-code-${i}`],
                  addressLine1: result[`${relationshipName}-street-address-${i}`],
                  addressLine2: result[`${relationshipName}-street-line-${i}`] ?? '',
                  type: AddressType.HOME,
                },
              ],
              identifiers: [],
            },
            relationshipType: Object.values(RelationshipSubtype).includes(
              result[`${relationshipName}-relationship-${i}`],
            )
              ? (result[`${relationshipName}-relationship-${i}`] as RelationshipSubtype)
              : RelationshipSubtype.OTHER,
          };
          trustedContacts.push(trustedContact);
        }
      }
    });
  }

  return trustedContacts;
};

export const getTrustedContacts = (
  result: Record<string, any>,
  hiddenDataPoints: { [key: string]: boolean },
): RelationshipInput[] => {
  const trustedContactKeys = Object.keys(result).filter(k => k.startsWith('trusted_contact'));
  const hideTrustedContacts = hiddenDataPoints['custom:trusted_contact:contacts'] ?? false;

  return getContactsFromTrustedContactKeys(trustedContactKeys, result, hideTrustedContacts);
};

export const getUpdatedRelationship = (
  symphonyMappingSplit: string[],
  key: string,
  relationship: RelationshipInput,
  result: Record<string, any>,
) => {
  switch (symphonyMappingSplit[0]) {
    case 'party':
      relationship.party = getUpdatedParty(symphonyMappingSplit, key, relationship.party, result, []);
      break;
    case 'relationshipType':
      relationship.relationshipType =
        result[key] && Object.values(RelationshipSubtype).includes(result[key])
          ? (result[key] as RelationshipSubtype)
          : RelationshipSubtype.OTHER;
      break;
    default:
      break;
  }

  return relationship;
};

export const getDefaultValueForRegulatoryInformationKey = (
  regulatoryInfromation: RegulatoryInformationInput,
  regulatoryInfromationMapping: string,
) => {
  return regulatoryInfromation?.[regulatoryInfromationMapping as keyof RegulatoryInformationInput] ?? '';
};
