import { GridProps } from '@mui/material';
import * as React from 'react';

import { Grid } from '~/components';

interface ModalActionsLayoutProps extends GridProps {
  otherActions?: React.ReactNode;
  primaryActions: React.ReactNode;
  secondaryActions: React.ReactNode;
}
/**
 * This component is used to layout the action buttons in a Modal component,
 * aiming to guarantee proper alignment both on the desktop version with the
 * dialog variant and the mobile version with the drawer variant.
 *
 */
export const ModalActionsLayout: React.FC<ModalActionsLayoutProps> = ({
  otherActions,
  primaryActions,
  secondaryActions,
  ...props
}) => (
  <Grid
    alignItems={{ xs: 'initial', md: 'center' }}
    container
    direction={{ xs: 'column-reverse', md: 'row' }}
    spacing={2}
    {...props}
  >
    {otherActions && (
      <Grid item md ml={1.5} xs={12}>
        {otherActions}
      </Grid>
    )}
    {secondaryActions && (
      <Grid item md="auto" xs={12}>
        {secondaryActions}
      </Grid>
    )}
    {primaryActions && (
      <Grid item md="auto" xs={12}>
        {primaryActions}
      </Grid>
    )}
  </Grid>
);
