import React from 'react';

import { EntityUpdateWorkflowPaperwork } from '~/components/AccountProfileUpdateWrapper/symphony';

export interface AccountProfileContextVariables {
  disablePageEditing: boolean;
  entityUpdateWorkflowID: string;
  entityUpdateWorkflowPaperwork: EntityUpdateWorkflowPaperwork[] | null;
  isSubmitAgain: boolean;
  isUpdateChangesPending: boolean;
  partyIdForSigningDocuments: string;
}

export const defaultAccountProfileContextValues: AccountProfileContextVariables = {
  disablePageEditing: false,
  entityUpdateWorkflowID: '',
  entityUpdateWorkflowPaperwork: null,
  isSubmitAgain: false,
  isUpdateChangesPending: false,
  partyIdForSigningDocuments: '',
};
export const AccountProfileContext = React.createContext(defaultAccountProfileContextValues);
