import {
  GetTaxLossHarvestingContent,
  GetTaxLossHarvestingContentVariables,
} from './__generated__/GetTaxLossHarvestingContent';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetTaxLossHarvestingContent = (
  options?: QueryHookOptions<GetTaxLossHarvestingContent, GetTaxLossHarvestingContentVariables>,
): QueryResult<GetTaxLossHarvestingContent, GetTaxLossHarvestingContentVariables> => {
  return useContentstackQuery(queries.GetTaxLossHarvestingContent, options);
};
