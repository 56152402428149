import { QueryHookOptions, QueryResult } from '@apollo/client';

import {
  GetDocusignSignatureStatus,
  GetDocusignSignatureStatus_managedProduct_legalDocuments,
  GetDocusignSignatureStatus_managedProduct_legalDocuments_signees,
  GetDocusignSignatureStatusVariables,
} from './__generated__/GetDocusignSignatureStatus';
import * as queries from './query.gql';

import { useSymphonyQuery } from '~/utils';

export type LegalDocuments = GetDocusignSignatureStatus_managedProduct_legalDocuments;
export type LegalDocumentSignees = GetDocusignSignatureStatus_managedProduct_legalDocuments_signees[];
export const useGetDocusignSignatureStatus = (
  options?: QueryHookOptions<GetDocusignSignatureStatus, GetDocusignSignatureStatusVariables>,
): QueryResult<GetDocusignSignatureStatus, GetDocusignSignatureStatusVariables> => {
  return useSymphonyQuery(queries.GetDocusignSignatureStatus, options);
};
