import React from 'react';

import { ZipCodeMapItem } from './hooks/useGetPaperworkData';

import { EmploymentStatus, FinancialAccountType, RepCodeInput } from '~/__generated__';

// This interface is created to for Corporate Paperwork flow to count for Authorized Individuals
// and Non Authorized Individuals accounts which include Beneficial Owner and Control Persons who are not Authorized
export interface CorporateAccountHolderNumbers {
  AI: { isAuthorizedIndividualEntity: boolean; isOnlyAuthorizedIndividual: boolean }[];
  NON_AI: { isStakeHolderEntity: boolean }[];
}

export interface PaperworkContextVariables {
  accountHolderSelectedContactList: string[];
  accountType?: FinancialAccountType;
  ageOfTermination?: number;
  annualIncomePrimary?: number;
  currentContact: string;
  differentMailingAddressValue?: boolean;
  employmentDetails?: {
    employmentStatus: EmploymentStatus;
    paperworkId: string | null;
  };
  isNonUsCitizen: boolean;
  isResidentAlien: boolean;
  isValidNetWorth: boolean;
  maritalStatus: string;
  netWorth?: string;
  primaryUserHomeStateCode?: string;
  repCodes?: (RepCodeInput | null)[];
  sameAsPrimaryAddress?: boolean;
  stateZipCodeMap?: ZipCodeMapItem[];
  taxation?: string;
  tenantInCommonPercentagePrimary?: number;
  userHomeStateCode?: string;
  userMailStateCode?: string;
}

export const defaultPaperworkContext: PaperworkContextVariables = {
  currentContact: '',
  accountHolderSelectedContactList: [],
  isValidNetWorth: true,
  isNonUsCitizen: true,
  isResidentAlien: false,
  maritalStatus: '',
};

export const PaperworkContext = React.createContext<PaperworkContextVariables>(defaultPaperworkContext);
