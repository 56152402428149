import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FieldError, UseFormMethods } from 'react-hook-form';

import { DropdownsContent, QuestionContent, ValidationMessage } from '../Section/Questions/types';
import { isValidNumberInput, mandatoryCheckboxValidation } from '../utils/helper';

import { isCompanyNameInputChangeAllowed, isInputLengthValid } from './utils';

import { EmploymentStatus, RegulatoryRelationship, RelationshipSubtype } from '~/__generated__';
import { SecuritySearchOption, TickerSearch } from '~/components/TickerSearch';
import {
  Alert,
  Box,
  CheckboxGroup,
  Dropdown,
  RadioGroup,
  RteContent,
  TextField,
  Typography,
  useTheme,
} from '~/components/ui';
import { PaperworkContext } from '~/containers/Paperwork/context';
import {
  CmsKeys,
  getDropdownItems,
  getErrorMessage,
  isValidLength,
  isValidTicker,
  isValidZipCode,
} from '~/containers/Paperwork/Section/Questions/utils';
import { PaperworkPartyCompanyInformation, PaperworkRegulatoryInformation } from '~/containers/Paperwork/symphony';
import { SfTheme, useCoreConfig } from '~/utils';

export interface FormData {
  [dataPointKey: string]: any;
}

export enum RegulatoryKeys {
  accreditedInvestorKey = 'regulatory-accredited-investor',
  associatedWithAccountHoldingBrokerDealerKey = 'regulatory-associated-with-account-holding-broker-dealer',
  associatedWithNonAccountHoldingBrokerDealerKey = 'regulatory-associated-with-non-account-holding-broker-dealer',
  associatedWithRegisteredInvestmentAdvisorKey = 'regulatory-associated-with-registered-investment-advisor',
  exchangeKey = 'regulatory-exchange-company',
  phcKey = 'regulatory-phc',
  spfKey = 'regulatory-senior-political-figure',
}

export const {
  spfKey,
  phcKey,
  exchangeKey,
  accreditedInvestorKey,
  associatedWithNonAccountHoldingBrokerDealerKey,
  associatedWithAccountHoldingBrokerDealerKey,
  associatedWithRegisteredInvestmentAdvisorKey,
} = RegulatoryKeys;
// As this is a custom question component, we pass the symphonyMappingKey and questionKey to the custom component,
// then map it within the component with their respective field names. The questionKey should follow the first part of
// the custom component name.
export const spfCheckboxFieldName = spfKey;
export const spfNameFieldName = `${spfKey}-name`;
export const spfRelationshipFieldName = `${spfKey}-relationship`;
export const spfTitleFieldName = `${spfKey}-title`;
export const spfCountryFieldName = `${spfKey}-country`;
export const phcCheckboxFieldName = phcKey;
export const phcNameFieldName = `${phcKey}-company-name`;
export const phcTickerFieldName = `${phcKey}-ticker`;
export const phcRelationshipFieldName = `${phcKey}-relationship`;
export const phcControlTypeFieldName = `${phcKey}-control-type`;
export const exchangeEmployedCheckboxFieldName = exchangeKey;
export const sameAsEmployerFieldName = `${exchangeKey}-same-as-employer`;
export const exchangeCompanyNameFieldName = `${exchangeKey}-name`;
export const exchangeAffiliationTypeFieldName = `${exchangeKey}-affiliation-type`;
export const exchangeRelationshipFieldName = `${exchangeKey}-relationship`;
export const exchangeCompanyStreetAddressFieldName = `${exchangeKey}-street-address`;
export const exchangeCompanyStreetLineFieldName = `${exchangeKey}-street-line`;
export const exchangeCompanyStateFieldName = `${exchangeKey}-state`;
export const exchangeCompanyCityFieldName = `${exchangeKey}-city`;
export const exchangeCompanyZipCodeFieldName = `${exchangeKey}-zip-code`;
export const exchangeCompanyAffiliationLetterFieldName = `${exchangeKey}-company_affiliation_letter`;
export const accreditedInvestorCheckboxFieldName = accreditedInvestorKey;
export const associatedWithNonAccountHoldingBrokerDealerCheckboxFieldName = associatedWithNonAccountHoldingBrokerDealerKey;
export const associatedWithAccountHoldingBrokerDealerCheckboxFieldName = associatedWithAccountHoldingBrokerDealerKey;
export const associatedWithRegisteredInvestmentAdvisorCheckboxFieldName = associatedWithRegisteredInvestmentAdvisorKey;

export interface Props {
  content: {
    questions: QuestionContent[];
    tickerSearchAddTickerManuallyCta: string;
    tickerSearchNoOptionsText: string;
    tickerSearchPlaceholder: string;
    validationMessages?: ValidationMessage[];
  };
  dataQa?: string;
  dropdownsContent: DropdownsContent;
  employmentData?: PaperworkPartyCompanyInformation | null;
  employmentStatus?: string;
  formHooks: UseFormMethods<FormData>;
  paperworkId: string | null;
  questionKey: string;
  regulatoryData?: PaperworkRegulatoryInformation | null;
}

export const Regulatory: FC<Props> = ({
  dataQa = 'regulatory',
  formHooks,
  content,
  dropdownsContent,
  questionKey,
  regulatoryData,
  employmentData,
  paperworkId,
}) => {
  const {
    sfPaperwork: { styles: style },
  } = useTheme<SfTheme>();

  const {
    autoFillCompanyNameFromTicker,
    checkMaritalStatus,
    isAffiliationLetterFieldEnabled,
  } = useCoreConfig().components.sfPaperwork;
  const [hasSpfConnection, setHasSpfConnection] = useState<boolean>();
  const [isPhcSeniorOffice, setIsPhcSeniorOfficer] = useState<boolean>();
  const [isExchangeEmployed, setIsExchangeEmployed] = useState<boolean>();
  const [isSameEmployer, setIsSameEmployer] = useState<boolean>();
  const [tickerOption, setTickerOption] = useState<SecuritySearchOption | null>({
    label: regulatoryData?.ticker ?? '',
    name: regulatoryData?.ticker ?? '',
    ticker: regulatoryData?.ticker ?? '',
  });
  const [showTickerSearch, setShowTickerSearch] = useState<boolean>(true);

  const { employmentDetails, maritalStatus, stateZipCodeMap } = useContext(PaperworkContext);

  const { control, errors: fieldsErrors, getValues, setValue, watch } = formHooks;
  const watchExchangeRelationshipType = watch(exchangeRelationshipFieldName);
  const checkMaritalValue = (value: string) =>
    maritalStatus && maritalStatus !== 'MARRIED' && checkMaritalStatus && value === RelationshipSubtype.SPOUSE;
  const getInputError = (error?: FieldError) => {
    return (
      !!error && (
        <Box data-qa={`error-${error.ref?.name}`} sx={{ width: 1 }}>
          <Typography role="alert" sx={{ color: 'error.main' }} variant="caption">
            {getErrorMessage(error.type, content.validationMessages)}
          </Typography>
        </Box>
      )
    );
  };
  const { questions } = content;

  useEffect(() => {
    if (regulatoryData) {
      setHasSpfConnection(regulatoryData.hasSpfConnection ?? false);
      setIsPhcSeniorOfficer(regulatoryData.isPhcSeniorOfficer ?? false);
      setIsExchangeEmployed(regulatoryData.isExchangeEmployed ?? false);
    }
  }, [regulatoryData]);

  useEffect(() => {
    if (regulatoryData && employmentData) {
      if (employmentData.organizationName === regulatoryData.exchangeEmployerName) {
        setIsSameEmployer(true);
      } else {
        setIsSameEmployer(false);
      }
    } else {
      setIsSameEmployer(false);
    }
  }, [employmentData, regulatoryData]);

  const onCheckboxAnswerChange = (dataPointKey: string, answer: boolean) => {
    switch (dataPointKey) {
      case spfCheckboxFieldName:
        setHasSpfConnection(answer);
        break;
      case phcCheckboxFieldName:
        setIsPhcSeniorOfficer(answer);
        break;
      case exchangeEmployedCheckboxFieldName:
        setIsExchangeEmployed(answer);
        break;
      default:
        break;
    }
  };

  const onRadioAnswerChange = (answer: string) => {
    switch (answer) {
      case 'data_point:regulatory_same_as_employer:single_option_no':
        setIsSameEmployer(false);
        break;
      case 'data_point:regulatory_same_as_employer:single_option_yes':
        setIsSameEmployer(true);
        break;
      default:
        break;
    }
  };

  const regulatoryLabelContent = questions.find(q => q.key === `${questionKey}_label`);
  const spfCheckboxContent = questions.find(q => q.key === `${questionKey}_senior_political_figure`);
  const spfNameContent = questions.find(q => q.key === `${questionKey}_senior_political_figure_name`);
  const spfRelationshipContent = questions.find(q => q.key === `${questionKey}_senior_political_figure_relationship`);
  const spfTitleContent = questions.find(q => q.key === `${questionKey}_senior_political_figure_title`);
  const spfCountryContent = questions.find(q => q.key === `${questionKey}_senior_political_figure_country`);
  const phcCheckboxContent = questions.find(q => q.key === `${questionKey}_policy_making_officer`);
  const phcNameContent = questions.find(q => q.key === `${questionKey}_policy_making_officer_company_name`);
  const phcTickerContent = questions.find(q => q.key === `${questionKey}_policy_making_officer_company_ticker`);

  const exchangeEmployedCheckboxContent = questions.find(
    q => q.key === `${questionKey}_employed_by_regulatory_authority`,
  );
  const sameAsEmployerContent = questions.find(q => q.key === `${questionKey}_same_as_employer`);
  const exchangeCompanyContent = questions.find(q => q.key === `${questionKey}_exchange_company_name`);
  const exchangeAffiliationTypeContent = questions.find(q => q.key === `${questionKey}_exchange_affiliation_type`);
  const exchangeStreetAddressContent = questions.find(q => q.key === `${questionKey}_exchange_company_street_address`);
  const exchangeStreetLineContent = questions.find(q => q.key === `${questionKey}_exchange_company_street_line_2`);
  const exchangeCityContent = questions.find(q => q.key === `${questionKey}_exchange_company_city`);
  const exchangeStateContent = questions.find(q => q.key === `${questionKey}_exchange_company_state`);
  const exchangeZipCodeContent = questions.find(q => q.key === `${questionKey}_exchange_company_zip_code`);
  const exchangeCompanyAffiliationLetterContent = questions.find(
    q => q.key === `${questionKey}_exchange_company_affiliation_letter`,
  );
  const exchangeCompanyAffiliationLetterInfoContent = questions.find(
    q => q.key === `${questionKey}_exchange_company_affiliation_letter_info`,
  );
  const phcRelationshipContent = questions.find(q => q.key === `${questionKey}_policy_making_officer_relationship`);
  const phcControlTypeContent = questions.find(q => q.key === `${questionKey}_policy_making_officer_control_type`);
  const exchangeRelationshipContent = questions.find(q => q.key === `${questionKey}_exchange_relationship`);
  const accreditedInvestorCheckboxContent = questions.find(q => q.key === `${questionKey}_accredited_investor`);

  const nonAccountHoldingBrokerDealerCheckboxContent = questions.find(
    q => q.key === `${questionKey}_non_account_holding_broker_dealer`,
  );
  const accountHoldingBrokerDealerCheckboxContent = questions.find(
    q => q.key === `${questionKey}_account_holding_broker_dealer`,
  );
  const registeredInvestmentAdvisorCheckboxContent = questions.find(
    q => q.key === `${questionKey}_registered_investment_advisor`,
  );

  if (
    [
      regulatoryLabelContent,
      spfCheckboxContent,
      spfNameContent,
      spfRelationshipContent,
      spfTitleContent,
      spfCountryContent,
      phcCheckboxContent,
      phcNameContent,
      phcTickerContent,
      exchangeEmployedCheckboxContent,
      sameAsEmployerContent,
      exchangeCompanyContent,
      exchangeAffiliationTypeContent,
      exchangeStreetAddressContent,
      exchangeStreetLineContent,
      exchangeCityContent,
      exchangeStateContent,
      exchangeZipCodeContent,
      accreditedInvestorCheckboxContent,
      exchangeRelationshipContent,
      phcRelationshipContent,
    ].includes(undefined)
  ) {
    return <Alert severity="error">Content not found for some of the question(s) in: "{questionKey}" in CMS.</Alert>;
  }

  // Custom logic to determine the display of different questions to checkbox states
  const isEmployed = useMemo(() => {
    if (employmentDetails && employmentDetails.paperworkId === paperworkId) {
      return [EmploymentStatus.EMPLOYED, EmploymentStatus.SELF_EMPLOYED].includes(
        employmentDetails.employmentStatus as EmploymentStatus,
      );
    } else {
      return employmentData?.organizationName ?? false;
    }
  }, [employmentDetails, employmentData?.organizationName, paperworkId]);
  const hideSpfFields = !hasSpfConnection;
  const hidePhcFields = !isPhcSeniorOffice;
  const showSameAsEmployerField = useMemo(() => {
    return (
      isExchangeEmployed && isEmployed && watchExchangeRelationshipType === RegulatoryRelationship.SELF_OR_ACCOUNT_OWNER
    );
  }, [watchExchangeRelationshipType, isExchangeEmployed, isEmployed]);
  const showExchangeEmployerInformation = useMemo(() => {
    return (
      watchExchangeRelationshipType === RegulatoryRelationship.RELATED_PERSON ||
      (isExchangeEmployed && isSameEmployer === false) ||
      (isExchangeEmployed && !isEmployed)
    );
  }, [watchExchangeRelationshipType, isExchangeEmployed, isSameEmployer, isEmployed]);

  useEffect(() => {
    if (setValue && showSameAsEmployerField) {
      setValue(
        sameAsEmployerFieldName,
        isSameEmployer
          ? 'data_point:regulatory_same_as_employer:single_option_yes'
          : 'data_point:regulatory_same_as_employer:single_option_no',
      );
    }
  }, [isSameEmployer, setValue, showSameAsEmployerField]);

  const resetCompanyFields = () => {
    setValue(exchangeCompanyNameFieldName, '');
    setValue(exchangeAffiliationTypeFieldName, '');
    setValue(exchangeCompanyStreetAddressFieldName, '');
    setValue(exchangeCompanyStreetLineFieldName, '');
    setValue(exchangeCompanyCityFieldName, '');
    setValue(exchangeCompanyStateFieldName, '');
    setValue(exchangeCompanyZipCodeFieldName, '');
  };

  const getTickerController = () => (
    <Box sx={{ display: hidePhcFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
      <Controller
        control={control}
        defaultValue={tickerOption?.ticker}
        name={phcTickerFieldName}
        render={({ name, onChange, ref, value, ...params }) =>
          showTickerSearch ? (
            <TickerSearch
              {...params}
              ariaLabelledBy="Ticker Search"
              content={{
                label: phcTickerContent?.question,
                noOptionsText: content.tickerSearchNoOptionsText,
                placeholder: content.tickerSearchPlaceholder,
              }}
              customOptions={[
                {
                  isDivider: true,
                  label: content.tickerSearchAddTickerManuallyCta,
                  name: content.tickerSearchAddTickerManuallyCta,
                  ticker: '',
                },
              ]}
              error={!!fieldsErrors[phcTickerFieldName]}
              onValueChange={(_e, newValue, reason) => {
                if (reason === 'clear') {
                  setTickerOption(null);
                  onChange(null);
                  return;
                } else if (newValue?.name === content.tickerSearchAddTickerManuallyCta) {
                  setShowTickerSearch(false);
                } else {
                  if (autoFillCompanyNameFromTicker) {
                    setValue?.(phcNameFieldName, newValue?.name);
                  }
                  setTickerOption(newValue);
                  onChange(newValue?.ticker);
                }
              }}
              sx={{ width: '100%' }}
              tickerSearchValue={tickerOption}
            />
          ) : (
            <TextField
              error={!!fieldsErrors[phcTickerFieldName]}
              fullWidth
              inputRef={ref}
              label={phcTickerContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (phcTickerContent?.character_limit && inputValue.length > phcTickerContent.character_limit) {
                  e.preventDefault();
                  return;
                }
                e.target.value = inputValue.toUpperCase(); // NFS has validation, and it considers only [A-Z0-9] pattern as valid
                onChange(e);
              }}
              value={value.trimStart()}
            />
          )
        }
        rules={
          hidePhcFields
            ? {}
            : {
                required: true,
                validate: {
                  isValidTicker: value => (value ? isValidTicker(value) : true),
                },
              }
        }
      />
      {getInputError(fieldsErrors[phcTickerFieldName])}
    </Box>
  );

  const getCompanyController = () => (
    <Box sx={{ display: hidePhcFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
      <Controller
        control={control}
        defaultValue={regulatoryData?.publiclyHeldCompanyName ?? ''}
        name={phcNameFieldName}
        render={({ onChange, value, name, ref }) => (
          <TextField
            disabled={!!autoFillCompanyNameFromTicker}
            error={!!fieldsErrors[phcNameFieldName]}
            fullWidth
            inputRef={ref}
            label={phcNameContent?.question}
            name={name}
            onChange={e => {
              const { value: inputValue } = e.target;
              if (!isInputLengthValid(inputValue, phcNameContent?.character_limit)) {
                e.preventDefault();
                return;
              }
              onChange(e);
            }}
            type="text"
            value={value.trimStart()}
          />
        )}
        rules={
          hidePhcFields
            ? {}
            : {
                required: !autoFillCompanyNameFromTicker,
                validate: {
                  required: value => !!value.trim(),
                },
              }
        }
      />
      {getInputError(fieldsErrors[phcNameFieldName])}
    </Box>
  );

  const getCheckBoxController = (defaultValue: boolean, fieldName: string, questionContent?: string) => (
    <Box sx={{ mt: 1 }}>
      <Controller
        control={control}
        defaultValue={defaultValue}
        key={`question-${fieldName}`}
        name={fieldName}
        render={({ onChange }) => (
          <CheckboxGroup
            dataQa={`question-checkbox-${questionKey}`}
            error={!!fieldsErrors[fieldName]}
            items={[
              {
                name: fieldName,
                label: <RteContent component="span" data={questionContent ?? ''} />,
                defaultChecked: defaultValue,
              },
            ]}
            onChange={e => {
              onChange(e);
            }}
          />
        )}
      />
      {getInputError(fieldsErrors[fieldName])}
    </Box>
  );

  return (
    <Box data-qa={dataQa}>
      <Box sx={{ display: 'flex' }}>
        <Typography component="h3" sx={{ mr: 1 }} variant="subtitle1">
          {regulatoryLabelContent?.question}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', my: 2, pt: 1, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.hasSpfConnection ?? false}
          key={`question-${spfCheckboxFieldName}`}
          name={spfCheckboxFieldName}
          render={({ onChange }) => (
            <CheckboxGroup
              dataQa={`question-checkbox-${questionKey}`}
              error={!!fieldsErrors[spfCheckboxFieldName]}
              items={[
                {
                  name: spfCheckboxFieldName,
                  label: <RteContent component="span" data={spfCheckboxContent?.question ?? ''} />,
                  defaultChecked: regulatoryData?.hasSpfConnection ?? false,
                },
              ]}
              onChange={e => {
                onCheckboxAnswerChange(spfCheckboxFieldName, e[spfCheckboxFieldName]);
                onChange(e);
              }}
            />
          )}
        />
        {getInputError(fieldsErrors[spfCheckboxFieldName])}
      </Box>
      <Box sx={{ display: hideSpfFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.nameOfSeniorPoliticalFigure ?? ''}
          name={spfNameFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldsErrors[spfNameFieldName]}
              fullWidth
              inputRef={ref}
              label={spfNameContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (spfNameContent?.character_limit && inputValue.length > spfNameContent.character_limit) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              type="text"
              value={value.trimStart()}
            />
          )}
          rules={
            hideSpfFields
              ? {}
              : {
                  required: true,
                  validate: {
                    required: value => !!value.trim(),
                  },
                }
          }
        />
        {getInputError(fieldsErrors[spfNameFieldName])}
      </Box>
      <Box sx={{ display: hideSpfFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.relationship ?? ''}
          name={spfRelationshipFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${spfRelationshipFieldName}`}
              error={!!fieldsErrors[spfRelationshipFieldName]}
              inputRef={ref}
              items={spfRelationshipContent?.options ?? []}
              label={spfRelationshipContent?.question}
              onChange={e => onChange(e.target.value)}
              value={value}
              width="100%"
            />
          )}
          rules={
            hideSpfFields
              ? {}
              : {
                  required: true,
                  validate: {
                    spouseNotAllowed: value => !checkMaritalValue(value),
                    maritalStatusNotSelected: () => !(!maritalStatus && checkMaritalStatus),
                  },
                }
          }
        />
        {getInputError(fieldsErrors[spfRelationshipFieldName])}
      </Box>
      <Box sx={{ display: hideSpfFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.politicalTitle ?? ''}
          name={spfTitleFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldsErrors[spfTitleFieldName]}
              fullWidth
              inputRef={ref}
              label={spfTitleContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (spfTitleContent?.character_limit && inputValue.length > spfTitleContent.character_limit) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              type="text"
              value={value.trimStart()}
            />
          )}
          rules={
            hideSpfFields
              ? {}
              : {
                  required: true,
                  validate: {
                    required: value => !!value.trim(),
                  },
                }
          }
        />
        {getInputError(fieldsErrors[spfTitleFieldName])}
      </Box>
      <Box sx={{ display: hideSpfFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.countryOfOffice ?? ''}
          name={spfCountryFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${spfCountryFieldName}`}
              error={!!fieldsErrors[spfCountryFieldName]}
              inputRef={ref}
              items={getDropdownItems(CmsKeys.Countries, dropdownsContent, spfCountryContent as QuestionContent)}
              label={spfCountryContent?.question}
              onChange={e => onChange(e.target.value)}
              value={value}
              width="100%"
            />
          )}
          rules={hideSpfFields ? {} : { required: true }}
        />
        {getInputError(fieldsErrors[spfCountryFieldName])}
      </Box>
      <Box sx={{ display: 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.isPhcSeniorOfficer ?? false}
          key={`question-${phcCheckboxFieldName}`}
          name={phcCheckboxFieldName}
          render={({ onChange }) => (
            <CheckboxGroup
              dataQa={`${dataQa}-phc-checkbox`}
              error={!!fieldsErrors[phcCheckboxFieldName]}
              items={[
                {
                  name: phcCheckboxFieldName,
                  label: <RteContent component="span" data={phcCheckboxContent?.question ?? ''} />,
                  defaultChecked: regulatoryData?.isPhcSeniorOfficer ?? false,
                },
              ]}
              onChange={e => {
                onCheckboxAnswerChange(phcCheckboxFieldName, e[phcCheckboxFieldName]);
                onChange(e);
              }}
            />
          )}
        />
        {getInputError(fieldsErrors[phcCheckboxFieldName])}
      </Box>
      <Box
        sx={{
          display: hidePhcFields ? 'none' : 'flex',
          my: 2,
          flex: '1 0',
          flexWrap: 'wrap',
        }}
      >
        <Controller
          control={control}
          defaultValue={regulatoryData?.phcRelationship ?? null}
          name={phcRelationshipFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${phcRelationshipFieldName}`}
              error={!!fieldsErrors[phcRelationshipFieldName]}
              inputRef={ref}
              items={phcRelationshipContent?.options ?? []}
              label={phcRelationshipContent?.question}
              onChange={e => onChange(e.target.value)}
              value={value}
              width="100%"
            />
          )}
          rules={hidePhcFields ? {} : { required: true }}
        />
        {getInputError(fieldsErrors[phcRelationshipFieldName])}
      </Box>
      <Box sx={{ display: hidePhcFields ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.controlType ?? ''}
          name={phcControlTypeFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${phcControlTypeFieldName}`}
              error={!!fieldsErrors[phcControlTypeFieldName]}
              inputRef={ref}
              items={phcControlTypeContent?.options ?? []}
              label={phcControlTypeContent?.question}
              onChange={e => onChange(e.target.value)}
              value={value}
              width="100%"
            />
          )}
          rules={hidePhcFields ? {} : { required: true }}
        />
        {getInputError(fieldsErrors[phcControlTypeFieldName])}
      </Box>
      {autoFillCompanyNameFromTicker ? (
        <>
          {getTickerController()} {getCompanyController()}
        </>
      ) : (
        <>
          {getCompanyController()} {getTickerController()}
        </>
      )}
      <Box sx={{ display: 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.isExchangeEmployed ?? false}
          key={`question-${exchangeEmployedCheckboxFieldName}`}
          name={exchangeEmployedCheckboxFieldName}
          render={({ onChange }) => (
            <CheckboxGroup
              dataQa={`${dataQa}-exchange-checkbox`}
              error={!!fieldsErrors[exchangeEmployedCheckboxFieldName]}
              items={[
                {
                  name: exchangeEmployedCheckboxFieldName,
                  label: <RteContent component="span" data={exchangeEmployedCheckboxContent?.question ?? ''} />,
                  defaultChecked: regulatoryData?.isExchangeEmployed ?? false,
                },
              ]}
              onChange={e => {
                onCheckboxAnswerChange(exchangeEmployedCheckboxFieldName, e[exchangeEmployedCheckboxFieldName]);
                onChange(e);
              }}
            />
          )}
        />
        {getInputError(fieldsErrors[exchangeEmployedCheckboxFieldName])}
      </Box>
      <Box
        sx={{
          display: isExchangeEmployed ? 'flex' : 'none',
          my: 2,
          flex: '1 0',
          flexWrap: 'wrap',
        }}
      >
        <Controller
          control={control}
          defaultValue={regulatoryData?.finraRelationship ?? null}
          name={exchangeRelationshipFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${exchangeRelationshipFieldName}`}
              error={!!fieldsErrors[exchangeRelationshipFieldName]}
              inputRef={ref}
              items={exchangeRelationshipContent?.options ?? []}
              label={exchangeRelationshipContent?.question}
              onChange={e => {
                onChange(e.target.value);
                resetCompanyFields();
              }}
              value={value}
              width="100%"
            />
          )}
          rules={isExchangeEmployed ? { required: true } : {}}
        />
        {getInputError(fieldsErrors[exchangeRelationshipFieldName])}
      </Box>
      {showSameAsEmployerField && (
        <Box sx={{ display: 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
          <Controller
            control={control}
            defaultValue={null}
            key={`question-${sameAsEmployerFieldName}`}
            name={sameAsEmployerFieldName}
            render={({ onChange, value }) => (
              <RadioGroup
                dataQa={`${dataQa}-same-as-employer`}
                error={!!fieldsErrors[sameAsEmployerFieldName]}
                items={sameAsEmployerContent?.options ?? []}
                legend={sameAsEmployerContent?.question}
                name={sameAsEmployerFieldName}
                onChange={e => {
                  onRadioAnswerChange(e);
                  onChange(e);
                  resetCompanyFields();
                }}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
        </Box>
      )}
      <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.exchangeEmployerName ?? ''}
          name={exchangeCompanyNameFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldsErrors[exchangeCompanyNameFieldName]}
              fullWidth
              inputRef={ref}
              label={exchangeCompanyContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (!isCompanyNameInputChangeAllowed(inputValue, exchangeCompanyContent?.character_limit)) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              value={value.trimStart()}
            />
          )}
          rules={
            !showExchangeEmployerInformation
              ? {}
              : {
                  required: true,
                  validate: {
                    required: value => !!value.trim(),
                  },
                }
          }
        />
        {getInputError(fieldsErrors[exchangeCompanyNameFieldName])}
      </Box>
      <Box sx={{ display: !isExchangeEmployed ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.exchangeAffiliationType ?? ''}
          name={exchangeAffiliationTypeFieldName}
          render={({ onChange, value, ref }) => (
            <Dropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: style.maxDropdownHeight,
                  },
                },
              }}
              dataQa={`dropdown-${exchangeAffiliationTypeFieldName}`}
              error={!!fieldsErrors[exchangeAffiliationTypeFieldName]}
              inputRef={ref}
              items={exchangeAffiliationTypeContent?.options ?? []}
              label={exchangeAffiliationTypeContent?.question}
              onChange={e => onChange(e.target.value)}
              value={value}
              width="100%"
            />
          )}
          rules={isExchangeEmployed ? { required: true } : {}}
        />
        {getInputError(fieldsErrors[exchangeAffiliationTypeFieldName])}
      </Box>
      <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.exchangeAddress?.addressLine1 ?? ''}
          name={exchangeCompanyStreetAddressFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldsErrors[exchangeCompanyStreetAddressFieldName]}
              fullWidth
              inputRef={ref}
              label={exchangeStreetAddressContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (
                  exchangeStreetAddressContent?.character_limit &&
                  inputValue.length > exchangeStreetAddressContent.character_limit
                ) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              value={value.trimStart()}
            />
          )}
          rules={
            !showExchangeEmployerInformation
              ? {}
              : {
                  required: true,
                  validate: {
                    required: value => !!value.trim(),
                  },
                }
          }
        />
        {getInputError(fieldsErrors[exchangeCompanyStreetAddressFieldName])}
      </Box>
      <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.exchangeAddress?.addressLine2 ?? ''}
          name={exchangeCompanyStreetLineFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldsErrors[exchangeCompanyStreetLineFieldName]}
              fullWidth
              inputRef={ref}
              label={exchangeStreetLineContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (
                  exchangeStreetLineContent?.character_limit &&
                  inputValue.length > exchangeStreetLineContent.character_limit
                ) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              value={value.trimStart()}
            />
          )}
        />
        {getInputError(fieldsErrors[exchangeCompanyStreetLineFieldName])}
      </Box>
      <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', my: 2, flex: '1 0', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          defaultValue={regulatoryData?.exchangeAddress?.countrySecondarySubdivision ?? ''}
          name={exchangeCompanyCityFieldName}
          render={({ onChange, value, name, ref }) => (
            <TextField
              error={!!fieldsErrors[exchangeCompanyCityFieldName]}
              fullWidth
              inputRef={ref}
              label={exchangeCityContent?.question}
              name={name}
              onChange={e => {
                const { value: inputValue } = e.target;
                if (exchangeCityContent?.character_limit && inputValue.length > exchangeCityContent.character_limit) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
              value={value.trimStart()}
            />
          )}
          rules={
            !showExchangeEmployerInformation
              ? {}
              : {
                  required: true,
                  validate: {
                    required: value => !!value.trim(),
                  },
                }
          }
        />
        {getInputError(fieldsErrors[exchangeCompanyCityFieldName])}
      </Box>
      <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', my: 2 }}>
        <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', flex: '1 0', flexWrap: 'wrap', mr: 1 }}>
          <Controller
            control={control}
            defaultValue={regulatoryData?.exchangeAddress?.countryPrimarySubdivision ?? ''}
            name={exchangeCompanyStateFieldName}
            render={({ onChange, value, ref }) => (
              <Dropdown
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: style.maxDropdownHeight,
                    },
                  },
                }}
                dataQa={`dropdown-${exchangeCompanyStateFieldName}`}
                error={!!fieldsErrors[exchangeCompanyStateFieldName]}
                inputRef={ref}
                items={getDropdownItems(CmsKeys.States, dropdownsContent, exchangeStateContent as QuestionContent)}
                label={exchangeStateContent?.question}
                onChange={e => onChange(e.target.value)}
                value={value}
                width="100%"
              />
            )}
            rules={!showExchangeEmployerInformation ? {} : { required: true }}
          />
          {getInputError(fieldsErrors[exchangeCompanyStateFieldName])}
        </Box>
        <Box sx={{ display: !showExchangeEmployerInformation ? 'none' : 'flex', flex: '1 0', flexWrap: 'wrap' }}>
          <Controller
            control={control}
            defaultValue={regulatoryData?.exchangeAddress?.postalCode ?? ''}
            name={exchangeCompanyZipCodeFieldName}
            render={({ onChange, value, name, ref }) => (
              <TextField
                error={!!fieldsErrors[exchangeCompanyZipCodeFieldName]}
                fullWidth
                inputRef={ref}
                label={exchangeZipCodeContent?.question}
                name={name}
                onChange={e => {
                  const { value: inputValue } = e.target;
                  if (isValidNumberInput(inputValue)) {
                    e.preventDefault(); // Prevent Non-Numeric entries
                    return;
                  }
                  if (
                    exchangeZipCodeContent?.character_limit &&
                    inputValue.length > exchangeZipCodeContent.character_limit
                  ) {
                    e.preventDefault();
                    return;
                  }
                  onChange(e);
                }}
                type="text"
                value={value.trimStart()}
              />
            )}
            rules={
              !showExchangeEmployerInformation
                ? {}
                : {
                    required: true,
                    validate: {
                      isLength: value => (value ? isValidLength(value, 5) : true),
                      isValidZipCode: value =>
                        isValidZipCode({
                          linkedState: getValues(exchangeCompanyStateFieldName),
                          zipcode: value.trim(),
                          stateZipCodeMap: stateZipCodeMap ?? [],
                        }),
                    },
                  }
            }
          />
          {getInputError(fieldsErrors[exchangeCompanyZipCodeFieldName])}
        </Box>
      </Box>
      {isAffiliationLetterFieldEnabled && isExchangeEmployed && (
        <>
          <Box sx={{ display: 'flex', mt: 2, pt: 1, pb: 0, flex: '1 0', flexWrap: 'wrap' }}>
            <Controller
              control={control}
              defaultValue={regulatoryData?.hasAffiliationLetter ?? false}
              key={`question-${exchangeCompanyAffiliationLetterFieldName}`}
              name={exchangeCompanyAffiliationLetterFieldName}
              render={({ onChange }) => (
                <CheckboxGroup
                  dataQa={`question-checkbox-${questionKey}`}
                  error={!!fieldsErrors[spfCheckboxFieldName]}
                  items={[
                    {
                      name: exchangeCompanyAffiliationLetterFieldName,
                      label: (
                        <RteContent component="span" data={exchangeCompanyAffiliationLetterContent?.question ?? ''} />
                      ),
                      defaultChecked: regulatoryData?.hasAffiliationLetter ?? false,
                    },
                  ]}
                  onChange={e => {
                    onChange(e);
                  }}
                />
              )}
              rules={{
                validate: {
                  mandatoryAffiliationLetter: value => mandatoryCheckboxValidation(value),
                },
              }}
            />
            {getInputError(fieldsErrors[exchangeCompanyAffiliationLetterFieldName])}
          </Box>
          <Alert data-qa="affiliation-letter-info" severity="info">
            <RteContent data={exchangeCompanyAffiliationLetterInfoContent?.question ?? ''} defaultBodyVariant="body2" />
          </Alert>
        </>
      )}
      {getCheckBoxController(
        regulatoryData?.isAccreditedInvestor ?? false,
        accreditedInvestorCheckboxFieldName,
        accreditedInvestorCheckboxContent?.question,
      )}
      {getCheckBoxController(
        regulatoryData?.isEmployedByAnotherBroker ?? false,
        associatedWithNonAccountHoldingBrokerDealerCheckboxFieldName,
        nonAccountHoldingBrokerDealerCheckboxContent?.question,
      )}
      {getCheckBoxController(
        regulatoryData?.isEmployedByAccountBroker ?? false,
        associatedWithAccountHoldingBrokerDealerCheckboxFieldName,
        accountHoldingBrokerDealerCheckboxContent?.question,
      )}
      {getCheckBoxController(
        regulatoryData?.isAssociatedWithUsRegisteredInvestmentAdvisor ?? false,
        associatedWithRegisteredInvestmentAdvisorCheckboxFieldName,
        registeredInvestmentAdvisorCheckboxContent?.question,
      )}
    </Box>
  );
};
