import { useMemo } from 'react';

import { AccountProfileUpdateState } from '..';
import { EntityUpdateWorkflow, PartyPerson, useGetAccountProfileManagedProduct } from '../symphony';
import { getTemporaryPaperworkPartyPersonObject } from '../utils';

import { UpdateWorkflowStatus } from '~/__generated__';
import { ManagedProductPaperwork } from '~/containers/Paperwork/symphony';
import { AsyncResult, isCustodialAccountType } from '~/utils';

interface Data {
  entityUpdateWorkflowId?: string;
  isSignedAndNotCompleted?: boolean;
  isSigningRequired?: boolean;
  paperworkList?: ManagedProductPaperwork[] | null;
  partyIdForSigningDocuments: string;
}

interface Variables {
  currentStep: AccountProfileUpdateState;
  managedProductId: string;
  partyId: string;
}

interface Response extends AsyncResult<Data> {
  refetch: () => void;
}
/**
 * Account Profile Managed Product has 'temporaryPaperworkList' and 'paperwork'
 * After account profile is being saved in temporaryPaperworkList, We don't have the below information
 * 1. givenName, 2. familyName, 3. isUpdatingBeneficiariesAllowed
 * So we are mapping these 3 missing information to 'temporaryPaperworkList' from 'paperwork'
 * by matching the [paperwork].id and [temporaryPaperworkList].originalPaperworkId
 * @param param.managedProductId - Managed product id of the account
 * @param param.partyId - Party Id of the client
 * @returns {Data}
 */
export const useGetEntityUpdateWorkflowData = ({ managedProductId, partyId, currentStep }: Variables): Response => {
  const {
    data: managedProductData,
    loading: managedProductLoading,
    error: managedProductError,
    refetch: refetchManagedProduct,
  } = useGetAccountProfileManagedProduct({
    skip: currentStep !== AccountProfileUpdateState.EDIT_ACCOUNT_PROFILE,
    variables: {
      managedProductId,
      partyId,
      statuses: [UpdateWorkflowStatus.DOCS_PREPARED, UpdateWorkflowStatus.DOCS_SIGNED],
    },
    fetchPolicy: 'no-cache', // Required to fetch the managed product again while redirecting from account summary to account profile page after docusign completed or discard changes.
  });
  const data = useMemo(() => {
    // TODO: ANR-8783 - need change in backend with type name to fetch account profile workflow instead of [0]
    const entityUpdateWorkflowData: EntityUpdateWorkflow | undefined =
      managedProductData?.managedProduct?.entityUpdateWorkflow?.[0];
    const managedProductPaperworkData = managedProductData?.managedProduct?.paperwork;

    if (
      !managedProductLoading &&
      managedProductPaperworkData &&
      entityUpdateWorkflowData?.temporaryPaperworkList?.every(p => p.paperwork.party)
    ) {
      const partyPersonList: {
        familyName: string;
        givenName: string;
        isUpdatingBeneficiariesAllowed: boolean;
        paperworkId: string;
      }[] = managedProductPaperworkData.map(paperworkData => {
        return {
          familyName: paperworkData.party?.partyPerson?.familyName ?? '',
          givenName: paperworkData.party?.partyPerson?.givenName ?? '',
          isUpdatingBeneficiariesAllowed: paperworkData.isUpdatingBeneficiariesAllowed ?? false,
          paperworkId: paperworkData.id ?? '',
        };
      });

      const paperworkList = entityUpdateWorkflowData.temporaryPaperworkList.map(paperworkListItem => {
        const partyPerson = partyPersonList.find(
          person => person.paperworkId === paperworkListItem.originalPaperworkId,
        );
        const temporaryPartyPerson = paperworkListItem.paperwork.party?.partyPerson;

        if (paperworkListItem.paperwork.party) {
          const paperworkPartyPerson: PartyPerson =
            temporaryPartyPerson ??
            getTemporaryPaperworkPartyPersonObject({
              familyName: partyPerson?.familyName,
              givenName: partyPerson?.givenName,
            });

          return Object.assign(
            paperworkListItem.paperwork,
            { isUpdatingBeneficiariesAllowed: partyPerson?.isUpdatingBeneficiariesAllowed ?? false },
            { party: Object.assign(paperworkListItem.paperwork.party, { partyPerson: paperworkPartyPerson }) },
          );
        }
        return paperworkListItem.paperwork;
      });

      return {
        partyIdForSigningDocuments: isCustodialAccountType(managedProductData.managedProduct?.accountType)
          ? managedProductData.managedProduct?.relatedPartiesV2?.find(item => !item.isInitialParty)?.partyId ?? partyId
          : partyId,
        entityUpdateWorkflowId: entityUpdateWorkflowData.id ?? '',
        isSignedAndNotCompleted: entityUpdateWorkflowData.status === UpdateWorkflowStatus.DOCS_SIGNED,
        isSigningRequired: entityUpdateWorkflowData.isSigningRequired,
        paperworkList,
      };
    }
    return undefined;
  }, [
    currentStep,
    partyId,
    managedProductData?.managedProduct?.accountType,
    managedProductData?.managedProduct?.relatedPartiesV2,
    managedProductData?.managedProduct?.entityUpdateWorkflow,
    managedProductData?.managedProduct?.paperwork,
    managedProductLoading,
  ]);

  return {
    data,
    loading: managedProductLoading,
    error: managedProductError,
    refetch: refetchManagedProduct,
  };
};
