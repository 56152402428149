import { ContentDataSection } from '../contentstack';

import { getDefaultValue } from '~/containers/Paperwork/Section/Questions/utils';
import { GetPaperwork_managedProduct_paperwork } from '~/containers/Paperwork/symphony/__generated__/GetPaperwork';
import { PaperworkSection } from '~/containers/Paperwork/types';

// TODO: ANR-8615 - Section heading should be a Rte Content, Changing to RTE content will affect Paperwork also, right now this ticket is not in scope for this change
/**
 * @param {ContentDataSection[] | null | undefined} getSectionHeading.contentDataSection - content data of the section to find the section heading from content stack
 * @param {PaperworkSection} getSectionHeading.section - section to check for titleKeySymphonyMapping and map the data to config
 * @param {GetPaperwork_managedProduct_paperwork | undefined} getSectionHeading.symphonyData - corresponding symphonyData of the section from getPaperwork to map titleKey data
 * @returns {string} - We return a string of Section Heading along with the titleKeySymphonyMapping data
 * e.g. if titleKeySymphonyMapping: party.partyPerson.givenName -> "Client Name"
 * and content data retuns "Details"
 * this function will return "Client Name's Details"
 */
export const getSectionHeading = ({
  contentDataSection,
  section,
  symphonyData,
}: {
  contentDataSection: ContentDataSection[] | null | undefined;
  section: PaperworkSection;
  symphonyData: GetPaperwork_managedProduct_paperwork | undefined;
}): string => {
  let data = '';
  if (section.titleKeySymphonyMapKeys && symphonyData) {
    section.titleKeySymphonyMapKeys.forEach(key => {
      data = `${data} ${getDefaultValue(symphonyData, { symphonyMapping: key })}`;
    });
  }
  const title = contentDataSection?.find(s => {
    const sectionHeadingPrefix = section.titleKey?.split(':')?.[0];
    return s?.key === sectionHeadingPrefix;
  })?.title;

  return `${data ? `${data}'s ` : ''}${title}`;
};
