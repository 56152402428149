import React, { FC } from 'react';

import { QuestionContent } from '../Section/Questions/types';

import { Box, Button, RteContent, useModalState } from '~/components';
import { InvestmentRestrictions as InvestmentRestrictionsModal } from '~/components/modals/InvestmentRestrictions';
import { ContentOptions } from '~/utils';

export interface Props {
  content: { questions: QuestionContent[] };
  contentOptions: ContentOptions;
  dataQa?: string;
  managedProductId: string;
  partyId: string;
  questionKey: string;
}

export const InvestmentRestrictions: FC<Props> = ({
  content,
  contentOptions,
  dataQa = 'investment-restrictions',
  managedProductId,
  partyId,
  questionKey,
}) => {
  const { open, openModal, onClose } = useModalState();
  const { questions } = content;
  const investmentRestrictionsContent = questions.find(q => q.key === questionKey)?.question ?? '';
  const investmentRestrictionsLabelContent = questions.find(q => q.key === `${questionKey}_label`)?.question ?? '';
  const investmentRestrictionsModalLinkContent =
    questions.find(q => q.key === `${questionKey}_modal_link`)?.question ?? '';
  return (
    <Box data-qa={dataQa}>
      <RteContent data={investmentRestrictionsContent} sx={{ pb: 2 }} />
      <RteContent data={investmentRestrictionsLabelContent} sx={{ pb: 2 }} />
      <Button aria-haspopup="true" onClick={openModal} variant="outlined">
        <RteContent data={investmentRestrictionsModalLinkContent} />
      </Button>
      <InvestmentRestrictionsModal
        contentOptions={contentOptions}
        managedProductId={managedProductId}
        onClose={onClose}
        open={open}
        partyId={partyId}
      />
    </Box>
  );
};
