import { DataMappingsByDataPointKey } from '../Section/Questions/types';

import { isMasked } from './helper';

import {
  AccountHolderType,
  AddressInput,
  AddressType,
  BusinessEntityAttributesInput,
  BusinessEntityInput,
  BusinessEntityRoleType,
  CompanyInput,
  ContactType,
  EmploymentStatus,
  IdentifierType,
  MaritalStatus,
  PartyContactInput,
  PartyIdentifierInput,
  PartyInput,
  PartyPersonInput,
  PartyPersonLanguageInput,
  TaxStatus,
} from '~/__generated__';
import { isoDateFormat } from '~/utils';

const getUpdatedPartyBusinessEntity = (
  symphonyMappingSplit: string[],
  key: string,
  partyBusinessEntity: BusinessEntityInput,
  result: Record<string, any>,
) => {
  if (result[key]) {
    switch (symphonyMappingSplit[2]) {
      case 'attributes':
        if (!partyBusinessEntity.attributes) {
          partyBusinessEntity.attributes = {};
        }
        const attributeNameSplit = symphonyMappingSplit[3].split(':');
        switch (attributeNameSplit[0]) {
          // accountHolderType is only saved from accountHolder section other pages should not update its value
          case 'countryOfTaxation':
            partyBusinessEntity.attributes.countryOfTaxation = result[key] as string;
            break;
          case 'organizationDocumentName':
            partyBusinessEntity.attributes.organizationDocumentName = result[key] as string;
            break;
          case 'taxation':
            partyBusinessEntity.attributes.taxation = result[key] as string;
            break;
          case 'resolutionDateAdopted':
            partyBusinessEntity.attributes.resolutionDateAdopted = isoDateFormat(result[key]);
            break;
          case 'trustDate':
            partyBusinessEntity.attributes.trustDate = isoDateFormat(result[key]);
            break;
          case 'roleType':
            partyBusinessEntity.attributes.roleType = partyBusinessEntity.attributes.roleType?.length
              ? [...partyBusinessEntity.attributes.roleType, attributeNameSplit[1] as BusinessEntityRoleType]
              : [attributeNameSplit[1] as BusinessEntityRoleType];
            break;
        }
        break;
      default:
        partyBusinessEntity[symphonyMappingSplit[2] as keyof BusinessEntityInput] = result[key] as string;
        break;
    }
  }

  return partyBusinessEntity;
};

const getUpdatedPartyPerson = (
  symphonyMappingSplit: string[],
  key: string,
  partyPerson: PartyPersonInput,
  result: Record<string, any>,
  hiddenDataPoints: Record<string, any>,
) => {
  if (result[key]) {
    switch (symphonyMappingSplit[2]) {
      case 'givenName':
        partyPerson.givenName = result[key] as string;
        break;
      case 'middleName':
        partyPerson.middleName = result[key] as string;
        break;
      case 'familyName':
        partyPerson.familyName = result[key] as string;
        break;
      case 'birthDate':
        partyPerson.birthDate = isoDateFormat(result[key]);
        break;
      case 'deathDate':
        partyPerson.deathDate = isoDateFormat(result[key]);
        break;
      case 'residentType':
        const residentType = parseInt(result[key], 10);
        partyPerson.residentType = residentType;
        // For U.S. Citizen, we don't show the citizenship dropdown, which is why we need the following check
        if (residentType === 1) {
          // TODO: Refactor this such that 999 is not hardcoded and can come from CMS/Symphony
          partyPerson.citizenship = '999';
        }
        break;
      case 'citizenship':
        partyPerson.citizenship = result[key] as string;
        break;
      case 'maritalStatus':
        partyPerson.maritalStatus = result[key] as MaritalStatus;
        break;
      case 'employmentStatus':
        partyPerson.employmentStatus = result[key] as EmploymentStatus;
        break;
      case 'occupation':
        partyPerson.occupation = result[key] as string;
        break;
      case 'language':
        if (!partyPerson.language) {
          partyPerson.language = {};
        }
        partyPerson.language[symphonyMappingSplit[3] as keyof PartyPersonLanguageInput] = result[key];
        break;
      case 'taxStatus':
        partyPerson.taxStatus = hiddenDataPoints[key] ? null : (result[key] as TaxStatus);
        break;
      default:
        break;
    }
  }

  return partyPerson;
};

const getUpdatedAddresses = (
  objKey: string,
  key: string,
  addresses: AddressInput[],
  result: Record<string, any>,
  hiddenDataPoints: Record<string, any>,
) => {
  if (!hiddenDataPoints[key]) {
    const fieldNameTypeSplit = objKey.split(':');
    const fieldName = fieldNameTypeSplit[0];
    const addressType = fieldNameTypeSplit[1];

    // Checkbox values are nested within a checkbox group, so we have to access the values of checkbox through getting
    // the checkbox group, then getting the value from the checkbox group
    const differentMailingAddressCheckboxGroup = result?.['data_point:different_mailing_address:boolean'];
    const isDifferentMailingAddressChecked =
      typeof differentMailingAddressCheckboxGroup === 'object'
        ? differentMailingAddressCheckboxGroup?.['data_point:different_mailing_address:boolean'] === true
        : differentMailingAddressCheckboxGroup === true;
    let addressIndex = 0;
    const selectedEmploymentStatus = result?.['data_point:employment_status:single_option'];
    const hasOfficeAddress = ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED'].includes(selectedEmploymentStatus);
    const address = addresses.find(a => a.type === (addressType.toUpperCase() as AddressType));
    // no address will be shared if address type is office and the person is not employed
    if (!((addressType.toUpperCase() as AddressType) === AddressType.OFFICE && !hasOfficeAddress)) {
      if (!address) {
        addresses.push({ type: addressType.toUpperCase() as AddressType });
        addressIndex = addresses.length - 1;
      } else {
        addressIndex = addresses.indexOf(address);
      }

      switch (fieldName) {
        case 'type':
          break;
        default:
          addresses[addressIndex][fieldName as keyof AddressInput] = result[key];
          break;
      }

      // If isDifferentMailing is not checked, we want to use the home address for the mailing address
      // first find the index of mailing within the addresses array, then spread the home address into the mailing address
      if (
        // This case adds MAILING type into addresses whilst creating HOME Address in case MAILING is not submitted from FE
        !isDifferentMailingAddressChecked &&
        (addressType.toUpperCase() === AddressType.MAILING || addressType.toUpperCase() === AddressType.HOME)
      ) {
        const mailingAddressIndex = addresses?.findIndex(add => add.type === AddressType.MAILING);
        if (addresses) {
          // if MAILING Type object wasn't found, create a MAILING type object with HOME address values
          if (mailingAddressIndex === -1) {
            addresses.push({
              ...addresses?.find(add => add.type === AddressType.HOME),
              type: AddressType.MAILING,
            });
          } else {
            addresses[mailingAddressIndex] = {
              ...addresses?.find(add => add.type === AddressType.HOME),
              type: AddressType.MAILING,
            };
          }
        }
      }
    }
  }
  return addresses;
};

const getUpdatedIdentifiers = (
  objKey: string,
  key: string,
  identifiers: PartyIdentifierInput[],
  result: Record<string, any>,
) => {
  const fieldNameTypeSplit = objKey.split(':');
  const fieldName = fieldNameTypeSplit[0];
  const identifierType = fieldNameTypeSplit.length > 1 ? fieldNameTypeSplit[1] : '';

  if (result[key]) {
    if (identifierType === '') {
      identifiers.push({ type: result[key].toUpperCase() as IdentifierType });
    } else if (identifierType) {
      let identifierIndex = 0;
      const identifier = identifiers.find(i => i.type === (identifierType.toUpperCase() as IdentifierType));
      if (!identifier) {
        identifiers.push({ type: identifierType.toUpperCase() as IdentifierType });
        identifierIndex = identifiers.length - 1;
      } else {
        identifierIndex = identifiers.indexOf(identifier);
      }

      switch (fieldName) {
        case 'identifierValue':
          identifiers[identifierIndex].identifierValue =
            (identifiers[identifierIndex].type === IdentifierType.SSN ||
              identifiers[identifierIndex].type === IdentifierType.TIN) &&
            isMasked(result[key]?.toString())
              ? null
              : (result[key].toUpperCase() as string);
          break;
        case 'identifierJurisdiction':
          identifiers[identifierIndex].identifierJurisdiction = result[key] as string;
          break;
        case 'identifierCountry':
          identifiers[identifierIndex].identifierCountry = result[key] as string;
          break;
        case 'identifierIssuance':
          identifiers[identifierIndex].identifierIssuance = isoDateFormat(result[key]);
          break;
        case 'identifierExpiry':
          identifiers[identifierIndex].identifierExpiry = isoDateFormat(result[key]);
          break;
        default:
          break;
      }
    }
  }

  return identifiers;
};

const getUpdatedPartyCompany = (
  objKey: string,
  key: string,
  partyCompany: CompanyInput,
  result: Record<string, any>,
) => {
  if (result[key]) {
    switch (objKey) {
      case 'designation':
        partyCompany.designation = result[key] as string;
        break;
      case 'organizationName':
        partyCompany.organizationName = result[key] as string;
        break;
      default:
        break;
    }
  }

  return partyCompany;
};

const getUpdatedPartyContacts = (
  objKey: string,
  key: string,
  partyContacts: PartyContactInput[],
  result: Record<string, any>,
) => {
  const fieldNameTypeSplit = objKey.split(':');
  const partyContactType = fieldNameTypeSplit[1];

  const partyContact = partyContacts.find(i => i.type === (partyContactType.toUpperCase() as ContactType));
  if (!partyContact) {
    if (result[key]) {
      partyContacts.push({
        type: partyContactType.toUpperCase() as ContactType,
        contact: result[key] as string,
      });
    }
  } else {
    const index = partyContacts.indexOf(partyContact);
    partyContacts[index].contact = result[key] as string;
  }

  return partyContacts.filter(party => !!party.contact);
};

export const getUpdatedParty = (
  symphonyMappingSplit: string[],
  key: string,
  party: PartyInput,
  result: Record<string, any>,
  hiddenDataPoints: Record<string, any>,
) => {
  switch (symphonyMappingSplit[1]) {
    case 'partyPerson':
      if (party.partyPerson) {
        party.partyPerson = getUpdatedPartyPerson(
          symphonyMappingSplit,
          key,
          party.partyPerson,
          result,
          hiddenDataPoints,
        );
        /**
         * Taxation value is an open string
         * for now in SAN DA PRO, EXEMPT is what we are using as value,
         * where tax status is not captured.This may need to change
         * if other partners are using a different value
         */
        if (result['data_point:taxation:single_option'] === 'EXEMPT') {
          party.partyPerson.taxStatus = null;
        }
      }
      break;
    case 'addresses':
      if (party.addresses) {
        party.addresses = getUpdatedAddresses(symphonyMappingSplit[2], key, party.addresses, result, hiddenDataPoints);
      }
      break;
    case 'identifiers':
      if (party.identifiers) {
        party.identifiers = getUpdatedIdentifiers(symphonyMappingSplit[2], key, party.identifiers, result);
      }
      break;
    case 'partyBusinessEntity':
      if (party.partyBusinessEntity) {
        party.partyBusinessEntity = getUpdatedPartyBusinessEntity(
          symphonyMappingSplit,
          key,
          party.partyBusinessEntity,
          result,
        );
      }
      break;
    case 'partyCompany':
      if (party.partyCompany) {
        party.partyCompany = getUpdatedPartyCompany(symphonyMappingSplit[2], key, party.partyCompany, result);
      }
      break;
    case 'partyContacts':
      if (party.partyContacts) {
        party.partyContacts = getUpdatedPartyContacts(symphonyMappingSplit[2], key, party.partyContacts, result);
      }
      break;
    default:
      break;
  }

  return party;
};

export const getDefaultValueForPartyKey = (
  party: PartyInput,
  partyMapping: string,
  data?: DataMappingsByDataPointKey,
) => {
  const partyMappingSplit = partyMapping.split('.');
  switch (partyMappingSplit[0]) {
    case 'partyPerson':
      const partyPerson: any = party?.partyPerson;
      const partyPersonKey = partyMappingSplit[1] as keyof PartyPersonInput;
      const partyPersonLanguageKey = partyMappingSplit[2] as keyof PartyPersonLanguageInput;
      const value = partyPersonLanguageKey
        ? partyPerson?.[partyPersonKey]?.[partyPersonLanguageKey] ?? ''
        : partyPersonKey === 'taxStatus' && partyPerson?.[partyPersonKey] === TaxStatus.UNKNOWN
        ? ''
        : partyPerson?.[partyPersonKey] ?? '';
      return typeof value === 'number' ? value.toString() : value;
    case 'partyCompany':
      const partyCompany: any = party?.partyCompany;
      const partyCompanyKey = partyMappingSplit[1] as keyof CompanyInput;
      return partyCompany?.[partyCompanyKey] ?? '';
    case 'addresses':
      const partyAddresses = party?.addresses;
      const [addressField, addressType] = partyMappingSplit[1].split(':');
      const address = partyAddresses?.find(a => a.type === (addressType.toUpperCase() as AddressType));

      const alternateAddressType = data?.alternateSymphonyMappingForPrefill?.split(':')?.[1] ?? '';
      const alternateAddress = alternateAddressType
        ? partyAddresses?.find(a => a.type === (alternateAddressType.toUpperCase() as AddressType))
        : undefined;

      const filteredAddress = address ?? alternateAddress;
      const partyAddressKey = addressField as keyof AddressInput;
      return filteredAddress?.[partyAddressKey] ?? '';
    case 'identifiers':
      const partyIdentifiers = party?.identifiers;
      if (partyMappingSplit[1].includes(':')) {
        const [identifierField, identifierType] = partyMappingSplit[1].split(':');
        const filteredIdentifier = partyIdentifiers?.find(
          i => i.type === (identifierType.toUpperCase() as IdentifierType),
        );
        const partyIdentifierKey = identifierField as keyof PartyIdentifierInput;
        return filteredIdentifier?.[partyIdentifierKey] ?? '';
      } else {
        const filteredPartyIdentifiers = partyIdentifiers?.filter(
          i => i.type && Object.values(IdentifierType).includes(i.type),
        );
        return filteredPartyIdentifiers ? filteredPartyIdentifiers.map(i => i.type.toUpperCase() ?? '').join(',') : '';
      }
    case 'partyContacts':
      const partyContacts = party.partyContacts;
      const [contactField, contactType] = partyMappingSplit[1].split(':');
      const primaryPartyContacts = partyContacts?.filter(c => c.isPrimary !== false);
      const contact = primaryPartyContacts?.find(c => c.type === (contactType.toUpperCase() as ContactType));

      const alternateContactType = data?.alternateSymphonyMappingForPrefill?.split(':')?.[1] ?? '';
      const alternateContact = alternateContactType
        ? primaryPartyContacts?.find(c => c.type === (alternateContactType.toUpperCase() as ContactType))
        : undefined;

      const nonPrimaryContact = partyContacts?.find(
        c =>
          c.type === (contactType.toUpperCase() as ContactType) ||
          c.type === (alternateContactType.toUpperCase() as ContactType),
      );

      const filteredContact = contact ?? alternateContact ?? nonPrimaryContact;
      const partyContactKey = contactField as keyof PartyContactInput;
      return filteredContact?.[partyContactKey] ?? '';
    case 'partyBusinessEntity':
      const partyBusinessEntity = party.partyBusinessEntity;
      const partyBusinessEntityKey = partyMappingSplit[1] as keyof BusinessEntityInput;
      const partyBusinessEntityAttributeKey = partyMappingSplit[2]?.split(
        ':',
      )?.[0] as keyof BusinessEntityAttributesInput;
      if (partyBusinessEntityKey === 'attributes' && partyBusinessEntity?.attributes) {
        if (partyBusinessEntityAttributeKey === 'roleType' && data?.checkboxItems) {
          const defaultValue: Record<string, boolean> = {};
          data.checkboxItems.forEach(item => {
            defaultValue[item.dataPointKey] =
              party.partyBusinessEntity?.attributes?.roleType?.includes(
                item.symphonyMapping.split(':')[1] as BusinessEntityRoleType,
              ) ?? false;
          });
          return defaultValue;
        } else if (partyBusinessEntityAttributeKey === 'accountHolderType' && data?.checkboxItems) {
          const defaultValue: Record<string, boolean> = {};
          data.checkboxItems.forEach(item => {
            defaultValue[item.dataPointKey] =
              party.partyBusinessEntity?.attributes?.accountHolderType?.includes(
                item.symphonyMapping.split(':')[1] as AccountHolderType,
              ) ?? false;
          });
          return defaultValue;
        } else {
          return partyBusinessEntity.attributes[partyBusinessEntityAttributeKey] ?? '';
        }
      } else {
        return partyBusinessEntity?.[partyBusinessEntityKey] ?? '';
      }
  }
};
