import {
  GetAccountProfileUpdateCompletedContent,
  GetAccountProfileUpdateCompletedContent_all_account_profile_update_completed_items_update_action,
  GetAccountProfileUpdateCompletedContentVariables,
} from './__generated__/GetAccountProfileUpdateCompletedContent';
import * as queries from './query.gql';

import { useContentstackQuery } from '~/utils';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';

export type AccountProfileContentType = GetAccountProfileUpdateCompletedContent_all_account_profile_update_completed_items_update_action;

export const useGetAccountProfileUpdateCompletedContent = (
  options?: QueryHookOptions<GetAccountProfileUpdateCompletedContent, GetAccountProfileUpdateCompletedContentVariables>,
): QueryResult<GetAccountProfileUpdateCompletedContent, GetAccountProfileUpdateCompletedContentVariables> => {
  return useContentstackQuery(queries.GetAccountProfileUpdateCompletedContent, options);
};
