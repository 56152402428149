import { QueryHookOptions, QueryResult } from '@apollo/client';

import { GetSecuritySearchResults, GetSecuritySearchResultsVariables } from './__generated__/GetSecuritySearchResults';
import * as queries from './query.gql';

import { useLazySymphonyQuery, useSymphonyQuery } from '~/utils/symphony';

export const useGetSecuritySearchResults = (
  options?: QueryHookOptions<GetSecuritySearchResults, GetSecuritySearchResultsVariables>,
): QueryResult<GetSecuritySearchResults, GetSecuritySearchResultsVariables> => {
  return useSymphonyQuery(queries.GetSecuritySearchResults, options);
};

export const useLazyGetSecuritySearchResults = (
  options?: QueryHookOptions<GetSecuritySearchResults, GetSecuritySearchResultsVariables>,
) => {
  return useLazySymphonyQuery(queries.GetSecuritySearchResults, options);
};
