import React from 'react';

import { Box, Chip, DropdownChangeEvent, DropdownItem, Typography, useTheme } from '~/components';

export interface Props {
  items: DropdownItem[];
  multiSelectRenderValueProps: {
    hideDeleteIconFromChip?: boolean;
    variant: 'chip' | 'text';
  };
  onChange?: (e: DropdownChangeEvent) => void;
  placeholder?: string;
  selected?: string | number | (string | number)[];
}

export const MultiSelectDropdownRenderValue: React.FC<Props> = ({
  items,
  onChange,
  placeholder,
  multiSelectRenderValueProps,
  selected,
}) => {
  const {
    sfDropdown: {
      styles: { multiSelectChip },
    },
  } = useTheme();

  const { hideDeleteIconFromChip, variant } = multiSelectRenderValueProps;

  if (!Array.isArray(selected) || !selected.length) {
    return <Typography>{placeholder}</Typography>;
  }
  if (variant === 'chip') {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected.map(el => (
          <Chip
            key={el}
            label={items.find(item => item.value === el)?.label}
            onDelete={
              hideDeleteIconFromChip
                ? undefined
                : e => {
                    e.target.value = selected.filter(item => item !== el);
                    onChange?.(e);
                  }
            }
            onMouseDown={e => e.stopPropagation()}
            sx={multiSelectChip}
            variant="filled"
          />
        ))}
      </Box>
    );
  }
  return <>{selected.join(', ')}</>;
};
