import React from 'react';
import { UseFormMethods } from 'react-hook-form';

import { RelationshipDetails } from '../../Beneficiaries';
import { CMSQuestions } from '../../contentstack';
import { PaperworkContextVariables } from '../../context';

import { ComponentTypes, ValidationRules } from './utils';

import { AddressInput, FinancialAccountType } from '~/__generated__';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { Item } from '~/components/ui/RadioGroup';
import { CTAs } from '~/containers/AccountProfile/contentstack';
import { ManagedProductPaperwork, QuestionnaireData } from '~/containers/Paperwork/symphony';
import { PartyAttribute } from '~/hooks/client/symphony';
import { ContentOptions } from '~/utils/contentstack/src/types';

export type QuestionValue = string | boolean | number | number[] | string[];
export type QuestionConditionType = 'BETWEEN' | 'EQUAL' | 'GREATER' | 'NOT_EQUAL';

export const ALPHA_ONLY = /^[A-Za-z ]+$/;
export const ALPHA_NUMERIC_ONLY = /^[a-zA-Z0-9]*$/;

export interface QuestionRule {
  conditions?: {
    dataPointKey: string;
    type: QuestionConditionType;
    value: QuestionValue;
  }[];
  newLine?: boolean;
  next: string | null;
}

export interface Mask {
  charsToShow: number;
  maskChar: string;
}

export type CustomErrorMap = { customErrorName: string; originalErrorName: string };
export interface Validation {
  errorMap?: CustomErrorMap;
  name: string;
  value: string | number | boolean | string[] | RegExp;
  watchField?: string;
}

export interface Formatting {
  name: string;
  value: Mask | boolean; // Other formatting types can be added here when required
}

export interface ValidationMessage {
  key: string;
  label: string;
}

export interface QuestionOrder {
  hasHiddenComponentType?: boolean;
  orderSteps: QuestionOrderSteps[];
  selectContactDisabledFields?: string[];
  start: string;
}

interface BeneficiaryProps {
  contingentBeneficiaryIds?: string[];
  ctas?: CTAs[];
  deleteKeysFromResult: (keys: string[], relationshipDetails?: RelationshipDetails) => void;
  isAccountProfileEdit?: boolean;
  numberOfContingentBeneficiaries: any;
  numberOfPrimaryBeneficiaries: any;
  setBeneficiaryIds?: (ids: string[]) => void;
  setContingentBeneficiaryIds?: (ids: string[]) => void;
  setNumberOfContingentBeneficiaries: any;
  setNumberOfPrimaryBeneficiaries: any;
}

interface CoApplicantProps {
  homeStateCode?: string | null;
  primaryClientHasBeneficiaries?: boolean;
}

export interface CustomQuestionComponentProps {
  accountType?: FinancialAccountType | null;
  beneficiaryProps?: BeneficiaryProps;
  coApplicantProps?: CoApplicantProps;
  content: {
    countriesList: DropdownItem[];
    questions: QuestionContent[];
    stateList?: DropdownItem[];
    validationMessages?: ValidationMessage[];
  };
  contentOptions?: ContentOptions;
  contextValues: PaperworkContextVariables;
  dataQa?: string;
  disablePageEditing?: boolean;
  formHooks: UseFormMethods<FormData>;
  hidden: boolean;
  isAccountProfileEdit?: boolean;
  isSecondary?: boolean;
  isSubmitted: boolean;
  managedProductId?: string;
  onUnsuccessfulCallback: () => void;
  paperworkData?: ManagedProductPaperwork[];
  partyAttributes?: PartyAttribute[];
  partyId?: string;
  prefillValues?: any;
  setContextValues?: React.Dispatch<React.SetStateAction<PaperworkContextVariables>>;
  validationRules?: ValidationRules;
}

export interface PaperworkCheckboxGroupItem {
  dataPointKey: string;
  defaultValue: boolean;
  disabled?: boolean;
  questionKey: string;
  symphonyMapping: string;
}

export interface QuestionOrderSteps {
  accountTypes?: FinancialAccountType[];
  adjacent?: string | null;
  allowedOptions?: string[];
  alternateSymphonyMappingForPrefill?: string;
  checkBoxes?: PaperworkCheckboxGroupItem[];
  clearPrefilledValue?: boolean;
  cmsKey?: string;
  componentType: ComponentTypes;
  contextKey?: keyof PaperworkContextVariables;
  ctas?: CTAs[];
  customQuestionComponent?: React.FC<CustomQuestionComponentProps>;
  dataPointKey: string;
  defaultFirstOption?: boolean;
  defaultValue?: string | boolean;
  doNotApplyDefaultValue?: boolean;
  formats?: Formatting[];
  onChange?: (e: any) => void;
  questionKey: string;
  questionnaireDataPointKey?: string;
  rules: QuestionRule[];
  shouldFetchFromSymphonyData?: boolean;
  symphonyMapping: string;
  validations?: Validation[];
  value?: string;
}

export interface QuestionContent {
  character_limit?: number;
  error?: boolean;
  key: string;
  options?: Item[];
  prefix?: string;
  question: string;
  suffix?: string;
}

export interface BeneficiaryConfig {
  hideSsn: boolean;
  includeLastName?: boolean;
}

export interface AssetsHeldAwayConfig {
  excludeQuestions?: string[];
}

export interface Props {
  accountType?: FinancialAccountType | null;
  allPaperworkData?: ManagedProductPaperwork[];
  assetsHeldAwayConfig?: AssetsHeldAwayConfig;
  beneficiaryConfig: BeneficiaryConfig;
  content: {
    questions: CMSQuestions;
    validationMessages?: ValidationMessage[];
  };
  contentOptions: ContentOptions;
  contingentBeneficiaryIds?: string[];
  ctas?: CTAs[];
  dataQa?: string;
  deleteKeysFromResult: (keys: string[], relationshipDetails?: RelationshipDetails) => void;
  dropdownsContent: DropdownsContent;
  employmentStatus?: string;
  harvestLosses?: boolean;
  hiddenComponentValues?: Record<string, any>;
  isAccountProfileEdit?: boolean;
  isSaving?: boolean;
  isSecondary?: boolean;
  isSubmitted: boolean;
  listOfEmails?: DropdownItem[];
  managedProductId: string;
  minorAgesByState: { custodial_minor_age?: number; minor_age: number; state?: string }[];
  onSubmit: (section: string, formData: any, hiddenDataPoints: any) => void;
  onUnsuccessfulCallback: () => void;
  order: QuestionOrder;
  partyAttributes?: PartyAttribute[];
  partyId: string;
  primaryAddresses: AddressInput[];
  primaryClientHasBeneficiaries?: boolean;
  questionnaireInvestWealthData?: QuestionnaireData[];
  repCodes: DropdownItem<string>[];
  results?: Record<string, any>;
  section: string;
  sectionIndex?: number;
  selectContactDropdownItems?: DropdownItem<string>[];
  selectedContact?: ManagedProductPaperwork | null;
  setBeneficiaryIds?: (ids: string[]) => void;
  setContextValues?: React.Dispatch<React.SetStateAction<PaperworkContextVariables>>;
  setContingentBeneficiaryIds?: (ids: string[]) => void;
  showTlhButtonIcon?: boolean;
  symphonyData?: ManagedProductPaperwork | null;
  updateAccordionStates?: (id: number | undefined) => void;
  updateEmploymentDetails?: (paperworkId: string | null, status: string | null) => void;
  updateEmploymentStatus?: (status: string) => void;
  updateTlh?: (tlh: boolean) => void;
}

export interface DropdownsContent {
  countriesList: DropdownItem[];
  statesList: DropdownItem[];
}

export interface Rule {
  conditions: {
    dataPointKey: string;
    targetValue: QuestionValue;
    type?: QuestionConditionType;
  }[];
  dependentDataPointKeys: string[];
}

export interface FormData {
  [dataPointKey: string]: any;
}
export interface DataMappingsByDataPointKey {
  alternateSymphonyMappingForPrefill?: string | undefined;
  checkboxItems?: PaperworkCheckboxGroupItem[];
  cmsKeys?: string;
  componentType?: ComponentTypes;
  contextKey?: keyof PaperworkContextVariables;
  defaultValue?: string | boolean | undefined | Record<string, boolean>;
  questionKey?: string;
  questionnaireDataPointKeyMapping?: string | undefined;
  shouldFetchFromSymphonyData?: boolean;
  symphonyMapping: string;
}

export interface HiddenOption {
  dependentOn?: {
    comparisonType: QuestionConditionType;
    contextVariable: keyof PaperworkContextVariables;
    fieldName: string;
    value: string;
  };
  fieldName: string;
  values: string[];
}
