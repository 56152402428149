import { styled, useTheme } from '@mui/material';

export const UncheckedRadioIcon = styled('span')(() => {
  const {
    sfRadioGroup: {
      styles: { radioIcons: radioIconStyles },
    },
  } = useTheme();

  return {
    ...radioIconStyles?.icon,
  };
});
