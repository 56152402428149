import { range } from 'fp-ts/lib/Array';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';

import { PaperworkContext } from '../context';
import { QuestionContent, ValidationMessage } from '../Section/Questions/types';
import { getCtaText } from '../utils/ctatext';
import { isValidNumberInput } from '../utils/helper';

import { AddressType, ContactType, MaritalStatus, RelationshipSubtype } from '~/__generated__';
import { AccountProfileContext } from '~/components/AccountProfileUpdateWrapper/context';
import { Alert, Box, Button, Dropdown, TextField, Typography, useTheme } from '~/components/ui';
import { DropdownItem } from '~/components/ui/Dropdown/types';
import { CTAs } from '~/containers/AccountProfile/contentstack';
import {
  getErrorMessage,
  isValidEmail,
  isValidLength,
  isValidNumber,
  isValidZipCode,
} from '~/containers/Paperwork/Section/Questions/utils';
import { PaperworkRelationships } from '~/containers/Paperwork/symphony';
import { SfTheme, useCoreConfig } from '~/utils';

export interface FormData {
  [dataPointKey: string]: any;
}

export interface Props {
  contactData?: (PaperworkRelationships | null)[];
  content: { questions: QuestionContent[]; validationMessages?: ValidationMessage[] };
  control: Control<FormData>;
  ctas?: CTAs[];
  dataQa?: string;
  deleteKeysFromResult: (keys: string[]) => void;
  fieldsErrors: DeepMap<FormData, FieldError>;
  getValues: (val: string) => string;
  hidden: boolean;
  isAccountProfileEdit?: boolean;
  maximumContacts: number;
  minimumContacts: number;
  numberOfContacts: any;
  questionKey: string;
  setNumberOfContacts: any;
  statesList?: DropdownItem[];
  symphonyMappingKey: string;
  trigger: (name?: string | string[] | undefined) => Promise<boolean>;
}

export const Contacts: FC<Props> = ({
  dataQa = 'contacts',
  ctas,
  fieldsErrors,
  control,
  content,
  hidden,
  getValues,
  isAccountProfileEdit,
  minimumContacts,
  maximumContacts,
  setNumberOfContacts,
  numberOfContacts,
  questionKey,
  symphonyMappingKey,
  contactData,
  statesList,
  deleteKeysFromResult,
  trigger,
}) => {
  const {
    sfPaperwork: { styles: style },
    sfAccountProfile: { styles: accountProfileStyles },
  } = useTheme<SfTheme>();
  const { questions } = content;
  const {
    components: {
      sfPaperwork: { trustedContact },
    },
  } = useCoreConfig();
  // As this is a custom question component, we pass the symphonyMappingKey and questionKey to the custom component,
  // then map it within the component with their respective field names. The questionKey should follow the first part of
  // the custom component name.
  const contactFirstNameFieldName = `${symphonyMappingKey}-first-name`;
  const contactMiddleNameFieldName = `${symphonyMappingKey}-middle-name`;
  const contactLastNameFieldName = `${symphonyMappingKey}-last-name`;
  const contactRelationshipFieldName = `${symphonyMappingKey}-relationship`;
  const contactEmailFieldName = `${symphonyMappingKey}-email`;
  const contactPhoneNumberFieldName = `${symphonyMappingKey}-phone-number`;
  const contactStreetAddressFieldName = `${symphonyMappingKey}-street-address`;
  const contactStreetLineFieldName = `${symphonyMappingKey}-street-line`;
  const contactCityFieldName = `${symphonyMappingKey}-city`;
  const contactStateFieldName = `${symphonyMappingKey}-state`;
  const contactZipCodeFieldName = `${symphonyMappingKey}-zip-code`;

  const contactFirstNameContent = questions.find(q => q.key === `${questionKey}_first_name`);
  const contactMiddleNameContent = questions.find(q => q.key === `${questionKey}_middle_name`);
  const contactLastNameContent = questions.find(q => q.key === `${questionKey}_last_name`);
  const alternateContactFirstNameContent = questions.find(q => q.key === `${questionKey}_alternate_first_name`);
  const alternateContactMiddleNameContent = questions.find(q => q.key === `${questionKey}_alternate_middle_name`);
  const alternateContactLastNameContent = questions.find(q => q.key === `${questionKey}_alternate_last_name`);
  const contactRelationshipContent = questions.find(q => q.key === `${questionKey}_relationship`);
  const contactEmailContent = questions.find(q => q.key === `${questionKey}_email`);
  const contactPhoneNumberContent = questions.find(q => q.key === `${questionKey}_phone_number`);
  const contactStreetAddressContent = questions.find(q => q.key === `${questionKey}_street_address`);
  const contactStreetLineContent = questions.find(q => q.key === `${questionKey}_street_line_2`);
  const contactCityContent = questions.find(q => q.key === `${questionKey}_city`);
  const contactStateContent = questions.find(q => q.key === `${questionKey}_state`);
  const contactZipCodeContent = questions.find(q => q.key === `${questionKey}_zip_code`);

  const contactRelationTypeIndexValueObjRef = useRef({});
  const [contactRelationTypeError, setContactRelationTypeError] = useState<boolean>(false);
  const { maritalStatus } = useContext(PaperworkContext);
  const checkIfSpouseAllowed = (value: string) =>
    maritalStatus &&
    maritalStatus !== MaritalStatus.MARRIED &&
    value === RelationshipSubtype.SPOUSE &&
    !isAccountProfileEdit;

  const contactElementStyles = isAccountProfileEdit ? accountProfileStyles.contactElements : style.contactElements;
  const { stateZipCodeMap } = useContext(PaperworkContext);
  const { disablePageEditing } = useContext(AccountProfileContext);
  useEffect(() => {
    if (contactData && contactData.length) {
      setNumberOfContacts(contactData.length);
    } else {
      setNumberOfContacts(isAccountProfileEdit ? 0 : 1);
    }
  }, []);
  const onAdd = () => {
    setNumberOfContacts(numberOfContacts + 1);
  };

  const onRemove = () => {
    const indexOfRemovedItem = numberOfContacts - 1;
    setNumberOfContacts(numberOfContacts - 1);
    deleteKeysFromResult([
      `${contactFirstNameFieldName}-${indexOfRemovedItem}`,
      `${contactMiddleNameFieldName}-${indexOfRemovedItem}`,
      `${contactLastNameFieldName}-${indexOfRemovedItem}`,
      `${contactRelationshipFieldName}-${indexOfRemovedItem}`,
      `${contactEmailFieldName}-${indexOfRemovedItem}`,
      `${contactPhoneNumberFieldName}-${indexOfRemovedItem}`,
      `${contactStreetAddressFieldName}-${indexOfRemovedItem}`,
      `${contactStreetLineFieldName}-${indexOfRemovedItem}`,
      `${contactCityFieldName}-${indexOfRemovedItem}`,
      `${contactStateFieldName}-${indexOfRemovedItem}`,
      `${contactZipCodeFieldName}-${indexOfRemovedItem}`,
    ]);
  };

  const getInputError = (error?: FieldError) => {
    return (
      !!error && (
        <Box data-qa={`error-${error.ref?.name}`} sx={{ width: 1 }}>
          <Typography role="alert" sx={{ color: 'error.main' }} variant="caption">
            {getErrorMessage(error.type, content.validationMessages)}
          </Typography>
        </Box>
      )
    );
  };

  useEffect(() => {
    if (contactData?.length) {
      const prefillValues = contactData.map((value, index) => {
        return {
          [index]: value?.relationshipType,
        };
      });
      contactRelationTypeIndexValueObjRef.current = {
        ...contactRelationTypeIndexValueObjRef.current,
        ...prefillValues,
      };
      checkContactRelationTypeError();
    }
  }, [contactData]);

  const checkContactRelationTypeError = () => {
    const spouseCount = Object.values(contactRelationTypeIndexValueObjRef.current).filter(
      contactType => contactType === RelationshipSubtype.SPOUSE,
    ).length;
    setContactRelationTypeError(spouseCount > 1);
  };

  const onContactRelationTypeChange = (index: number, value: string) => {
    contactRelationTypeIndexValueObjRef.current = { ...contactRelationTypeIndexValueObjRef.current, [index]: value };
    checkContactRelationTypeError();
  };

  if (
    [
      contactFirstNameContent,
      contactMiddleNameContent,
      contactLastNameContent,
      alternateContactFirstNameContent,
      alternateContactMiddleNameContent,
      alternateContactLastNameContent,
      contactRelationshipContent,
      contactEmailContent,
      contactPhoneNumberContent,
      contactStreetAddressContent,
      contactStreetLineContent,
      contactCityContent,
      contactStateContent,
      contactZipCodeContent,
    ].includes(undefined)
  ) {
    return <Alert severity="error">Content not found for some of the question(s) in: "{questionKey}" in CMS.</Alert>;
  }

  const addCtaText = isAccountProfileEdit
    ? numberOfContacts
      ? getCtaText(ctas, 'addAlternateTrustedContact')
      : getCtaText(ctas, 'addTrustedContact')
    : questions.find(q => q.key === `${questionKey}_add_cta`)?.question;
  const ctaStyle = style.sectionCTA ? { ...style.sectionCTA } : {};

  const invalidCharactersInNameRegex = (allowSpecialCharacters?: boolean) =>
    allowSpecialCharacters ? /[^A-Za-z -]/g : /[^A-Za-z ]/g;

  return (
    <Box data-qa={dataQa}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ mr: 1 }} variant="subtitle1">
          {questions.find(q => q.key === `${questionKey}_title`)?.question}
        </Typography>
        <Typography sx={{ alignSelf: 'center' }} variant="caption">
          {questions.find(q => q.key === `${questionKey}_subtitle`)?.question}
        </Typography>
      </Box>
      {!!numberOfContacts &&
        range(0, numberOfContacts - 1).map(number => {
          return (
            <Box
              data-qa={dataQa}
              key={number}
              sx={
                isAccountProfileEdit
                  ? { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', mb: 2, mt: number ? 2 : 0 }
                  : { mb: 2 }
              }
            >
              <Box sx={{ ...contactElementStyles, mt: isAccountProfileEdit ? 1 : undefined }}>
                <Controller
                  control={control}
                  defaultValue={contactData?.[number]?.party.partyPerson?.givenName ?? ''}
                  name={`${contactFirstNameFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactFirstNameFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldsErrors[`${contactFirstNameFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={
                        number === 0 ? contactFirstNameContent?.question : alternateContactFirstNameContent?.question
                      }
                      name={name}
                      onBlur={e => {
                        e.target.value = e.target.value.trim();
                        onChange(e);
                      }}
                      onChange={e => {
                        const { value: inputValue } = e.target;
                        if (
                          contactFirstNameContent?.character_limit &&
                          inputValue.length > contactFirstNameContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        if (inputValue) {
                          e.target.value = inputValue.replaceAll(
                            invalidCharactersInNameRegex(trustedContact?.allowSpecialCharacters),
                            '',
                          );
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                  rules={hidden ? {} : { required: true }}
                />
                {getInputError(fieldsErrors[`${contactFirstNameFieldName}-${number}`])}
              </Box>
              <Box sx={{ ...contactElementStyles, mt: isAccountProfileEdit ? 1 : undefined }}>
                <Controller
                  control={control}
                  defaultValue={contactData?.[number]?.party.partyPerson?.middleName ?? ''}
                  name={`${contactMiddleNameFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactMiddleNameFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldsErrors[contactMiddleNameFieldName]}
                      fullWidth
                      inputRef={ref}
                      label={
                        number === 0 ? contactMiddleNameContent?.question : alternateContactMiddleNameContent?.question
                      }
                      name={name}
                      onBlur={e => {
                        e.target.value = e.target.value.trim();
                        onChange(e);
                      }}
                      onChange={e => {
                        const { value: inputValue } = e.target;
                        if (
                          contactMiddleNameContent?.character_limit &&
                          inputValue.length > contactMiddleNameContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        if (inputValue) {
                          e.target.value = inputValue.replaceAll(invalidCharactersInNameRegex(false), '');
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                />
                {getInputError(fieldsErrors[`${contactMiddleNameFieldName}-${number}`])}
              </Box>
              <Box
                sx={{
                  ...contactElementStyles,
                  mt: isAccountProfileEdit ? 1 : undefined,
                  mr: isAccountProfileEdit ? 0 : undefined,
                }}
              >
                <Controller
                  control={control}
                  defaultValue={contactData?.[number]?.party.partyPerson?.familyName ?? ''}
                  disabled={disablePageEditing}
                  name={`${contactLastNameFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactLastNameFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldsErrors[`${contactLastNameFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={
                        number === 0 ? contactLastNameContent?.question : alternateContactLastNameContent?.question
                      }
                      name={name}
                      onBlur={e => {
                        e.target.value = e.target.value.trim();
                        onChange(e);
                      }}
                      onChange={e => {
                        const { value: inputValue } = e.target;
                        if (
                          contactLastNameContent?.character_limit &&
                          inputValue.length > contactLastNameContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        if (inputValue) {
                          e.target.value = inputValue.replaceAll(
                            invalidCharactersInNameRegex(trustedContact?.allowSpecialCharacters),
                            '',
                          );
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                  rules={hidden ? {} : { required: true }}
                />
                {getInputError(fieldsErrors[`${contactLastNameFieldName}-${number}`])}
              </Box>
              <Box sx={{ ...contactElementStyles, mt: 1.75 }}>
                <Controller
                  control={control}
                  defaultValue={contactData?.[number]?.relationshipType ?? ''}
                  name={`${contactRelationshipFieldName}-${number}`}
                  render={({ onChange, value, ref }) => (
                    <Dropdown
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: style.maxDropdownHeight,
                          },
                        },
                      }}
                      dataQa={`dropdown-${contactRelationshipFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldsErrors[`${contactRelationshipFieldName}-${number}`]}
                      inputRef={ref}
                      items={contactRelationshipContent?.options ?? []}
                      label={contactRelationshipContent?.question}
                      onChange={e => {
                        onContactRelationTypeChange(number, e.target.value as string);
                        onChange(e.target.value);
                      }}
                      value={value}
                      width="100%"
                    />
                  )}
                  rules={
                    hidden
                      ? {}
                      : {
                          required: true,
                          validate: {
                            onlyOneSpouseSelectionAllowed: () => !contactRelationTypeError,
                            spouseNotAllowed: value => !checkIfSpouseAllowed(value),
                          },
                        }
                  }
                />
                {getInputError(fieldsErrors[`${contactRelationshipFieldName}-${number}`])}
              </Box>
              <Box sx={contactElementStyles}>
                <Controller
                  control={control}
                  defaultValue={
                    contactData?.[number]?.party.partyContacts?.find(contact => contact.type === ContactType.EMAIL)
                      ?.contact ?? ''
                  }
                  name={`${contactEmailFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactEmailFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldsErrors[`${contactEmailFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={contactEmailContent?.question}
                      name={name}
                      onChange={e => {
                        const { value: inputValue } = e.target;
                        if (
                          contactEmailContent?.character_limit &&
                          inputValue.length > contactEmailContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        onChange(e);
                      }}
                      type="email"
                      value={value}
                    />
                  )}
                  rules={
                    hidden
                      ? {}
                      : {
                          required: trustedContact?.optionalEmailAddress ? false : true,
                          validate: {
                            isEmail: value => !value || isValidEmail(value),
                            eitherEmailOrPhoneRequired: value => {
                              const phoneNumberField = `${contactPhoneNumberFieldName}-${number}`;
                              const phoneNumberFieldValue = getValues(phoneNumberField);
                              if (trustedContact?.eitherEmailOrPhoneRequired && !phoneNumberFieldValue) {
                                if (!value) {
                                  return false;
                                } else {
                                  trigger(phoneNumberField);
                                }
                              }
                              return true;
                            },
                          },
                        }
                  }
                />
                {getInputError(fieldsErrors[`${contactEmailFieldName}-${number}`])}
              </Box>
              <Box sx={{ ...contactElementStyles, mr: isAccountProfileEdit ? 0 : undefined }}>
                <Controller
                  control={control}
                  defaultValue={
                    contactData?.[number]?.party.partyContacts?.find(contact => contact.type === ContactType.MOBILE)
                      ?.contact ?? ''
                  }
                  name={`${contactPhoneNumberFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactPhoneNumberFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldsErrors[`${contactPhoneNumberFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={contactPhoneNumberContent?.question}
                      name={name}
                      onChange={e => {
                        const { value: inputValue } = e.target;
                        if (isValidNumberInput(inputValue)) {
                          e.preventDefault(); // Prevent Non Numeric entries
                          return;
                        }
                        if (
                          contactPhoneNumberContent?.character_limit &&
                          inputValue.length > contactPhoneNumberContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                  rules={
                    hidden
                      ? {}
                      : {
                          required: trustedContact?.optionalPhoneNumber ? false : true,
                          validate: {
                            isNumber: value => (value ? isValidNumber(value) : true),
                            minLength: value => (value ? value.length >= 10 : true),
                            eitherEmailOrPhoneRequired: value => {
                              const emailField = `${contactEmailFieldName}-${number}`;
                              const emailFieldValue = getValues(emailField);
                              if (trustedContact?.eitherEmailOrPhoneRequired && !emailFieldValue) {
                                if (!value) {
                                  return false;
                                } else {
                                  trigger(emailField);
                                }
                              }
                              return true;
                            },
                          },
                        }
                  }
                />
                {getInputError(fieldsErrors[`${contactPhoneNumberFieldName}-${number}`])}
              </Box>
              <Box sx={contactElementStyles}>
                <Controller
                  control={control}
                  defaultValue={
                    contactData?.[number]?.party.addresses?.find(address => address.type === AddressType.HOME)
                      ?.addressLine1 ?? ''
                  }
                  name={`${contactStreetAddressFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactStreetAddressFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldsErrors[`${contactStreetAddressFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={contactStreetAddressContent?.question}
                      name={name}
                      onBlur={e => {
                        e.target.value = e.target.value.trim();
                        onChange(e);
                      }}
                      onChange={e => {
                        if (
                          contactStreetAddressContent?.character_limit &&
                          e.target.value.length > contactStreetAddressContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                  rules={hidden ? {} : { required: trustedContact?.optionalStreetAddress ? false : true }}
                />
                {getInputError(fieldsErrors[`${contactStreetAddressFieldName}-${number}`])}
              </Box>
              <Box sx={contactElementStyles}>
                <Controller
                  control={control}
                  defaultValue={
                    contactData?.[number]?.party.addresses?.find(address => address.type === AddressType.HOME)
                      ?.addressLine2 ?? ''
                  }
                  name={`${contactStreetLineFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactStreetLineFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldsErrors[`${contactStreetLineFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={contactStreetLineContent?.question}
                      name={name}
                      onBlur={e => {
                        e.target.value = e.target.value.trim();
                        onChange(e);
                      }}
                      onChange={e => {
                        if (
                          contactStreetLineContent?.character_limit &&
                          e.target.value.length > contactStreetLineContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                />
                {getInputError(fieldsErrors[`${contactStreetLineFieldName}-${number}`])}
              </Box>
              <Box sx={{ ...contactElementStyles, mr: isAccountProfileEdit ? 0 : undefined }}>
                <Controller
                  control={control}
                  defaultValue={
                    contactData?.[number]?.party.addresses?.find(address => address.type === AddressType.HOME)
                      ?.countrySecondarySubdivision ?? ''
                  }
                  name={`${contactCityFieldName}-${number}`}
                  render={({ onChange, value, name, ref }) => (
                    <TextField
                      dataQa={`textField-${contactCityFieldName}-${number}`}
                      disabled={disablePageEditing}
                      error={!!fieldsErrors[`${contactCityFieldName}-${number}`]}
                      fullWidth
                      inputRef={ref}
                      label={contactCityContent?.question}
                      name={name}
                      onBlur={e => {
                        e.target.value = e.target.value.trim();
                        onChange(e);
                      }}
                      onChange={e => {
                        if (
                          contactCityContent?.character_limit &&
                          e.target.value.length > contactCityContent.character_limit
                        ) {
                          e.preventDefault();
                          return;
                        }
                        onChange(e);
                      }}
                      type="text"
                      value={value}
                    />
                  )}
                  rules={hidden ? {} : { required: trustedContact?.optionalCity ? false : true }}
                />
                {getInputError(fieldsErrors[`${contactCityFieldName}-${number}`])}
              </Box>
              <Box sx={{ display: 'flex', my: 2, width: isAccountProfileEdit ? 0.32 : undefined }}>
                <Box sx={{ display: 'flex', flex: '1 0', flexWrap: 'wrap', mr: 1 }}>
                  <Controller
                    control={control}
                    defaultValue={
                      contactData?.[number]?.party.addresses?.find(address => address.type === AddressType.HOME)
                        ?.countryPrimarySubdivision ?? ''
                    }
                    name={`${contactStateFieldName}-${number}`}
                    render={({ onChange, value, ref }) => (
                      <Dropdown
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: style.maxDropdownHeight,
                            },
                          },
                        }}
                        dataQa={`dropdown-${contactStateFieldName}-${number}`}
                        disabled={disablePageEditing}
                        error={!!fieldsErrors[`${contactStateFieldName}-${number}`]}
                        inputRef={ref}
                        items={statesList ?? []}
                        label={contactStateContent?.question}
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        width="100%"
                      />
                    )}
                    rules={hidden ? {} : { required: trustedContact?.optionalState ? false : true }}
                  />
                  {getInputError(fieldsErrors[`${contactStateFieldName}-${number}`])}
                </Box>
                <Box sx={{ display: 'flex', flex: '1 0', flexWrap: 'wrap' }}>
                  <Controller
                    control={control}
                    defaultValue={
                      contactData?.[number]?.party.addresses?.find(address => address.type === AddressType.HOME)
                        ?.postalCode ?? ''
                    }
                    name={`${contactZipCodeFieldName}-${number}`}
                    render={({ onChange, value, name, ref }) => (
                      <TextField
                        dataQa={`textField-${contactZipCodeFieldName}-${number}`}
                        disabled={disablePageEditing}
                        error={!!fieldsErrors[`${contactZipCodeFieldName}-${number}`]}
                        fullWidth
                        inputRef={ref}
                        label={contactZipCodeContent?.question}
                        name={name}
                        onBlur={e => {
                          e.target.value = e.target.value.trim();
                          onChange(e);
                        }}
                        onChange={e => {
                          const { value: inputValue } = e.target;
                          if (isValidNumberInput(inputValue)) {
                            e.preventDefault(); // Prevent Non Numeric entries
                            return;
                          }
                          if (
                            contactZipCodeContent?.character_limit &&
                            inputValue.length > contactZipCodeContent.character_limit
                          ) {
                            e.preventDefault();
                            return;
                          }
                          onChange(e);
                        }}
                        type="text"
                        value={value}
                      />
                    )}
                    rules={
                      hidden
                        ? {}
                        : {
                            required: trustedContact?.optionalZipCode ? false : true,
                            validate: {
                              isLength: value => (value ? isValidLength(value, 5) : true),
                              isValidZipCode: value =>
                                isValidZipCode({
                                  linkedState: getValues(`${contactStateFieldName}-${number}`),
                                  zipcode: value,
                                  stateZipCodeMap: stateZipCodeMap ?? [],
                                  isZipCodeOptional: trustedContact?.optionalZipCode,
                                }),
                            },
                          }
                    }
                  />
                  {getInputError(fieldsErrors[`${contactZipCodeFieldName}-${number}`])}
                </Box>
              </Box>
            </Box>
          );
        })}
      {isAccountProfileEdit && !!numberOfContacts && (
        <Button disabled={disablePageEditing} onClick={onRemove} sx={{ ...ctaStyle, mt: -3 }}>
          {questions.find(q => q.key === `${questionKey}_remove_cta`)?.question ?? 'Remove'}
        </Button>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: isAccountProfileEdit ? 3 : 0 }}>
        {numberOfContacts < maximumContacts && (
          <Button
            disabled={disablePageEditing}
            onClick={onAdd}
            sx={ctaStyle}
            variant={isAccountProfileEdit ? 'outlined' : 'text'}
          >
            {addCtaText ?? `+ Add  ${numberOfContacts ? 'an Alternate' : 'a'} Trusted Contact`}
          </Button>
        )}
        {!isAccountProfileEdit && numberOfContacts > minimumContacts && (
          <Button disabled={disablePageEditing} onClick={onRemove} sx={ctaStyle}>
            {questions.find(q => q.key === `${questionKey}_remove_cta`)?.question ?? 'Remove'}
          </Button>
        )}
      </Box>
    </Box>
  );
};
