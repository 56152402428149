import { DeletePaperworkById, DeletePaperworkByIdVariables } from './__generated__/DeletePaperworkById';
import {
  GetPaperwork,
  GetPaperwork_managedProduct,
  GetPaperwork_managedProduct_paperwork,
  GetPaperwork_managedProduct_paperwork_investment,
  GetPaperwork_managedProduct_paperwork_party,
  GetPaperwork_managedProduct_paperwork_party_addresses,
  GetPaperwork_managedProduct_paperwork_party_identifiers,
  GetPaperwork_managedProduct_paperwork_party_partyBusinessEntity,
  GetPaperwork_managedProduct_paperwork_party_partyCompany,
  GetPaperwork_managedProduct_paperwork_party_partyContacts,
  GetPaperwork_managedProduct_paperwork_party_partyPerson,
  GetPaperwork_managedProduct_paperwork_regulatoryInformation,
  GetPaperwork_managedProduct_paperwork_regulatoryInformation_exchangeAddress,
  GetPaperwork_managedProduct_paperwork_relationships,
  GetPaperwork_managedProduct_paperwork_relationships_party_partyPerson,
  GetPaperwork_managedProduct_paperwork_trustInformation,
  GetPaperwork_managedProduct_paperwork_wealthInformation,
  GetPaperwork_managedProduct_questionnaires_collectedDataGroup_collectedData,
  GetPaperwork_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedCurrency,
  GetPaperwork_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedSingleOption,
  GetPaperworkVariables,
} from './__generated__/GetPaperwork';
import {
  GetRelatedContacts,
  GetRelatedContacts_client_party_relationships_party,
  GetRelatedContacts_client_party_relationships_party_relationships_party,
  GetRelatedContactsVariables,
} from './__generated__/GetRelatedContacts';
import { SaveMultiplePaperwork, SaveMultiplePaperworkVariables } from './__generated__/SaveMultiplePaperwork';
import { SavePaperwork, SavePaperworkVariables } from './__generated__/SavePaperwork';
import { SyncWithCustodian, SyncWithCustodianVariables } from './__generated__/SyncWithCustodian';
import * as mutations from './mutations.gql';
import * as queries from './query.gql';

import { MutationHookOptions, MutationTuple, QueryHookOptions } from '~/utils/apollo-client';
import { useSymphonyMutation, useSymphonyQuery } from '~/utils/symphony';

export type Relationship = GetPaperwork_managedProduct_paperwork_relationships | null;
export type ManagedProduct = GetPaperwork_managedProduct;
export type ManagedProductPaperwork = GetPaperwork_managedProduct_paperwork;
export type PaperworkParty = GetPaperwork_managedProduct_paperwork_party;
export type RelatedContactParty =
  | GetRelatedContacts_client_party_relationships_party
  | GetRelatedContacts_client_party_relationships_party_relationships_party;
export type PaperworkPartyPerson = GetPaperwork_managedProduct_paperwork_party_partyPerson;
export type PaperworkPartyBusinessEntity = GetPaperwork_managedProduct_paperwork_party_partyBusinessEntity;
export type RelationshipPartyPerson = GetPaperwork_managedProduct_paperwork_relationships_party_partyPerson;
export type PaperworkRelationships = GetPaperwork_managedProduct_paperwork_relationships;
export type PaperworkRegulatoryInformation = GetPaperwork_managedProduct_paperwork_regulatoryInformation;
export type PaperworkPartyCompanyInformation = GetPaperwork_managedProduct_paperwork_party_partyCompany;
export type PaperworkPartyContact = GetPaperwork_managedProduct_paperwork_party_partyContacts;
export type PaperworkPartyIdentifier = GetPaperwork_managedProduct_paperwork_party_identifiers;
export type PaperworkPartyAddress =
  | GetPaperwork_managedProduct_paperwork_party_addresses
  | GetPaperwork_managedProduct_paperwork_regulatoryInformation_exchangeAddress;
export type QuestionnaireData = GetPaperwork_managedProduct_questionnaires_collectedDataGroup_collectedData;
export type QuestionnaireWealthData = GetPaperwork_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedCurrency;
export type QuestionnaireInvestmentData = GetPaperwork_managedProduct_questionnaires_collectedDataGroup_collectedData_QuestionnaireCollectedSingleOption;
export type PaperworkInvestment = GetPaperwork_managedProduct_paperwork_investment;
export type PaperworkWealthInformation = GetPaperwork_managedProduct_paperwork_wealthInformation;
export type TrustInformation = GetPaperwork_managedProduct_paperwork_trustInformation;

export const isQuestionnaireWealthData = (field: QuestionnaireData): field is QuestionnaireWealthData =>
  field.__typename === 'QuestionnaireCollectedCurrency';
export const isQuestionnaireInvestmentData = (field: QuestionnaireData): field is QuestionnaireInvestmentData =>
  field.__typename === 'QuestionnaireCollectedSingleOption';

export const useGetPaperwork = (options?: QueryHookOptions<GetPaperwork, GetPaperworkVariables>) => {
  return useSymphonyQuery(queries.GetPaperwork, options);
};

export const useGetRelatedContacts = (options?: QueryHookOptions<GetRelatedContacts, GetRelatedContactsVariables>) => {
  return useSymphonyQuery(queries.GetRelatedContacts, options);
};

export const useSavePaperwork = (options?: MutationHookOptions<SavePaperwork, SavePaperworkVariables>) => {
  return useSymphonyMutation(mutations.SavePaperwork, options);
};

export const useDeletePaperworkById = (
  options?: MutationHookOptions<DeletePaperworkById, DeletePaperworkByIdVariables>,
): MutationTuple<DeletePaperworkById, DeletePaperworkByIdVariables> => {
  return useSymphonyMutation(mutations.DeletePaperworkById, options);
};

export const useSaveMultiplePaperwork = (
  options?: MutationHookOptions<SaveMultiplePaperwork, SaveMultiplePaperworkVariables>,
): MutationTuple<SaveMultiplePaperwork, SaveMultiplePaperworkVariables> => {
  return useSymphonyMutation(mutations.SaveMultiplePaperwork, options);
};

export const useSyncWithCustodian = (options?: MutationHookOptions<SyncWithCustodian, SyncWithCustodianVariables>) => {
  return useSymphonyMutation(mutations.SyncWithCustodian, options);
};
