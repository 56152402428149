import { OutlinedInputProps } from '@mui/material';
import { endOfYesterday, isAfter, isBefore, isValid, parseISO, startOfYear, toDate } from 'date-fns';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FieldError, RegisterOptions, UseFormMethods } from 'react-hook-form';

import { PaperworkContext } from '../../context';
import { BeneficiaryConfig, QuestionContent, ValidationMessage } from '../../Section/Questions/types';
import {
  ComponentTypes,
  getErrorMessage,
  InputTypes,
  isValidIdentificationNumber,
} from '../../Section/Questions/utils';
import { BeneficiaryTypeError } from '..';

import { IdentifierType, RelationshipSubtype } from '~/__generated__';
import {
  Alert,
  Box,
  CheckboxGroup,
  DatePicker,
  Dropdown,
  DropdownItem,
  Grid,
  InputAdornment,
  RadioGroup,
  RteContent,
  TextField,
  Typography,
} from '~/components';
import { AccountProfileContext } from '~/components/AccountProfileUpdateWrapper/context';
import { isStringEmpty, useCoreConfig } from '~/utils';

export interface FormData {
  [dataPointKey: string]: any;
}

interface ControllerParams {
  componentName: string;
  componentType?: ComponentTypes;
  defaultValue?: string;
  disabled?: boolean;
  dropdownOptions?: DropdownItem[];
  error?: boolean;
  inputProps?: Partial<OutlinedInputProps>;
  inputType?: InputTypes;
  onInputChange?: (e: any) => boolean;
  questionContent?: QuestionContent;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  type?: string;
}

export interface Props {
  beneficiaryConfig: BeneficiaryConfig;
  beneficiaryTypeError: BeneficiaryTypeError;
  beneficiaryUniqueId: string;
  content: {
    countriesList: DropdownItem[];
    questions: QuestionContent[];
    validationMessages?: ValidationMessage[];
  };
  dataQa?: string;
  formHooks: UseFormMethods<FormData>;
  hidden: boolean;
  index: number;
  isAccountProfileEdit?: boolean;
  onBeneficiaryTypeChange: (index: number, value: string) => void;
  onPercentageChange: (name: string, value: number) => void;
  percentageSumError: boolean;
  prefillData?: any;
  questionKey: string;
  symphonyMappingKey: string;
}

const getInputError = (error?: FieldError, validationMessages?: ValidationMessage[], questionKey?: string) => {
  return (
    !!error && (
      <Box data-qa={`error-${error.ref?.name}`} sx={{ width: 1 }}>
        <Typography role="alert" sx={{ color: 'error.main' }} variant="caption">
          {getErrorMessage(error.type, validationMessages, undefined, questionKey)}
        </Typography>
      </Box>
    )
  );
};

export const Beneficiary: FC<Props> = ({
  beneficiaryConfig,
  dataQa = 'beneficiary',
  index,
  formHooks: {
    errors: fieldErrors,
    control,
    watch,
    trigger,
    formState: { isSubmitted: formIsSubmitted },
  },
  beneficiaryUniqueId,
  content,
  hidden,
  isAccountProfileEdit,
  onPercentageChange,
  onBeneficiaryTypeChange,
  percentageSumError,
  beneficiaryTypeError,
  questionKey,
  symphonyMappingKey,
  prefillData,
}) => {
  const onPercentageValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    onPercentageChange(event.target.name, value ? parseFloat(value) : 0);
  };
  const { checkMaritalStatus } = useCoreConfig().components.sfPaperwork;

  const { hideSsn: hideSSN, includeLastName } = beneficiaryConfig;
  const { isTrustTypeFieldsEnabled, isPerStirpesEnabled } = useCoreConfig().components.sfPaperwork;

  const { countriesList, questions, validationMessages } = content;
  const [DOBFieldHasValue, setDOBFieldHasValue] = useState<boolean | null>();
  const [SSNFieldHasValue, setSSNFieldHasValue] = useState<boolean | null>();
  const [benTypeFieldValue, setBenTypeFieldValue] = useState<string | null>();
  const [taxReportingTypeFieldValue, setTaxReportingTypeFieldValue] = useState<string | null>();

  const beneficiaryULIDFieldName = `${symphonyMappingKey}-ulid-${beneficiaryUniqueId}`;
  const beneficiaryNameFieldName = `${symphonyMappingKey}-name-${beneficiaryUniqueId}`;
  const beneficiaryFirstNameFieldName = `${symphonyMappingKey}-first_name-${beneficiaryUniqueId}`;
  const beneficiaryLastNameFieldName = `${symphonyMappingKey}-last_name-${beneficiaryUniqueId}`;
  const beneficiaryTypeFieldName = `${symphonyMappingKey}-type-${beneficiaryUniqueId}`;
  const beneficiaryPercentageFieldName = `${symphonyMappingKey}-percentage-${beneficiaryUniqueId}`;
  const beneficiaryDOBFieldName = `${symphonyMappingKey}-dob-${beneficiaryUniqueId}`;
  const beneficiarySSNFieldName = `${symphonyMappingKey}-ssn-${beneficiaryUniqueId}`;
  const beneficiaryPerStirpesFieldName = `${symphonyMappingKey}-per-stirpes-${beneficiaryUniqueId}`;
  const beneficiaryNameOfTrusteeFieldName = `${symphonyMappingKey}-name-of-trustees-${beneficiaryUniqueId}`;
  const beneficiaryOrganizationCountryFieldName = `${symphonyMappingKey}-organization-country-${beneficiaryUniqueId}`;
  const beneficiaryTINFieldName = `${symphonyMappingKey}-tax-reporting-number-${beneficiaryUniqueId}`;
  const beneficiaryTaxReportingTypeFieldName = `${symphonyMappingKey}-tax-reporting-number-type-${beneficiaryUniqueId}`;

  const beneficiaryNameContent = questions.find(q => q.key === `${questionKey}_name`);
  const beneficiaryFirstNameContent = questions.find(q => q.key === `${questionKey}_first_name`);
  const beneficiaryLastNameContent = questions.find(q => q.key === `${questionKey}_last_name`);
  const beneficiaryTypeContent = questions.find(q => q.key === `${questionKey}_type`);
  const beneficiaryPercentageContent = questions.find(q => q.key === `${questionKey}_percentage`);
  const beneficiaryDOBContent = questions.find(q => q.key === `${questionKey}_dob`);
  const beneficiarySSNContent = questions.find(q => q.key === `${questionKey}_ssn`);
  const beneficiaryPerStirpesContent = questions.find(q => q.key === `${questionKey}_per_stirpes`);
  const beneficiaryNameOfTrusteeContent = questions.find(q => q.key === `${questionKey}_name_of_trustee`);
  const beneficiaryOrganizationCountryContent = questions.find(q => q.key === `${questionKey}_organization_country`);
  const beneficiaryTrustDateContent = questions.find(q => q.key === `${questionKey}_trust_date`);
  const beneficiaryTINContent = questions.find(q => q.key === `${questionKey}_tin`);
  const beneficiaryTaxReportingNumberTypeContent = questions.find(
    q => q.key === `${questionKey}_tax_reporting_number_type`,
  );

  const watchedDOBField = watch(beneficiaryDOBFieldName);
  const watchedSSNField = watch(beneficiarySSNFieldName);
  const watchedTINField = watch(beneficiaryTINFieldName);

  const { maritalStatus } = useContext(PaperworkContext);
  const { disablePageEditing } = useContext(AccountProfileContext);

  const isTrustOrCharity = useMemo(() => {
    const beneficiaryType = benTypeFieldValue as RelationshipSubtype;
    return [RelationshipSubtype.TRUST, RelationshipSubtype.CHARITY].includes(beneficiaryType);
  }, [benTypeFieldValue]);

  const checkMaritalValue = (value: string) =>
    maritalStatus && maritalStatus !== 'MARRIED' && checkMaritalStatus && value === RelationshipSubtype.SPOUSE;

  const IDENTIFICATION_LENGTH = 9;
  const identificationInvalidCharactersRegex = /[^0-9]/g;
  // Default minDate as MUI Date picker default to 1900-01-01 as the earliest date.
  const defaultMinDate = startOfYear(new Date('1900-01-01'));
  const defaultMinDateForTrust = startOfYear(new Date('1500-01-01'));

  useEffect(() => {
    // Setting DOBField and SSNField in state, these values are used for the validation rules for SSN and DOB field.
    // If DOB field is true SSN field is not required and vice versa.
    setDOBFieldHasValue(!!watchedDOBField);
    setSSNFieldHasValue(!!watchedSSNField || !!watchedTINField);
  }, [watchedDOBField, watchedSSNField, watchedTINField]);

  useEffect(() => {
    trigger(beneficiaryPercentageFieldName);
  }, [percentageSumError]);

  useEffect(() => {
    trigger(beneficiaryTypeFieldName);
  }, [beneficiaryTypeError, maritalStatus]);
  useEffect(() => {
    // Trigger revalidation for SSN field when DOB field changes
    if (!hideSSN) {
      trigger(beneficiarySSNFieldName);
    }
  }, [DOBFieldHasValue, hideSSN]);

  useEffect(() => {
    // Trigger revalidation for DOB field when SSN field changes
    trigger(beneficiaryDOBFieldName);
  }, [SSNFieldHasValue]);

  useEffect(() => {
    onBeneficiaryTypeChange(index, prefillData?.relationshipType);
  }, []);

  useEffect(() => {
    if (prefillData?.percentage) {
      onPercentageChange(
        beneficiaryPercentageFieldName,
        prefillData.percentage ? parseFloat(prefillData.percentage) : 0,
      );
    }
  }, [prefillData]);

  useEffect(() => {
    if (prefillData?.relationshipType) {
      setBenTypeFieldValue(prefillData?.relationshipType);
    }
  }, [prefillData?.relationshipType]);

  useEffect(() => {
    if (prefillData?.party?.identifiers?.length && isTrustOrCharity) {
      const identifierType = prefillData?.party?.identifiers?.find(
        (id: { identifierValue?: string | null; type: IdentifierType }) => !!id.identifierValue,
      )?.type;
      if (identifierType) {
        setTaxReportingTypeFieldValue(identifierType);
      }
    }
  }, [isTrustOrCharity, prefillData?.party?.identifiers]);

  if (
    [
      ...(includeLastName ? [beneficiaryFirstNameContent, beneficiaryLastNameContent] : [beneficiaryNameContent]),
      beneficiaryTypeContent,
      beneficiaryPercentageContent,
      beneficiaryDOBContent,
      beneficiarySSNContent,
    ].includes(undefined)
  ) {
    return <Alert severity="error">Content not found for some of the question(s) in: "{questionKey}" in CMS.</Alert>;
  }

  const onBeneficiaryNameChange = (e: any): boolean => {
    const { value: inputValue } = e.target;
    if (
      isStringEmpty(inputValue) ||
      (beneficiaryNameContent?.character_limit && inputValue?.length > beneficiaryNameContent.character_limit)
    ) {
      e.preventDefault();
      return false;
    }
    if (inputValue) {
      e.target.value = inputValue.replaceAll(/[^A-Za-z\d -]/g, '');
    }
    return true;
  };

  const onBeneficiaryFirstNameChange = (e: any): boolean => {
    const { value: inputValue } = e.target;
    if (
      isStringEmpty(inputValue) ||
      (beneficiaryFirstNameContent?.character_limit && inputValue?.length > beneficiaryFirstNameContent.character_limit)
    ) {
      e.preventDefault();
      return false;
    }
    if (inputValue) {
      e.target.value = inputValue.replaceAll(/[^A-Za-z -]/g, '');
    }
    return true;
  };

  const onBeneficiaryLastNameChange = (e: any): boolean => {
    const { value: inputValue } = e.target;
    if (
      isStringEmpty(inputValue) ||
      (beneficiaryLastNameContent?.character_limit && inputValue?.length > beneficiaryLastNameContent.character_limit)
    ) {
      e.preventDefault();
      return false;
    }
    if (inputValue) {
      e.target.value = inputValue.replaceAll(/[^A-Za-z -]/g, '');
    }
    return true;
  };

  const onBeneficiarySSNChange = (e: any): boolean => {
    const { value: inputValue } = e.target;
    if (inputValue?.length > IDENTIFICATION_LENGTH) {
      e.preventDefault();
      return false;
    }
    e.target.value = inputValue.replaceAll(identificationInvalidCharactersRegex, '');
    return true;
  };

  const onBeneficiaryTINChange = (e: any): boolean => {
    const { value: inputValue } = e.target;
    if (inputValue?.length > IDENTIFICATION_LENGTH) {
      e.preventDefault();
      return false;
    }
    e.target.value = inputValue.replaceAll(identificationInvalidCharactersRegex, '');
    return true;
  };

  const onBeneficiaryTypeFieldChange = (e: any): boolean => {
    const { value: selectedDropdownValue } = e.target;
    setBenTypeFieldValue(selectedDropdownValue as string);
    onBeneficiaryTypeChange(index, selectedDropdownValue as string);
    return true;
  };

  const onPercentageFieldChange = (e: any): boolean => {
    const { value: inputValue } = e.target;
    if (isNaN((inputValue as unknown) as number)) {
      e.preventDefault(); // Prevent Non Numeric entries
      return false;
    }
    const decimalValue = inputValue.split('.')?.[1];
    if (decimalValue?.length > 2) {
      return false;
    }

    onPercentageValueChange(e);
    return true;
  };

  const getDefaultValueForDOBField = (relationshipType: string) => {
    const getISODate = (date?: string) => (date ? parseISO(date) : undefined);
    if (relationshipType === RelationshipSubtype.TRUST) {
      return getISODate(prefillData?.party?.partyBusinessEntity?.attributes?.trustDate);
    }
    return getISODate(prefillData?.party?.partyPerson?.birthDate);
  };

  const getDefaultValueForNameField = (relationshipType: string) => {
    if (relationshipType === RelationshipSubtype.TRUST) {
      return prefillData?.party?.partyBusinessEntity?.name;
    }
    return prefillData?.party?.partyPerson?.givenName;
  };
  const getControllerByComponentName = (componentName: string) => {
    switch (componentName) {
      case 'dob':
        const isTrustBeneficiaryType = benTypeFieldValue === RelationshipSubtype.TRUST;
        return (
          <Controller
            control={control}
            defaultValue={getDefaultValueForDOBField(prefillData?.relationshipType)}
            name={beneficiaryDOBFieldName}
            render={({ onChange, value, ref }) => (
              <DatePicker
                dataQa={`datepicker-${beneficiaryDOBFieldName}`}
                defaultValue={getDefaultValueForDOBField(prefillData?.relationshipType)}
                disableFuture
                disableHighlightToday
                disabled={disablePageEditing}
                error={formIsSubmitted ? !!fieldErrors[beneficiaryDOBFieldName] : false}
                fullWidth
                inputRef={ref}
                isControlled
                label={isTrustBeneficiaryType ? beneficiaryTrustDateContent?.question : beneficiaryDOBContent?.question}
                maxDate={endOfYesterday()}
                minDate={isTrustBeneficiaryType ? defaultMinDateForTrust : defaultMinDate}
                onChange={onChange}
                value={value}
              />
            )}
            rules={
              hidden
                ? {}
                : {
                    required: !SSNFieldHasValue,
                    validate: {
                      isDate: value => (value ? isValid(toDate(value)) : true),
                      minDate: value => {
                        if (!value) {
                          return true;
                        }
                        return !isBefore(value, isTrustBeneficiaryType ? defaultMinDateForTrust : defaultMinDate);
                      },
                      isFutureDateDisabled: value => (value ? isAfter(endOfYesterday(), value) : true),
                    },
                  }
            }
          />
        );
      case 'perStirpes':
        return (
          <Controller
            control={control}
            defaultValue={prefillData?.isPerStirpes}
            name={beneficiaryPerStirpesFieldName}
            render={({ onChange }) => (
              <CheckboxGroup
                dataQa={`question-checkbox-${beneficiaryPerStirpesFieldName}`}
                disabled={disablePageEditing}
                error={false}
                items={[
                  {
                    name: beneficiaryPerStirpesFieldName,
                    label: <RteContent data={beneficiaryPerStirpesContent?.question ?? ''} />,
                    defaultChecked: prefillData?.isPerStirpes ?? false,
                  },
                ]}
                onChange={e => {
                  onChange(e);
                }}
              />
            )}
          />
        );
      case 'taxType':
        return (
          <Controller
            control={control}
            defaultValue={
              prefillData?.party?.identifiers?.find((id: { identifierValue: string | null }) => !!id.identifierValue)
                ?.type ?? ''
            }
            key={beneficiaryTaxReportingTypeFieldName}
            name={beneficiaryTaxReportingTypeFieldName}
            render={({ onChange, value }) => (
              <RadioGroup
                dataQa={`dropdown-${beneficiaryTaxReportingTypeFieldName}`}
                disabled={disablePageEditing}
                error={formIsSubmitted ? !!fieldErrors[beneficiaryTaxReportingTypeFieldName] : false}
                items={beneficiaryTaxReportingNumberTypeContent?.options ?? []}
                legend={beneficiaryTaxReportingNumberTypeContent?.question}
                name={beneficiaryTaxReportingTypeFieldName}
                onChange={e => {
                  setTaxReportingTypeFieldValue(e as string);
                  onChange(e);
                }}
                value={value}
              />
            )}
            rules={{ required: !DOBFieldHasValue }}
          />
        );
      default:
        return null;
    }
  };

  const getControllerByComponentType = ({
    componentName,
    questionContent,
    componentType,
    defaultValue,
    disabled,
    error,
    onInputChange,
    type,
    rules,
    inputProps,
  }: ControllerParams) => {
    const ariaLabel = `${questionContent?.question} ${Number(beneficiaryUniqueId) + 1}`;
    switch (componentType) {
      case ComponentTypes.Input:
        return (
          <Controller
            control={control}
            defaultValue={defaultValue ?? ''}
            name={componentName}
            render={({ onChange, value, name, ref }) => (
              <TextField
                InputLabelProps={{ 'aria-label': ariaLabel }}
                InputProps={inputProps}
                dataQa={`textField-${componentName}`}
                disabled={disablePageEditing ? disablePageEditing : disabled}
                error={formIsSubmitted ? !!fieldErrors[componentName] || error : false}
                fullWidth
                id={componentName}
                inputRef={ref}
                label={questionContent?.question}
                name={name}
                onChange={(e: any) => {
                  const isChangeValid = onInputChange?.(e);
                  if (isChangeValid) {
                    onChange(e);
                  }
                }}
                type={type ?? 'text'}
                value={value}
              />
            )}
            rules={!hidden && rules ? rules : {}}
          />
        );
      case ComponentTypes.Dropdown:
        return (
          <Controller
            control={control}
            defaultValue={defaultValue ?? ''}
            name={componentName}
            render={({ onChange, value, ref }) => (
              <Dropdown
                ariaLabel={ariaLabel}
                dataQa={`dropdown-${componentName}`}
                disabled={disablePageEditing}
                error={formIsSubmitted ? !!fieldErrors[componentName] || error : false}
                inputRef={ref}
                items={questionContent?.options ?? []}
                label={questionContent?.question}
                onChange={e => {
                  onInputChange?.(e);
                  onChange(e.target.value);
                }}
                value={value}
                width="100%"
              />
            )}
            rules={!hidden && rules ? rules : {}}
          />
        );
      default:
        return null;
    }
  };

  const UIDFieldControllerObj: ControllerParams = {
    componentName: beneficiaryULIDFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: prefillData?.id,
    type: 'hidden',
  };

  const NameFieldControllerObj: ControllerParams = {
    componentName: beneficiaryNameFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: getDefaultValueForNameField(prefillData?.relationshipType),
    onInputChange: onBeneficiaryNameChange,
    rules: {
      required: true,
    },
    questionContent: beneficiaryNameContent,
  };

  const FirstNameFieldControllerObj: ControllerParams = {
    componentName: beneficiaryFirstNameFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: prefillData?.party?.partyPerson?.givenName,
    onInputChange: onBeneficiaryFirstNameChange,
    rules: {
      required: true,
    },
    questionContent: beneficiaryFirstNameContent,
  };

  const LastNameFieldControllerObj: ControllerParams = {
    componentName: beneficiaryLastNameFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: prefillData?.party?.partyPerson?.familyName,
    onInputChange: onBeneficiaryLastNameChange,
    rules: {
      required: true,
    },
    questionContent: beneficiaryLastNameContent,
  };

  const SSNFieldControllerObj: ControllerParams = {
    componentName: beneficiarySSNFieldName,
    componentType: ComponentTypes.Input,
    defaultValue:
      prefillData?.party?.identifiers?.find((id: { type: IdentifierType }) => id.type === IdentifierType.SSN)
        ?.identifierValue ?? '',
    disabled:
      isAccountProfileEdit &&
      prefillData?.party?.identifiers?.find((id: { type: IdentifierType }) => id.type === IdentifierType.SSN)
        ?.identifierValue.length > 0,
    onInputChange: onBeneficiarySSNChange,
    questionContent: beneficiarySSNContent,
    rules: {
      required: !DOBFieldHasValue,
      validate: {
        isSsn: value => (value ? isValidIdentificationNumber(value) : true),
      },
    },
  };

  const NameOfTrusteeFieldControllerObj: ControllerParams = {
    componentName: beneficiaryNameOfTrusteeFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: prefillData?.nameOfTrustees,
    onInputChange: onBeneficiaryNameChange,
    questionContent: beneficiaryNameOfTrusteeContent,
  };

  const TypeFieldControllerObj: ControllerParams = {
    componentName: beneficiaryTypeFieldName,
    componentType: ComponentTypes.Dropdown,
    defaultValue: prefillData?.relationshipType,
    error: beneficiaryTypeError.spouseCount,
    questionContent: beneficiaryTypeContent,
    onInputChange: onBeneficiaryTypeFieldChange,
    rules: {
      required: true,
      validate: {
        onlyOneSpouseSelectionAllowed: () => !beneficiaryTypeError.spouseCount,
        spouseNotAllowed: value => isAccountProfileEdit || !checkMaritalValue(value),
        maritalStatusNotSelected: () =>
          (isAccountProfileEdit && !maritalStatus) || !(!maritalStatus && checkMaritalStatus),
      },
    },
  };

  const OrganizationCountryControllerObj: ControllerParams = {
    componentName: beneficiaryOrganizationCountryFieldName,
    componentType: ComponentTypes.Dropdown,
    defaultValue: prefillData?.party?.partyBusinessEntity?.country ?? '999',
    dropdownOptions: countriesList,
    questionContent: beneficiaryOrganizationCountryContent,
  };

  const PercentageFieldControllerObj: ControllerParams = {
    inputProps: {
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    },
    componentName: beneficiaryPercentageFieldName,
    componentType: ComponentTypes.Input,
    defaultValue: prefillData?.percentage,
    questionContent: beneficiaryPercentageContent,
    onInputChange: onPercentageFieldChange,
    rules: {
      required: true,
      min: 0,
      max: 100,
      validate: {
        sumNotEqualToHundred: () => !percentageSumError,
        zeroNotAllowed: inputValue => !!parseFloat(inputValue),
      },
    },
    error: percentageSumError,
  };

  const TINFieldControllerObj: ControllerParams = {
    componentName: beneficiaryTINFieldName,
    componentType: ComponentTypes.Input,
    defaultValue:
      prefillData?.party?.identifiers?.find((id: { type: IdentifierType }) => id.type === IdentifierType.TIN)
        ?.identifierValue ?? '',
    inputType: InputTypes.Tin,
    onInputChange: onBeneficiaryTINChange,
    questionContent: beneficiaryTINContent,
    rules: {
      // TIN will have a required value only when the Relationship if DOB field does not have a value.
      required:
        !!benTypeFieldValue &&
        (benTypeFieldValue === RelationshipSubtype.CHARITY ||
          (benTypeFieldValue === RelationshipSubtype.TRUST && !DOBFieldHasValue)) &&
        !!taxReportingTypeFieldValue &&
        taxReportingTypeFieldValue === IdentifierType.TIN,
      validate: {
        isTin: value => (value ? isValidIdentificationNumber(value) : true),
      },
    },
  };

  return isAccountProfileEdit ? (
    <Grid columnSpacing={2} container data-qa={dataQa} item sx={{ mt: index ? 3 : 0 }} xs={12}>
      {/* The hidden input field is put here to map the ULID to the Beneficiary object*/}
      <Box sx={{ display: 'none' }}>{getControllerByComponentType(UIDFieldControllerObj)}</Box>
      {includeLastName ? (
        <>
          <Grid item xs={4}>
            {getControllerByComponentType(FirstNameFieldControllerObj)}
            {formIsSubmitted &&
              getInputError(
                fieldErrors[beneficiaryFirstNameFieldName],
                validationMessages,
                beneficiaryFirstNameContent?.key,
              )}
          </Grid>
          <Grid item xs={4}>
            {getControllerByComponentType(LastNameFieldControllerObj)}
            {formIsSubmitted &&
              getInputError(
                fieldErrors[beneficiaryLastNameFieldName],
                validationMessages,
                beneficiaryLastNameContent?.key,
              )}
          </Grid>
        </>
      ) : (
        <Grid item xs={4}>
          {getControllerByComponentType(NameFieldControllerObj)}
          {formIsSubmitted &&
            getInputError(fieldErrors[beneficiaryNameFieldName], validationMessages, beneficiaryNameContent?.key)}
        </Grid>
      )}
      <Grid item sx={{ mt: '-6.5px' }} xs={hideSSN ? 4 : 2}>
        {getControllerByComponentType(TypeFieldControllerObj)}
        {formIsSubmitted && getInputError(fieldErrors[beneficiaryTypeFieldName], validationMessages)}
      </Grid>
      {hideSSN ? null : (
        <Grid item xs={2}>
          {getControllerByComponentType(SSNFieldControllerObj)}
          {formIsSubmitted &&
            getInputError(fieldErrors[beneficiarySSNFieldName], validationMessages, beneficiarySSNContent?.key)}
        </Grid>
      )}
      <Grid item xs={2}>
        {getControllerByComponentName('dob')}
        {formIsSubmitted &&
          getInputError(fieldErrors[beneficiaryDOBFieldName], validationMessages, beneficiaryDOBContent?.key)}
      </Grid>
      <Grid item xs={2}>
        {getControllerByComponentType(PercentageFieldControllerObj)}
        {formIsSubmitted && getInputError(fieldErrors[beneficiaryPercentageFieldName], validationMessages)}
      </Grid>
      {!!beneficiaryPerStirpesContent && isPerStirpesEnabled && (
        <Grid item xs={12}>
          {getControllerByComponentName('perStirpes')}
        </Grid>
      )}
    </Grid>
  ) : (
    <Grid columnSpacing={1} container data-qa={dataQa} item rowSpacing={2} sx={{ pt: 2 }} xs={12}>
      {/* The hidden input field is put here to map the ULID to the Beneficiary object*/}
      <Grid item sx={{ display: 'none' }}>
        <Box sx={{ display: 'none' }}>{getControllerByComponentType(UIDFieldControllerObj)}</Box>
      </Grid>
      {includeLastName ? (
        <>
          <Grid item xs={12}>
            {getControllerByComponentType(FirstNameFieldControllerObj)}
            {formIsSubmitted &&
              getInputError(
                fieldErrors[beneficiaryFirstNameFieldName],
                validationMessages,
                beneficiaryFirstNameContent?.key,
              )}
          </Grid>
          <Grid item xs={12}>
            {getControllerByComponentType(LastNameFieldControllerObj)}
            {formIsSubmitted &&
              getInputError(
                fieldErrors[beneficiaryLastNameFieldName],
                validationMessages,
                beneficiaryLastNameContent?.key,
              )}
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          {getControllerByComponentType(NameFieldControllerObj)}
          {formIsSubmitted && getInputError(fieldErrors[beneficiaryNameFieldName], validationMessages)}
        </Grid>
      )}
      <Grid item sx={{ mt: '-1.5px' }} xs={6}>
        {getControllerByComponentType(TypeFieldControllerObj)}
        {formIsSubmitted && getInputError(fieldErrors[beneficiaryTypeFieldName], validationMessages)}
      </Grid>
      <Grid item xs={6}>
        {getControllerByComponentType(PercentageFieldControllerObj)}
        {formIsSubmitted && getInputError(fieldErrors[beneficiaryPercentageFieldName], validationMessages)}
      </Grid>
      {!!beneficiaryPerStirpesContent && isPerStirpesEnabled && (
        <Grid item xs={12}>
          {getControllerByComponentName('perStirpes')}
        </Grid>
      )}
      {isTrustOrCharity ? (
        <>
          {isTrustTypeFieldsEnabled && benTypeFieldValue === RelationshipSubtype.TRUST && (
            <Grid item xs={12}>
              {getControllerByComponentName('dob')}
              {formIsSubmitted &&
                getInputError(
                  fieldErrors[beneficiaryDOBFieldName],
                  validationMessages,
                  beneficiaryTrustDateContent?.key,
                )}
            </Grid>
          )}
          <Grid item xs={12}>
            {getControllerByComponentName('taxType')}
            {formIsSubmitted && getInputError(fieldErrors[beneficiaryTaxReportingTypeFieldName], validationMessages)}
          </Grid>
          {taxReportingTypeFieldValue === IdentifierType.SSN && (
            <Grid item xs={12}>
              {getControllerByComponentType(SSNFieldControllerObj)}
              {formIsSubmitted &&
                getInputError(fieldErrors[beneficiarySSNFieldName], validationMessages, beneficiarySSNContent?.key)}
            </Grid>
          )}
          {taxReportingTypeFieldValue === IdentifierType.TIN && (
            <Grid item xs={12}>
              {getControllerByComponentType(TINFieldControllerObj)}
              {formIsSubmitted &&
                getInputError(fieldErrors[beneficiaryTINFieldName], validationMessages, beneficiaryTINContent?.key)}
            </Grid>
          )}
          {isTrustTypeFieldsEnabled && benTypeFieldValue === RelationshipSubtype.TRUST && (
            <>
              <Grid item xs={12}>
                {getControllerByComponentType(OrganizationCountryControllerObj)}
                {formIsSubmitted &&
                  getInputError(fieldErrors[beneficiaryOrganizationCountryFieldName], validationMessages)}
              </Grid>
              <Grid item xs={12}>
                {getControllerByComponentType(NameOfTrusteeFieldControllerObj)}
                {formIsSubmitted &&
                  getInputError(
                    fieldErrors[beneficiaryNameOfTrusteeFieldName],
                    validationMessages,
                    beneficiaryNameOfTrusteeContent?.key,
                  )}
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          <Grid item xs={6}>
            {getControllerByComponentName('dob')}
            {formIsSubmitted &&
              getInputError(fieldErrors[beneficiaryDOBFieldName], validationMessages, beneficiaryDOBContent?.key)}
          </Grid>
          <Grid item xs={6}>
            {hideSSN ? null : (
              <>
                {getControllerByComponentType(SSNFieldControllerObj)}
                {formIsSubmitted &&
                  getInputError(fieldErrors[beneficiarySSNFieldName], validationMessages, beneficiarySSNContent?.key)}
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
