import { useCallback } from 'react';

import {
  CreateEntityUpdateWorkflow,
  CreateEntityUpdateWorkflowVariables,
} from '../symphony/__generated__/CreateEntityUpdateWorkflow';

import { AssociatedEntityType, PaperworkInput, UpdateWorkflowStatus } from '~/__generated__';
import { DocusignParams } from '~/containers/Comparison';
import {
  CreateSigningDocument,
  CreateSigningDocumentVariables,
} from '~/hooks/docusign/symphony/__generated__/CreateSigningDocument';
import { MutationTuple } from '~/utils';

export interface EntityUpdateWorkFlowData {
  error: any;
  id: string;
  isSigningRequired: boolean;
}

export interface EntityUpdateWorkFlowVariables {
  createEntityUpdateWorkflow: MutationTuple<CreateEntityUpdateWorkflow, CreateEntityUpdateWorkflowVariables>[0];
  createSigningDocument: MutationTuple<CreateSigningDocument, CreateSigningDocumentVariables>[0];
  docusignParams?: DocusignParams;
  managedProductId: string;
  onBack: () => void;
  onNext?: ({
    isSigningRequired,
    entityUpdateWorkflowId,
    clientDeclined,
  }: {
    clientDeclined?: boolean;
    entityUpdateWorkflowId?: string;
    isSigningRequired?: boolean;
  }) => void;
  partyId: string;
  setErrorSaving: React.Dispatch<React.SetStateAction<any>>;
  setIsBusy: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessOnSave: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useEntityUpdateWorkFlow = ({
  managedProductId,
  docusignParams,
  partyId,
  createEntityUpdateWorkflow,
  createSigningDocument,
  setSuccessOnSave,
  onNext,
  onBack,
  setErrorSaving,
  setIsBusy,
  setIsSubmitted,
}: EntityUpdateWorkFlowVariables): ((paperworkDataListToSave: PaperworkInput[]) => Promise<void>) => {
  return useCallback(
    async (paperworkDataListToSave: PaperworkInput[]) => {
      try {
        const createEntityUpdateWorkflowData = await createEntityUpdateWorkflow({
          variables: {
            managedId: managedProductId,
            paperworkInputList: paperworkDataListToSave,
            status: UpdateWorkflowStatus.STAGED,
          },
        });
        const createdWorkflowData = createEntityUpdateWorkflowData.data?.createEntityUpdateWorkflow;
        if (createdWorkflowData?.id) {
          if (createdWorkflowData.isSigningRequired && docusignParams) {
            const { baseUrl, faPartyId, returnToUrl } = docusignParams;
            await createSigningDocument({
              variables: {
                baseUrl,
                managedProductId,
                partyId,
                returnToUrl,
                faPartyId: faPartyId ? faPartyId : null,
                associatedEntity: {
                  entityId: createdWorkflowData.id,
                  entityType: AssociatedEntityType.ENTITY_UPDATE_WORKFLOW,
                },
              },
            });
          }

          window.scroll(0, 0);
          setSuccessOnSave(true);
          onNext
            ? onNext({
                isSigningRequired: createdWorkflowData.isSigningRequired,
                entityUpdateWorkflowId: createdWorkflowData.id,
              })
            : onBack();
        } else {
          setErrorSaving(Error('Error saving'));
        }
      } catch (err: any) {
        // TODO DA2-512: Log error to sentry
        console.error(err);
        setErrorSaving(err);
      } finally {
        setIsBusy(false);
        setIsSubmitted(false);
      }
    },
    [
      createEntityUpdateWorkflow,
      managedProductId,
      docusignParams,
      setSuccessOnSave,
      onNext,
      onBack,
      createSigningDocument,
      partyId,
      setErrorSaving,
      setIsBusy,
      setIsSubmitted,
    ],
  );
};
