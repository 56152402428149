import {
  GetHeaderContent,
  GetHeaderContent_all_header_items,
  GetHeaderContent_all_header_items_language_dropdownConnection_edges_node_items,
  GetHeaderContentVariables,
} from './__generated__/GetHeaderContent';
import * as queries from './query.gql';

import { QueryHookOptions } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack/src/hooks';

export type HeaderContent = GetHeaderContent_all_header_items;
export type HeaderDropdownItem = GetHeaderContent_all_header_items_language_dropdownConnection_edges_node_items;

export const useGetHeaderContent = (options?: QueryHookOptions<GetHeaderContent, GetHeaderContentVariables>) => {
  return useContentstackQuery(queries.GetHeaderContent, options);
};
